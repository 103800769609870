import React, {useState} from "react"
import AuthenticationService from "../../services/AuthService"
import useStyle from '../../hooks/useStyle'
import { Link } from "react-router-dom"
import {usePageTitle} from "../../hooks/pageHead"
import CommonService from "../../services/CommonService"
import { useCurrentUserHook } from "./useUserHook"
import { Spinner } from "../Common/Spinner"

export const ResetPassword = (props) => {
  useStyle('/css/login.css', true) 
  usePageTitle('Reset Password')

  const params = props.match.params
  const url = props.match.path
  const {currentUser} = useCurrentUserHook()
  const [count, setCount] = useState(Math.random())

  const initialState = {
    password: "",
    cpassword:"",
    isSubmitting: false,
    errorMessage: null,
    errorSign: false,
    passwordNotMatch:true
  }

  const [data, setData] = useState(initialState);

  const handleInputChange = (event) => {
    data[event.target.name] = event.target.value;
    setData({...data});
    checkRegex(event.target.value)
  }

  const checkRegex = (val) =>{
    //let pattern = new RegExp(/^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d][A-Za-z\d]{5,19}$/);
    const pattern = new RegExp(/^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d\W_]{8,19}$/)
    const newData = data
    const passwordNotMatch = checkCpassword()
    if(pattern.test(val) === true){
      setData({
        ...newData,
        passwordNotMatch: passwordNotMatch,
        errorMessage: (
          data.cpassword.length===0?
          'You must re-enter the password in this box.'
          :(passwordNotMatch?'Password not match':null)
        ),
        errorSign: true
      })
    }else{
      setData({
        ...newData,
        passwordNotMatch: passwordNotMatch,
        errorMessage: 'Your password must include letters and numbers and must be at least 8 characters long.',
        errorSign:false
      })
    }
  }

  const checkCpassword = () =>{
    if(data.password!="" && data.cpassword!="" && data.password === data.cpassword){
      return false
    }else{
      return true
    }
  }

  const handleForgetSubmit = (event) => {
    event.preventDefault();
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    })
    if(url == "/password/update"){
      const req = { 
        user: { 
          new_password: data.password,
          password_confirmation:data.cpassword 
        } 
      }
      AuthenticationService.updatePassword(req).then((res) => {
        if (res.status == 200) {
          props.history.push("/health/table")
        } else {
          setData({
            ...data,
            isSubmitting: false,
            errorMessage: res.message || res.statusText,
          })
        }
      }).catch((error) => {
        setData({ ...data, errorMessage: error.response.data.error });
      })
    }else{
      const req = { 
        user: { 
          new_password: data.password,
          password_confirmation:data.cpassword 
        } 
      }
      const userId = params.userId
      const token = params.token  
      AuthenticationService.resetPassword(req, userId, token).then((res) => {
        if (res.status == 200) {
          props.history.push('/signin')
        } else {
          setData({
            ...data,
            isSubmitting: false,
            errorMessage: res.message || res.statusText,
          })
        }
      }).catch((error) => {
        setData({ ...data, errorMessage: error.response.data.error });
      })
    }
  }

  return (
    <div id="ct">
      {data.isSubmitting ? <Spinner/>:null}
      <section className="login-page flex justspacebetween">
        <form className="login-left wid-50 pos-rel flex aligncenter">
          <div className="login-form">
            <h2 className="title m-b-10">Reset your Password</h2>
            <p className="text-lite-gray m-b-30">
              Enter your new password below
            </p>
            <div className="form-fields flex coldir m-b-20">
              <label className="form-label m-b-10">New Password</label>
              <input
                type="password" placeholder="Choose a Password"
                name="password" onChange={handleInputChange}
                className="form-input fulwid"
                required/>
            </div>
            <div className="form-fields flex coldir">
              <label className="form-label m-b-10">Confirm New Password</label>
              <input type="password" placeholder="Confirm your Password"
                name="cpassword" onChange={handleInputChange} className="form-input fulwid"
                required />
            </div>
            {data.errorMessage && (
              <div className="form-error lred m-t-5">
                {data.errorMessage}
              </div>
            )}
            <button
              disabled={data.passwordNotMatch || data.isSubmitting}
              onClick={handleForgetSubmit}
              className="btn btn-primary fulwid f16 fw500 tcenter text-white m-t-20">
              Reset Password
            </button>
            {/*<div className="m-t-50">
              <p>
                Want to Sign In? {" "}
                <Link to="/signin" className="text-link">
                  <strong>Click Here </strong>
                </Link>
              </p>
            </div>*/}
          </div>
        </form>
        <div className="login-right tcenter justifycenter wid-50 pos-rel flex aligncenter">
          <div className="login-content">
            <a href="#" className="m-b-20">
              <img className="w-115" src="images/logo/cuetree_Icon.png" alt="" />
            </a>
            <h3 className="fw500 m-b-15">Transform health and wellbeing journeys</h3>
            <p className="text-lite-gray m-b-20 wid-80 w-80 wid-100-xs mx-auto">
               {CommonService.displayRandomPhrase(Math.floor(4*count))}
            </p>
            <div className="text-lite-gray badge badge-md badge-content m-b-20 mx-auto fw500">
              From Screening to extended care
            </div>
            <div className="m-t-10 mx-auto">
              <ul className="flex gap-5 justifycenter">
                <li>
                  <a href="#">
                    <img src="images/fb-icon.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/instagram-icon.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/twitter-icon.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/linked-icon.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/whatsapp-icon.png" alt="" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex justspacebetween m-t-50">
              <p className="text-lite-gray f12">
                <i className="m-l-15 m-r-5 far fa-copyright" aria-hidden="true"/> 
                {new Date().getFullYear()} IPIPAL INC. All Rights Reserved.
              </p>
              <p className="f12">
                <Link to="/about_us/terms_of_service" className="text-link">
                  <strong>Terms of Service</strong>
                </Link>
                {" "}|{" "}
                <Link to="/about_us/privacy_policy" className="text-link">
                  <strong>Privacy policy </strong>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ResetPassword;