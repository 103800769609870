import React, { useState, useEffect, useMemo, useRef } from "react"
import { useHistory } from "react-router-dom"
import Cs from "../../../../../services/CommonService"
import ReactTooltip from "react-tooltip"
import ChildGrowth from "../../../../../constants/ChildGrowthDataPoints"
import useSort from '../../../../../hooks/useSorting'
import ActivityService from "../../Activities/ActivityService"
import PreLoadSpinner from "../../../../../components/PreLoadSpinner"
import GenericModal from "../../../../../components/Modals/GenericModal"
import ActivityForm from '../../../MotherInfantJourney/Activities/Form/ActivityForm'
import useModal from "../../../../../hooks/useModal"
import NutritionCareNotification from '../../../../NutritionCares/NutritionCareNotification'
import DynamicTable from '../../../../Common/DynamicTable'
import { CueTip } from "../../../../Common/CueTooltip"
import PreScheduleAlert from "../../../../PreScheduleEvent/List/PreScheduleAlert"
import moment from '../../../../../../node_modules/moment/min/moment.min.js'
import { FontAwesomeBtn } from "../../../../Common/Button"
import { OptionsObjList } from "../../../../Common/FormInput"
import { usePageLocale } from "./../../../../../hooks/useLocale"
import { DraftBadge } from "../../../../Common/NotificationPill"
import { ActivityCard, DuplicateActivityAlert } from "./ActivityCard"
import { useActivityHook } from "./useActivityHook"
import PaginationSM from '../../../../Common/PaginationSM'
import { EmptyRowAlert } from '../../../../Common/TableView'
import CheckAccess from '../../../../Roles/CheckAccess'
import ConfirmMenu from "../../../../Modals/ConfirmMenu"
import FormTemplateList from "../../../../../constants/FormTemplateList"
import { UserName } from '../../../../UserProfile/UserInfoPopup'
import { AssessCfChartBtn, AssessCFChart, ProteinIntakeChart } from "../../../../Organizations/Members/ChartReports"
import AddCaseAssessmentIco from "../../../CaseAssessments/AddCaseAssessmentIco"
import { FilesListBtn } from "../../../CaseReports/OrganizationMediaFiles"
import { useFormTestReview, FormTestInputReviewFormPopup } from "../../../../FormTestInput/Reviews/FormTestInputReviewFormPopup"
import { LinkFontAwesomeIcon } from "../../../../Common/ImageIcons"
import FieldGroupActivityForm from '../../../MotherInfantJourney/Activities/Form/FieldGroupActivityForm'
import Organization from '../../../../../constants/Organization'
import { useCurrentUserHook } from "../../../../Authentication/useUserHook.js"
import { ReviewScore } from '../../../../Reviews/Common'

const InfantActivityTable = (props) => {

  let history = useHistory();
  const {WarningStyle} = ChildGrowth;
  let {current:scope} = useRef({'requestParams':null, 'actFormProps':null, 'selectedItem':null});
  const {toggleMenuModal, journeyProfile, searchString, componentName, dataSource, timeFilter, activityReloadTab} = props;
  const {child_form:formId, sid} = dataSource;

  const {currentUser} = useCurrentUserHook()

  const [loading, setLoading] = useState(true);
  const [activities, setActivities] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState({});
  const [formFields, setFormFields] = useState();
  const [formFieldsGrouped, setFormFieldsGrouped] = useState();
  const [tableFields, setTableFields] = useState();
  const [reviewActivityId, setReviewActivityId] = useState();
  const [caseAssessmentCount, setCaseAssessmentCount] = useState();
  const [filter, setFilter] = useState({
    'label':timeFilter?.options[0]?.label, 
    'date_type':timeFilter?.options[0]?.sid
  })

  const { isOpen:isInfantActivityFormOpen, toggleModal:toggleInfantActivityModal } = useModal();
  const { isOpen:isNutritionCareOpen, toggleModal:toggleNutritionCareModal } = useModal();
  const { isOpen:isAssessMenuOpen, toggleModal: toggleAssessMenuModal } = useModal();
  //const { isOpen:isGrowthMeasureUploadOpen, toggleModal:toggleGrowthMeasureUploadModal } = useModal()
  const { isOpen:isPreScheduleOpen, toggleModal: togglePreScheduleModal } = useModal();
  const { isOpen:isActivityCardOpen, toggleModal: toggleActivityCardModal } = useModal();
  const { isOpen:isDeleteModalOpen, toggleModal: toggleDeleteModal} = useModal();
  const { isOpen:isDupActivityAlertOpen, toggleModal: toggleDupActivityAlert } = useModal();
  const { isOpen:isAssessCFChartOpen, toggleModal:toggleAssessCFChart} = useModal();
  const { isOpen:isProteinIntakeChartOpen, toggleModal:toggleProteinIntakeChart} = useModal();

  const {labelLocale, getObjLocale} = usePageLocale(currentUser.current_locale, 'casePortalPage');
  const {labelLocale:tableHeadLocale} = usePageLocale(currentUser.current_locale, 'commonTableHeaders');

  const {child, setChild, ageFormat, getBgColorByZscore, getBgColorByZscoreLable} = useActivityHook();
  const {checkTestInputReviewNeeded, isTestInputReviewFormOpen, toggleTestInputReviewForm} = useFormTestReview();

  const isMultiChildCase = journeyProfile.associated_journey_id && FormTemplateList.mcjMotherForms.indexOf(formId) > -1;

  const permission = useMemo(() => 
    CheckAccess.getMemberPermissionBySid(currentUser, 'infant_process', sid), [currentUser.id]
  )

  useEffect(() => {
    ReactTooltip.rebuild()
    scope.requestParams = {
      'page': currentpage,
      'per_page': 15,
      'sort_column': 'updated_at',
      'sort_direction': 'desc',
      'search': searchString,
      'filter': filter,
      'fields':['form_review'],
      'form_fields_params': {
        'ids': [formId],
        'locale': currentUser.current_locale
      }
    }

    if(sid === 'prenatal_care'){
      scope.requestParams.sort_column = 'scheduled_date'
    }else if(sid === 'protein_intake'){
      scope.requestParams.form_fields_params.fields = ['columns', 'row_data_source_options']
      scope.requestParams.sort_column = 'data.record_date_formatted_date'
    }else if(sid === 'growth_measurement'){
      scope.requestParams.fields = ['form_review','case_report_count', 'case_report_verifications']
      scope.requestParams.data_source_params = {
        'data_source_id':[4010],
        'locale':currentUser.current_locale
      }
    } 

    if(componentName === 'assess_bf'){
      setLoading(false)
    }else{
      getActivityList()
    }
  }, [searchString, currentpage, filter])

  useEffect(()=>{
    if(activityReloadTab === sid)
      getActivityList()
  }, [activityReloadTab])

  let getActivityList = () => {
    setLoading(true)
    scope.requestParams.activity_form_id = formId
    scope.requestParams.activity_type = sid
    if(formFields) delete scope.requestParams.form_fields_params
    
    /*set journey_profile_id to associated_journey_id for mother forms */
    if(isMultiChildCase){
      scope.requestParams.journey_profile_id = journeyProfile.associated_journey_id
    }else{
      scope.requestParams.journey_profile_id = journeyProfile.id
    }

    ActivityService.getAll(scope.requestParams).then(({status, data}) => {
      if (status === 200) {
        scope.requestParams.totalItems = data.meta.total
        //journey_category = res.data.meta.journey_category;
        //CheckAccess.userAccess(authState.user, journey_category, res.data.meta.invited_journey_member)
        setAge(data.child)
        setChild(data.child)
        setActivities(data.activities)
        setPagemeta(data.meta)
        if(data.form_fields?.[formId]){
          if(formId === 575){
            const fields = [
              {label:'Immunization', client_id:'immunization'},
              {label:'Status', client_id:'status'},
              {label:'Remarks', client_id:'remarks'}
            ]
            setFormFields(fields);
            setTableFields(fields);
          }else{
            setFormFields(data.form_fields[formId]);
            setTableFields(data.form_fields[formId].filter((f) => f.show_in_table_view==='true'));
            const formFieldByClientId = Cs.listToObj(data.form_fields[formId], 'client_id');
            setFormFieldsGrouped(formFieldByClientId);
            setCaseAssessmentCount(data.case_assessment_count);
            if(componentName === 'growth_measurement'){
              const customFieldsBySid = Cs.listToObj(
                data.data_source_list?.mcj_measure_growth_table?.options, 'sid'
              );
              setFormFieldsGrouped({
                ...formFieldByClientId,
                ...customFieldsBySid
              });
            }
          }
        }
        
        setLoading(false);
        //if(todayActivityItem)openPreSchedule(todayActivityItem)
      }
    })
  }

  const {sort} = useSort(scope.requestParams, getActivityList);

  const onRangeFilterChange = (selectedIndex) =>{
    const f = timeFilter.options[selectedIndex]
    setFilter({
      label:f.label,
      date_type:f.sid
    })
  } 

  const setAge = (child) =>{
    if(!child)return;
    const data = child.data;
    child.age_in_weeks =  Cs.compareDate(data.dob_baby_formatted_date, null, 'weeks', true);
    child.age_in_month =  Cs.compareDate(data.dob_baby_formatted_date, null, 'months', true, true)?.toFixed(2);
  }

  const routeActivityForm = (e, id, scheduleId, state) =>{
    e && e.stopPropagation();
    
    /*Special case for twin triplets mother forms are same*/
    /*Change id to parent case*/
    let journeyCopy = {...journeyProfile};
    if(isMultiChildCase){
      journeyCopy.id = journeyProfile.associated_journey_id;
    } 

    scope.actFormProps = Cs.routeInfantPath(dataSource, {}, journeyCopy, history, null, id);
    scope.actFormProps.location.search += '&show_review=1';
    if(child){
      const c = child;
      let formState = {
        'child_fk_id': c.record_id, 
        'dob_baby_formatted_date': c.data.dob_baby_formatted_date, 
        'id_mother': journeyCopy.data.cell_number, 
        'birth_weight': c.data_source.weight?.label, //applies only for iit mcj
        'birth_height': c.data_source.height?.label, 
        'baby_age_dwmy': ageFormat(null, Cs.getCurrentDateTime())
      }
      scope.actFormProps.location.state = formState;
    }

    if(sid === 'growth_measurement' && state){
      const d = Cs.formatUpdateDate(state.visit_date ,'YYYY-MM-DD');
      scope.actFormProps.location.state.visit_date = d;
      scope.actFormProps.location.state.visit_date_formatted_date = d; 
    }else if(sid === 'prenatal_care' && state){
      const d = Cs.formatUpdateDate(state.record_date ,'YYYY-MM-DD');
      scope.actFormProps.location.state.record_date = d;
      scope.actFormProps.location.state.record_date_formatted_date = d; 
    }

    scope.actFormProps.match.params.schedule_id = scheduleId;
    scope.actFormProps.location.state.lmp_formatted_date = journeyCopy.data.lmp_formatted_date;

    if(sid === 'immunization'){
      scope.actFormProps.component = FieldGroupActivityForm;
      toggleInfantActivityModal();
    }else{
      scope.actFormProps.component = ActivityForm;
      toggleInfantActivityModal();
    }
  }

  const onFormSubmit = (activity={}) =>{
    if(activity.show_nutrition_care){
      openNotification(null, activity);
    }else{
      scope.selectedItem = activity;
      checkTestInputReviewNeeded(activity);
    }
    getActivityList();
    toggleInfantActivityModal();
  }

  /*const tabChange = function(key) {
    if(key=='nutrition_intake_bf'){
      toggleAssessMenuModal()
      setActivityType(key)
    }else{
      setActivityType(key)  
    }
  }*/

  const openNotification = (e, activity) =>{
    e && e.stopPropagation();
    scope.selectedItem = activity;
    toggleNutritionCareModal();
  }

  /*const openUploadForm = (e, activity) =>{
    e.stopPropagation()
    scope.selectedItem = activity
    toggleGrowthMeasureUploadModal()
  }*/

  const openPreSchedule = (activity) => {
    if(activity.pre_schedule_id){
      scope.selectedItem = activity;
      togglePreScheduleModal();
    }
  }

  const routeAssess = (path) =>{
    Cs.routeInfantPath(path, child, journeyProfile, history);
  }

  const openActivityCard = (activity) =>{
    scope.selectedItem = activity;
    scope.showReviewForm = false;
    setReviewActivityId(activity.id);
    toggleActivityCardModal();
  }

  const openDupActivityAlert = (activity) =>{
    setReviewActivityId(null);
    scope.selectedItem = activity;
    toggleDupActivityAlert();
  }

  const deleteConfirm = (e, activity) =>{
    e.stopPropagation();
    scope.selectedItem = activity;
    toggleDeleteModal();
  }

  //Check already form submitted in the given date.
  const getByRecordDate = async (date) => {
    const {status, data} = await ActivityService.getByRecordDate({
      'journey_profile_id':journeyProfile.id,
      'record_date':date,
      'form_id':formId,
      'activity_type':sid
    })
    if (status === 200 && data.activity) {
      openDupActivityAlert(data.activity);
    }
  }

  const deleteActivity = async (e) =>{
    e.preventDefault();
    const {status} = await ActivityService.delete(scope.selectedItem.id);
    if(status === 204){
      toggleDeleteModal();
      setActivities(activities.filter(a => a.id !== scope.selectedItem.id));
      scope.selectedItem = null;
    }
  }

  const openActivityForReview = (activity) =>{
    scope.selectedItem = activity;
    scope.showReviewForm = true;
    setReviewActivityId(activity.id);
    toggleActivityCardModal();
  }

  const closeTestInputReviewForm = () =>{
    isNutritionCareOpen && toggleNutritionCareModal();
    toggleTestInputReviewForm();
  }

  const openAssessCFChart = (e, activity) =>{
    e.stopPropagation();
    scope.selectedItem = activity;
    toggleAssessCFChart();
  }

  if(loading) return(<PreLoadSpinner/>)

  let components = {
    'prenatal_care':PrenatalCareTable,
    'growth_measurement':GrowthMeasureTable,
    'feeding_assessment':FeedingAssessmentTable,
    'assess_cf':AssessCFTable,
    'immunization':ImmunizationTable,
    'periodic_check':PeriodicCheck,
    'protein_intake':ProteinIntake
  }

  const TagName = components[componentName];  

  if(TagName){
    const activityProps = {
      'requestParams':scope.requestParams,
      childId: child?.id,
      sort,
      tableHeadLocale, 
      formFieldsGrouped, 
      sid, 
      dataSource, 
      permission, 
      labelLocale,
      routeAssess, 
      filter, 
      timeFilter, 
      currentUser, 
      onRangeFilterChange, 
      openActivityForReview, 
      routeActivityForm, 
      deleteConfirm, 
      openNotification, 
      openPreSchedule, 
      activities, 
      getObjLocale, 
      ageFormat, 
      getBgColorByZscore, 
      openActivityCard,
      tableFields,
      journeyProfile,
      caseAssessmentCount,
      openAssessCFChart
    }
    
    return (
      <>
        <TagName {...props} {...activityProps}/>
        
        <PaginationSM
          className="pagination-bar"
          currentPage={currentpage}
          totalItems={pagemeta.total}
          itemsPerPage={pagemeta.per_page}
          onPageChange={setCurrentpage}
          siblingCount={2}/>

        {activities.length === 0 &&
          <div className="text-center f16 m-t-15">
            {/*There is no data to display. Click "Add" or "+" to add details.*/}
            <EmptyRowAlert/>
          </div>
        }
                
        {isInfantActivityFormOpen && 
          <GenericModal toggleModal={toggleInfantActivityModal} 
            isPopupMode="true" onFormSubmit={onFormSubmit} 
            closeConfirmPopup={true} getByRecordDate={getByRecordDate}
            openDupActivityAlert={openDupActivityAlert}
            {...scope.actFormProps} />
        }

        {isNutritionCareOpen && 
          <GenericModal component={NutritionCareNotification} 
            toggleModal={()=>checkTestInputReviewNeeded(scope.selectedItem, toggleNutritionCareModal)} 
            isOpen={isNutritionCareOpen}
            itemType="infant.activity" 
            itemUuid={scope.selectedItem.id} 
            journeyProfileId={journeyProfile.id}
            mobileNo={journeyProfile.data.cell_number}
            countryCode={journeyProfile.data.country_code}/>
        } 

        {/*isGrowthMeasureUploadOpen && 
          <GenericModal component={UploadForm} 
          toggleModal={toggleGrowthMeasureUploadModal} 
          isOpen={isGrowthMeasureUploadOpen}
          itemType="infant.activity" item={selectedItem.id} 
          isPopupMode="true"/>*/
        }  

        {isPreScheduleOpen && 
          <GenericModal component={PreScheduleAlert} 
            title="Data to collect for the specified date and videos to watch"
            toggleModal={togglePreScheduleModal} 
            isOpen={isPreScheduleOpen}
            preScheduleId={scope.selectedItem.pre_schedule_id}
            child={child} 
            journeyProfile={journeyProfile} 
            schedule={scope.selectedItem} 
            isPopupMode="true"/>
        }

        <ConfirmMenu isOpen={isDeleteModalOpen} 
          toggleModal={toggleDeleteModal} 
          success={deleteActivity} />

        <FormTestInputReviewFormPopup 
          isOpen={isTestInputReviewFormOpen} 
          toggleModal={toggleTestInputReviewForm} 
          testInputId={scope.selectedItem?.test_input?.id} 
          onclose={closeTestInputReviewForm}/>

        {isActivityCardOpen &&
          <GenericModal 
            component={ActivityCard} 
            componentName={componentName}
            caseId={journeyProfile?.record_id}
            caseName={journeyProfile?.data?.mother_name}
            childName={child?.data?.baby_name}
            child={child} 
            currentUser={currentUser}
            routeActivityForm={routeActivityForm}
            isOpen={isActivityCardOpen}
            toggleModal={toggleActivityCardModal}
            deleteConfirm={deleteConfirm}
            activity={scope.selectedItem}
            formFields={formFields}
            sid={sid}
            isReviewEnabled={scope.selectedItem.id === reviewActivityId}
            showReviewForm={scope.showReviewForm}
            whatsAppPhoneNo={journeyProfile.last_updated_user?.phone_no}/>
        }

        {isDupActivityAlertOpen &&
          <GenericModal 
            component={DuplicateActivityAlert} 
            componentName={componentName}
            caseId={journeyProfile?.record_id}
            caseName={journeyProfile?.data?.mother_name}
            childName={child?.data?.baby_name}
            child={child} 
            currentUser={currentUser}
            isOpen={isDupActivityAlertOpen}
            toggleModal={toggleDupActivityAlert}
            activity={scope.selectedItem}
            formFields={formFields}
            sid={sid} />
        }

        {isAssessCFChartOpen ? (
          <GenericModal title={dataSource.label}
            component={AssessCFChart}
            isOpen={isAssessCFChartOpen} toggleModal={toggleAssessCFChart}
            activityId={scope.selectedItem.id} 
            formId={dataSource.child_form} 
            organizationId={journeyProfile.organization_id} 
            journeyProfileId={journeyProfile.id}/>
          ) : null
        }

        {isProteinIntakeChartOpen ? (
          <GenericModal title={dataSource.label}
            component={ProteinIntakeChart}
            isOpen={isProteinIntakeChartOpen} toggleModal={toggleProteinIntakeChart}
            activityId={scope.selectedItem.id}
            formId={dataSource.child_form}
            organizationId={journeyProfile.organization_id}
            journeyProfileId={journeyProfile.id} />
          ) : null
        }
      </>
    )
  }
  
  return <div>None</div>

}

const userInfo = (clientId, data, row) => {
    if(!row.id)
      return null

    return(
      <div className="text-center mxw-150">
        <UserName id={row.created_by} role={row.member_role}>{row.author_name}</UserName>
        <div>{row?.member_role?.role}</div>
        <div>{Cs.formatUpdateDate(row.updated_at, 'MMM D YYYY hh:mm A')}</div>
      </div>
    )
}

const scheduleInfo = (clientId, data, row) => {
  return(
    <div className="text-center mxw-150">
      {Cs.formatUpdateDate(row.scheduled_date, 'MMM D YYYY hh:mm A')}
    </div>
  )
}

const recordId = (clientId, data, row) => {
  return (
    <>
      {row.record_id ? row.record_id : "No data to display"}
      <div><DraftBadge isDraft={row.draft}/></div>
      <div className="flex justifycenter">
        <ReviewScore review={row.form_review}/>
      </div>
    </>
  );
};

const date = (clientId, data, row) => {
  return <>
    {row.id
      ? row.data?.record_date
      : Cs.formatUpdateDate(row.scheduled_date, 'MMM D YYYY')
    }
  </>
}

const PrenatalCareTable = ({journeyProfile, childId, caseAssessmentCount, filter, requestParams, openActivityCard, activities, tableFields, sort, dataSource, currentUser, tableHeadLocale, routeActivityForm, onRangeFilterChange, timeFilter, deleteConfirm, openNotification, labelLocale}) =>{
    const specialFields = [
      {label:tableHeadLocale(0), client_id:'record_id', ele:recordId},
      {label:tableHeadLocale(1), client_id:'data.record_date', ele:date}
    ]

    const specialFieldsLastCol = [
      {label:tableHeadLocale(2), client_id:'author_name', ele:userInfo}
    ]

    const OptionsEle = ({item}) => (
      <td>
        <div className="flex coldir aligncenter rgap-5 pointer">
          <LinkFontAwesomeIcon id="record_edit" 
            onSelect={e=>routeActivityForm(e, item.id, item.schedule_id, {record_date:item.scheduled_date})}
            iconCss="fas fa-edit font-18 white"/>
          
          {item.id ?
            <LinkFontAwesomeIcon id="record_delete" 
              onSelect={(e)=>deleteConfirm(e, item)}
              iconCss="far fa-trash-alt font-18 white"/>
            : null
          }

          {item.nutrition_care_alert_count > 0 &&
            <LinkFontAwesomeIcon id="record_alert" 
              count={item.nutrition_care_alert_count}
              onSelect={e=>openNotification(e, item)}
              iconCss="fas fa-envelope font-18 white"/>
          }  
        </div>  
      </td>
    )

    return(
      <div className="table-responsive table-fix-head"> 
        {childId ? null :
          <button id="add_measure_growth" data-tip={labelLocale(14)} 
            onClick={e=>routeActivityForm(e)} 
            type="button" className="btn btn-icon-add btn-sm text-white pull-right m-b-5 m-r-15">
              {labelLocale(3)}
          </button>
        }

        <span className="tooltip pull-right m-r-15" id="date_filter">
          <select onChange={(e)=>onRangeFilterChange(e.target.selectedIndex)}
            className="form-control ht-33 fw500 mxw-150"
            defaultValue={filter.label}>
              <OptionsObjList list={timeFilter?.options} idKey="label" labelKey="label" userLocale={currentUser.current_locale}/>
          </select>
          <CueTip tooltip={labelLocale(12)} positionCss="bottom"/>
        </span>

        {/* <AddCaseAssessmentIco 
          className="p-t-6 pull-right m-b-5 m-r-15 lh-17"
          journeyProfileId={journeyProfile.id}
          assessUserId={journeyProfile.created_by}
          count={caseAssessmentCount}
          tooltip={labelLocale(15)}
          assessmentData={{
            'assessment_types':'2',
            'assessment_types_array':[2], 
            'form_type':dataSource.label, 
            'form_type_sid':dataSource.sid
          }}
        /> */}

        <DynamicTable requestParams={requestParams} onSort={sort} 
          formFields={tableFields} specialFields={specialFields} 
          formDataList={activities} tClass="table" isSortable={false} 
          OptionsEle={OptionsEle} currentLocale={currentUser.current_locale}
          specialFieldsLastCol={specialFieldsLastCol}
          onRowClick={openActivityCard} isSortable={false}/>
      </div>  
    )
  }

  /*Postnatal care ward*/
  const FeedingAssessmentTable = ({activities, journeyProfile, caseAssessmentCount, requestParams, sort, openActivityCard, currentUser, dataSource, tableFields, routeActivityForm, deleteConfirm, labelLocale, tableHeadLocale}) =>{

    const date = (client_id, row) => (
      <>
        {row.data?.date_training || Cs.formatUpdateDate(row.created_at, 'MMM D YYYY')}
      </>
    )

    const specialFields = [
      {label:tableHeadLocale(0), client_id:'record_id', ele:recordId},
      /*{label:'Date', client_id:'data.date_training', ele:date},
      {label:'By', client_id:'author_name'}*/
    ]
    
    const OptionsEle = ({item}) => (
      <td>
        <div className="flex coldir aligncenter rgap-5 pointer">
          <LinkFontAwesomeIcon id="record_edit" 
            onSelect={e=>routeActivityForm(e, item.id)} 
            className="fas fa-edit font-18 white"/> 
          <LinkFontAwesomeIcon id="record_delete" 
            onSelect={(e)=>deleteConfirm(e, item)} 
            iconCss="far fa-trash-alt font-18 white"/>
        </div>
      </td>
    )
    
    const specialFieldsLastCol = [
      {label:tableHeadLocale(2), client_id:'author_name', ele:userInfo}
    ]

    return(
      <div className="table-responsive table-fix-head">        
        <button id="add_immunization" data-tip={labelLocale(14)} 
          onClick={e=>routeActivityForm(e)} 
          type="button" className="btn btn-icon-add btn-sm text-white pull-right m-b-5 m-r-15">
            {labelLocale(3)}
        </button>

        <AddCaseAssessmentIco 
          className="p-t-6 pull-right m-b-5 m-r-15 lh-17"
          journeyProfileId={journeyProfile.id}
          assessUserId={journeyProfile.created_by}
          count={caseAssessmentCount}
          tooltip={labelLocale(15)}
          assessmentData={{
            'assessment_type':dataSource.label, 
            'assessment_type_sid':dataSource.sid
          }}/>

        <DynamicTable requestParams={requestParams} onSort={sort} 
          formFields={tableFields} specialFields={specialFields} 
          formDataList={activities} tClass="table" isSortable={false}
          specialFieldsLastCol={specialFieldsLastCol}
          OptionsEle={OptionsEle} onRowClick={openActivityCard}
          currentLocale={currentUser.current_locale}/>
      </div>  
    )
  }

  const AssessCFTable = ({journeyProfile, caseAssessmentCount, openNotification, activities, requestParams, sort, dataSource, openActivityCard, currentUser, tableFields, routeActivityForm, deleteConfirm, labelLocale, tableHeadLocale, openAssessCFChart}) =>{

    const specialFields = [
      {label:tableHeadLocale(0), client_id:'record_id', ele:recordId}
    ]
    
    const specialFieldsLastCol = [
      {label:tableHeadLocale(2), client_id:'author_name', ele:userInfo}
    ]

    const OptionsEle = ({item}) => (
      <td className="flex coldir text-center">
        <span id="record_edit" 
          onClick={e=>routeActivityForm(e, item.id)} 
          className="fas fa-edit font-18 text-muted"/> 
        <span id="record_delete" onClick={(e)=>deleteConfirm(e, item)} 
          className="far fa-trash-alt m-t-15 font-18 text-muted"/>
        {item.nutrition_care_alert_count > 0 &&
          <span id="record_alert" onClick={e=>openNotification(e, item)} 
            className="fas fa-envelope m-t-15 font-18 text-muted">
            <span className="badge-2 m-l-5 bg-lred">{item.nutrition_care_alert_count}</span> 
          </span>
        }
        <span onClick={(e)=>openAssessCFChart(e, item)} className="fa fas fa-chart-line m-t-15 font-18 text-muted"/>
      </td>
    )
    
    return(
      <div className="table-responsive table-fix-head">        
        <button type="button" id="add_immunization" data-tip={labelLocale(14)} 
          onClick={e=>routeActivityForm(e)} 
          className="btn btn-icon-add btn-sm text-white pull-right m-b-5 m-r-15">
          {labelLocale(3)}
        </button>

        <AssessCfChartBtn name={labelLocale(2)}
          organizationId={currentUser.current_organization_id} 
          journeyProfileId={journeyProfile.id}
          formId={dataSource.child_form}/>

        {/* <AddCaseAssessmentIco 
          className="p-t-6 pull-right m-b-5 m-r-15 lh-17"
          journeyProfileId={journeyProfile.id}
          assessUserId={journeyProfile.created_by}
          count={caseAssessmentCount}
          tooltip={labelLocale(15)}
          assessmentData={{
            'assessment_types':'2',
            'assessment_types_array':[2], 
            'form_type':dataSource.label, 
            'form_type_sid':dataSource.sid
          }}
        /> */}

        <DynamicTable requestParams={requestParams} onSort={sort} 
          formFields={tableFields} specialFields={specialFields} 
          formDataList={activities} tClass="table" isSortable={false}
          specialFieldsLastCol={specialFieldsLastCol}
          OptionsEle={OptionsEle} onRowClick={openActivityCard}
          currentLocale={currentUser.current_locale}/>
      </div>  
    )
  }

  const ImmunizationTable = ({timeFilter, onRangeFilterChange, filter, activities, requestParams, journeyProfile, caseAssessmentCount, sort, openActivityCard, dataSource, currentUser, tableFields, routeActivityForm, deleteConfirm, labelLocale, tableHeadLocale}) =>{
    
    const specialFields = [
      {label:tableHeadLocale(0), client_id:'record_id', ele:recordId}
    ]
    
    const specialFieldsLastCol = [
      {label:"Schedule Date", client_id:'scheduled_date', ele:scheduleInfo},
      {label:tableHeadLocale(2), client_id:'author_name', ele:userInfo}
    ]

    const formFieldViewEle = {
      immunization: (clientId, data, ds) => ds.immunization ? (
        <div>
          {ds.immunization.label} / {ds.immunization.months ? (ds.immunization.months + ' Month') : (ds.immunization.weeks + ' Week')}
        </div>
      ) : null
    }

    const OptionsEle = ({item}) => (
      <td>
        <div className="flex coldir aligncenter rgap-5 pointer">
          <LinkFontAwesomeIcon  id="record_edit" 
            onSelect={e=>routeActivityForm(e, item.id, item.schedule_id, {visit_date:item.scheduled_date})} 
            iconCss="fas fa-edit font-18 white"/> 
          <LinkFontAwesomeIcon id="record_delete" 
            onSelect={(e)=>deleteConfirm(e, item)} 
            iconCss="far fa-trash-alt font-18 white"/> 
          </div>
      </td>
    )
    
    return(
      <div className="table-responsive table-fix-head">        
        <button id="add_immunization" data-tip={labelLocale(14)} 
          onClick={e=>routeActivityForm(e)} 
          type="button" className="btn btn-icon-add btn-sm text-white pull-right m-b-5 m-r-15">
            {labelLocale(3)}
        </button>

        <AddCaseAssessmentIco 
          className="p-t-6 pull-right m-b-5 m-r-15 lh-17"
          journeyProfileId={journeyProfile.id}
          assessUserId={journeyProfile.created_by}
          count={caseAssessmentCount}
          tooltip={labelLocale(15)}
          assessmentData={{
            'assessment_type':dataSource.label, 
            'assessment_type_sid':dataSource.sid
          }}/>

        <span className="tooltip pull-right m-r-15" id="date_filter">
          <select onChange={(e)=>onRangeFilterChange(e.target.selectedIndex)}
            className="form-control ht-33 fw500 mxw-150"
            defaultValue={filter.label}>
            <OptionsObjList list={timeFilter?.options} idKey="label" labelKey="label" userLocale={currentUser.current_locale}/>
          </select>
          <CueTip tooltip={labelLocale(12)} positionCss="bottom"/>
        </span>

        <DynamicTable requestParams={requestParams} onSort={sort} 
          formFields={tableFields} specialFields={specialFields} 
          formDataList={activities} tClass="table" isSortable={false}
          specialFieldsLastCol={specialFieldsLastCol}
          OptionsEle={OptionsEle} onRowClick={openActivityCard}
          currentLocale={currentUser.current_locale}
          formFieldViewEle={formFieldViewEle}/>
      </div>  
    )
  }

const PeriodicCheck = ({activities, requestParams, sort, journeyProfile, caseAssessmentCount, openActivityCard, dataSource, currentUser, tableFields, routeActivityForm, deleteConfirm, labelLocale, tableHeadLocale}) =>{

    const specialFields = [
      {label:tableHeadLocale(0), client_id:'record_id', ele:recordId}
    ]
    
    const specialFieldsLastCol = [
      {label:tableHeadLocale(2), client_id:'author_name', ele:userInfo}
    ]

    const OptionsEle = ({item}) => (
      <td>
        <div className="flex coldir aligncenter rgap-5 pointer">
          <LinkFontAwesomeIcon id="record_edit" 
            onSelect={e=>routeActivityForm(e, item.id)} 
            iconCss="fas fa-edit font-18 white"/>  
          <LinkFontAwesomeIcon id="record_delete" 
            onSelect={(e)=>deleteConfirm(e, item)} 
            iconCss="far fa-trash-alt font-18 white"/>
        </div>
      </td>
    )
    
    return(
      <div className="table-responsive table-fix-head">        
        <button id="add_immunization" data-tip={labelLocale(14)} 
          onClick={e=>routeActivityForm(e)} 
          type="button" className="btn btn-icon-add btn-sm text-white pull-right m-b-5 m-r-15">
            {labelLocale(3)}
        </button>

        <AddCaseAssessmentIco 
          className="p-t-6 pull-right m-b-5 m-r-15 lh-17"
          journeyProfileId={journeyProfile.id}
          assessUserId={journeyProfile.created_by}
          count={caseAssessmentCount}
          tooltip={labelLocale(15)}
          assessmentData={{
            'assessment_type':dataSource.label, 
            'assessment_type_sid':dataSource.sid
          }}
        />

        <DynamicTable requestParams={requestParams} onSort={sort} 
          formFields={tableFields} specialFields={specialFields} 
          formDataList={activities} tClass="table" isSortable={false} 
          specialFieldsLastCol={specialFieldsLastCol}
          OptionsEle={OptionsEle} onRowClick={openActivityCard}
          currentLocale={currentUser.current_locale}/>
      </div>  
    )
  }

  const ProteinIntake = ({activities, requestParams, sort, journeyProfile, caseAssessmentCount, openNotification, openActivityCard, dataSource, currentUser, tableFields, routeActivityForm, deleteConfirm, labelLocale, tableHeadLocale}) =>{

    const specialFields = [
      {label:tableHeadLocale(0), client_id:'record_id', ele:recordId}
    ]
    
    const specialFieldsLastCol = [
      {label:tableHeadLocale(2), client_id:'author_name', ele:userInfo}
    ]

    const OptionsEle = ({item}) => (
      <td>
        <div className="flex coldir aligncenter rgap-5 pointer">
          <LinkFontAwesomeIcon id="record_edit" 
            onSelect={e=>routeActivityForm(e, item.id)} 
            iconCss="fas fa-edit font-18 white"/>  

          <LinkFontAwesomeIcon id="record_delete" 
            onSelect={(e)=>deleteConfirm(e, item)} 
            iconCss="far fa-trash-alt font-18 white"/>

          {item.nutrition_care_alert_count > 0 &&
            <LinkFontAwesomeIcon id="record_alert" 
            count={item.nutrition_care_alert_count}
            onSelect={e=>openNotification(e, item)} 
            iconCss="fas fa-envelope font-18 white"/>
          }
        </div>
      </td>
    )
    
    return(
      <div className="table-responsive table-fix-head">        
        <button id="add_immunization" data-tip={labelLocale(14)} 
          onClick={e=>routeActivityForm(e)} 
          type="button" className="btn btn-icon-add btn-sm text-white pull-right m-b-5 m-r-15">
          {labelLocale(3)}
        </button>

        {/*<ProteinIntakeChartBtn name={labelLocale(2)}
          organizationId={currentUser.current_organization_id} 
          journeyProfileId={journeyProfile.id}
          formId={dataSource.child_form}/>*/}

        {/* <AddCaseAssessmentIco 
          className="p-t-6 pull-right m-b-5 m-r-15 lh-17"
          journeyProfileId={journeyProfile.id}
          assessUserId={journeyProfile.created_by}
          count={caseAssessmentCount}
          tooltip={labelLocale(15)}
          assessmentData={{
            'assessment_types':'2',
            'assessment_types_array':[2], 
            'form_type':dataSource.label, 
            'form_type_sid':dataSource.sid
          }}
        /> */}

        <DynamicTable requestParams={requestParams} onSort={sort} 
          formFields={tableFields} specialFields={specialFields} 
          formDataList={activities} tClass="table" isSortable={false} 
          specialFieldsLastCol={specialFieldsLastCol}
          OptionsEle={OptionsEle} onRowClick={openActivityCard}
          currentLocale={currentUser.current_locale}/>
      </div>  
    )
  }

const GrowthMeasureTable = ({caseAssessmentCount, tableHeadLocale, formFieldsGrouped, sid, dataSource, journeyProfile, permission, labelLocale ,routeAssess, filter, timeFilter, currentUser, onRangeFilterChange, openActivityForReview, routeActivityForm, deleteConfirm, openNotification, openPreSchedule, activities, getObjLocale, ageFormat, getBgColorByZscore, openActivityCard}) =>{

  const showPercentile = Organization.kcorp != currentUser.current_organization.record_id;

  return (
    <div className="table-responsive table-fix-head">
      <FontAwesomeBtn id="add_measure_growth" tooltip={labelLocale(14)}
        iconCss="fa fas fa-plus" isResponsive={false} name={labelLocale(3)}
        css="pull-right m-b-5 m-r-15 lh-17" colorCss="btn-green"
        onSelect={(e)=>routeActivityForm(e)}>
      </FontAwesomeBtn>

      <FontAwesomeBtn id="who_charts" tooltip={labelLocale(13)}
        iconCss="fa fas fa-chart-line" isResponsive={false} name={labelLocale(2)}
        css="pull-right m-b-5 m-r-15 lh-17" colorCss="bg-highlight"
        onSelect={()=>routeAssess({sid:'wellness'})}>
      </FontAwesomeBtn>

      <span className="tooltip pull-right m-r-15" id="date_filter">
        <select onChange={(e)=>onRangeFilterChange(e.target.selectedIndex)}
          className="form-control ht-33 fw500 mxw-150"
          defaultValue={filter.label}>
            <OptionsObjList list={timeFilter?.options} idKey="label" labelKey="label" userLocale={currentUser.current_locale}/>
        </select>
        <CueTip tooltip={labelLocale(12)} positionCss="bottom"/>
      </span>

      {/* <AddCaseAssessmentIco
        className="p-t-6 pull-right m-b-5 m-r-15 lh-17" 
        journeyProfileId={journeyProfile.id}
        assessUserId={journeyProfile.created_by}
        count={caseAssessmentCount}
        tooltip={labelLocale(15)}
        assessmentData={{
          'assessment_types':'2',
          'assessment_types_array':[2], 
          'form_type':dataSource.label, 
          'form_type_sid':dataSource.sid
        }}/> */}

      <table className="table">
        <thead>
          <tr>
            <th className='text-center' label="Id">
              {tableHeadLocale(0)}
            </th>
            <th className='text-center' label="Location">
              {getObjLocale(formFieldsGrouped.location)}
            </th>
            <th className='text-center' label="Measurement Date">
              {getObjLocale(formFieldsGrouped.visit_date)}
            </th>
            <th className='text-center' label="Age">
              {getObjLocale(formFieldsGrouped.age)}
            </th>
            <th className='text-center' label="W">
              {getObjLocale(formFieldsGrouped.baby_weight)}
            </th>
            <th className='text-center' label="H">
              {getObjLocale(formFieldsGrouped.height_baby)}
            </th>
            <th className='text-center' label="WFH">
              {getObjLocale(formFieldsGrouped.wfh)}
            </th>
            <th className='text-center' label="Visit Instructions">
              {getObjLocale(formFieldsGrouped.instructions)}
            </th>
            <th className='text-center' label="By">
              {tableHeadLocale(2)}
            </th>
            <th className='text-center' label="Actions">
              {tableHeadLocale(3)} 
            </th>
          </tr>
        </thead>
        <tbody>
          <GrowthMeasureTableData {...{showPercentile, caseAssessmentCount, tableHeadLocale, formFieldsGrouped, sid, dataSource, journeyProfile, permission, labelLocale ,routeAssess, filter, timeFilter, currentUser, onRangeFilterChange, openActivityForReview, routeActivityForm, deleteConfirm, openNotification, openPreSchedule, activities, getObjLocale, ageFormat, getBgColorByZscore, openActivityCard}}/>
        </tbody>
      </table>
    </div>
  )
}

const GrowthMeasureTableData = (props) => props.activities.map((a, i) => 
  <GrowthMeasureRowData {...props} a={a} key={i}/>
)

const GrowthMeasureRowData = ({a, caseAssessmentCount, showPercentile, tableHeadLocale, formFieldsGrouped, sid, dataSource, journeyProfile, permission, labelLocale ,routeAssess, filter, timeFilter, currentUser, onRangeFilterChange, openActivityForReview, routeActivityForm, deleteConfirm, openNotification, openPreSchedule, activities, getObjLocale, ageFormat, getBgColorByZscore, openActivityCard}) =>{
  let ds = a.data_source || {};
  let data = a.data || {};
  let scheduleDayDiff = null;
  let msg = '';
  if(!a.id){
    scheduleDayDiff = Cs.compareDate(a.scheduled_date, moment().format('YYYY-MM-DD'), 'days');
    //todayActivityItem = scheduleDayDiff === 0?a:null;
    //'A visit to take new measurements is scheduled for this date'
    msg =  scheduleDayDiff >= 0 ? 
      (a.notification_text || getObjLocale(formFieldsGrouped.measurement_scheduled))
      :
      getObjLocale(formFieldsGrouped.no_measurement);
  }
    
  data.age_ago = useMemo(() => ageFormat(a.id, a.scheduled_date, data), [a.id]);
  const weightZscoreStyle = useMemo(() => getBgColorByZscore(data.weight_zscore, data.weight_zscore_label), [data.weight_zscore, data.weight_zscore_label]);
  const heightZscoreStyle =  useMemo(() => getBgColorByZscore(data.height_zscore, data.height_zscore_label), [data.height_zscore, data.height_zscore_label]);
  const wfhZscoreStyle =  useMemo(() => getBgColorByZscore(data.wfh_zscore, data.wfh_zscore_label), [data.wfh_zscore, data.wfh_zscore_label]);

  const lastMgWhData = a.activity_form_id === 1177 
    ? {
      "height_baby":a.data?.height_baby,
      "baby_weight":a.data?.baby_weight
    } 
    : {
      "height_baby":a?.data_source?.height_baby?.label,
      "baby_weight":a?.data_source?.baby_weight?.label
    }

  return(
    <tr>
      <td className="text-center" onClick={()=> openActivityCard(a)}>
        {a.record_id}
        {scheduleDayDiff === 0 ? <div className="dot-highlight"/> : null}
        <DraftBadge isDraft={a.draft}/>
        {data.is_birth_measure === 1 ? <div>Birth Measurement</div> : null}
        <div className="flex justifycenter">
          <ReviewScore review={a.form_review}/>
        </div>
      </td>

      <td className="text-center mxw-50">
        {ds.location?.label}
        <div className="lred">{ds.reason_no_measure?.label}</div>
      </td>

      <td className="text-center">
        {a.id
          ?data.visit_date
          :Cs.formatUpdateDate(a.scheduled_date, 'MMM D YYYY')
        }
      </td>
        
      <td className="text-center">
        {data.age_ago}
      </td>

      <td className="text-center">
        {lastMgWhData.baby_weight && 
          <ul className="flex aligncenter coldir badge-boxes">
            <li className="badge m-5-0" style={weightZscoreStyle.r1}>{lastMgWhData.baby_weight} kgs</li>
            <li className="badge m-5-0" style={weightZscoreStyle.r2}>{data.weight_zscore} Z</li>
            {showPercentile ? <li className="badge m-5-0 bg-voilet text-voilet">{Cs.round(data.weight_percentile)}%</li> : null}
            <li className="badge m-5-0" style={weightZscoreStyle.r4}>{data.weight_zscore_label}</li>
          </ul>
        }
      </td>

      <td className="text-center">
        {lastMgWhData.height_baby &&
          <ul className="flex aligncenter coldir badge-boxes">
            <li className="badge m-5-0" style={heightZscoreStyle.r1}>{lastMgWhData.height_baby} cms</li>
            <li className="badge m-5-0" style={heightZscoreStyle.r2}>{data.height_zscore} Z</li>
            {showPercentile ? <li className="badge m-5-0 bg-voilet text-voilet">{Cs.round(data.height_percentile)}%</li> : null}
            <li className="badge m-5-0" style={heightZscoreStyle.r4}>{data.height_zscore_label}</li>
          </ul>
        }  
      </td>

      <td className="text-center">
        {data.wfh && 
          <ul className="flex aligncenter coldir badge-boxes">
            <li className="badge m-5-0" style={wfhZscoreStyle.r1}>{data.wfh}</li>
            <li className="badge m-5-0" style={wfhZscoreStyle.r2}>{data.wfh_zscore} Z</li>
            {showPercentile ? <li className="badge m-5-0 bg-voilet text-voilet">{Cs.round(data.wfh_percentile)}%</li> : null}
            <li className="badge m-5-0 d-hide" style={wfhZscoreStyle.r4}>{data.wfh_zscore_label}</li>
          </ul>
        }
      </td>

      <td className="text-center tooltip" onClick={()=>openPreSchedule(a)}>
        {(msg.length > 0 || a.pre_schedule_id) &&  
          <>
            <span className="fa fa-info-circle font-18 text-gray"/>
            <CueTip tooltip={msg}/>
          </>
        }
      </td>

      <td className="text-center mxw-150">
        <UserName id={a.created_by} role={a.member_role}>{a.author_name}</UserName>
        <div>{a?.member_role?.role}</div>
        <div>{Cs.formatUpdateDate(a.updated_at, 'MMM D YYYY hh:mm A')}</div>
        <div className="flex justspacebetween">
          {a.case_report_verifications?.map((r, i)=> 
            <span key={i} className="pill bg-highlight white m-r-5">{r.data?.type_label}</span>
          )}
        </div>
      </td>

      <td>
        <div className="flex coldir aligncenter rgap-5 pointer">
          {data.is_birth_measure !== 1 && CheckAccess.hasMcjActivityEditAccess(currentUser, a, sid) &&
            <>
              <LinkFontAwesomeIcon id="record_edit" 
                onSelect={e=>routeActivityForm(e, a.id, a.schedule_id, {visit_date:a.scheduled_date})} 
                iconCss="fas fa-edit font-18 white" />  
              
              <LinkFontAwesomeIcon id="record_delete" 
                onSelect={(e)=>deleteConfirm(e, a)} 
                iconCss="far fa-trash-alt font-18 white"/>     
            </>
          } 
          {a.nutrition_care_alert_count > 0 &&
            <LinkFontAwesomeIcon id="record_alert" 
              onSelect={e=>openNotification(e, a)}
              count={a.nutrition_care_alert_count} 
              iconCss="fas fa-envelope font-18 white"/>
          }
          {!a.id && Cs.compareDate(a.scheduled_date, null, 'days', false, true) <= 0 && 
            <LinkFontAwesomeIcon 
              onSelect={e=>routeActivityForm(e, a.id, a.schedule_id, {visit_date:a.scheduled_date})} 
              iconCss="fas fa-plus font-18 white"/>
          } 
          {a.id && permission?.review && 
            <LinkFontAwesomeIcon
              onSelect={()=>openActivityForReview(a)}
              count={a.review_count}
              iconCss="far fa-comment-alt font-18 white"/>
          }  

          <FilesListBtn className="m-t-10" activityId={a.id} 
            journey={journeyProfile} 
            organizationId={currentUser.current_organization_id}>
              <LinkFontAwesomeIcon iconCss="fas fa-camera font-18 white"/>
              {a.case_report_count > 0 ? <span className="badge-2 m-l-5 bg-lred">{a.case_report_count}</span> :null}
          </FilesListBtn>
        </div>
      </td>
    </tr>
  )
}

export default InfantActivityTable;