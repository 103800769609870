import React, { Fragment, useState, useEffect } from 'react';
import ChatService from './ChatService';
import CommonService from '../../services/CommonService';
import useStyle from '../../hooks/useStyle';
import { DivRichTextView, SpanRichTextView } from '../Common/RichTextView';
import FileUploadService from '../../services/FileUploadService';
import { useCurrentUserHook } from '../Authentication/useUserHook';
import { Spinner } from '../Common/Spinner';

let chat = {};
const ChatConversation = ({chatUser, toggleModal, newMessage, setChatUser}) => {
  useStyle('chat')
  
  const {currentUser,screen} = useCurrentUserHook()
  const [chatList, setChatList] = useState([])
  const [text, setText] = useState('')
  const [parentChat, setParentChat] = useState({})
  const [loading, setLoading] = useState(true)

  let requestParams = {
     'sort_column': 'updated_at',
     'sort_direction': 'asc',
     'reciever_id':chatUser.user_id
  }

  useEffect(() =>{
    if(newMessage){
      setChatList([...chatList, newMessage])
      scrollTo(chatList)  
    }
  }, [newMessage])

  let getChatsList = () => {
    setLoading(true)
    ChatService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        setLoading(false)
        setChatList(res.data.chats)
        scrollTo(res.data.chats)
      }
    })
  }

  useEffect(() => {
      resetChat()
      getChatsList()
  }, [chatUser])

  const resetChat = ()=>{
    chat = {};
    chat.reciever_id = chatUser.user_id
  }

  const addChat = (e, text) => {
    setLoading(true)
    e.preventDefault()
    if(text=='')return
    if(chat.id){
      chat.message = text
      ChatService.update(chat).then((res)=>{
        if(res.status == 204){
          setLoading(false)
          setText('')
          setChatList((chatList.filter(i => i.id !== chat.id)).concat([chat]))
          resetChat()
          scrollTo(chatList)
        }
      })
    }else{
      chat.message = text
      if(parentChat.id)chat.parent_chat_id = parentChat.id;
      ChatService.add(chat).then((res)=>{
        if(res.status == 201){
          setLoading(false)
          chat = res.data.chat
          chat.parent_chat = parentChat.id?parentChat:null;
          chat.author = {name: currentUser.name}
          setChatList([...chatList, chat])
          resetChat()
          setParentChat({})
          scrollTo(chatList)
        }
      })
    }
  }

  const deleteChat = (chat) => {
    setLoading(true)
    ChatService.delete(chat).then((res)=>{
      setLoading(false)
      setChatList(chatList.filter(i => i.id !== chat.id))
      scrollTo(chatList)
    })  
  }

  const setEdit =(c)=>{
    chat = c;
    setText(c.chat)
  }

  const setReply = (chat) =>{
    setParentChat(chat)
    scrollTo(chatList)
  }

  function scrollTo(comments){
    if(comments.length>0){
      let ele = document.getElementById('last_comment');  
      document.getElementById('chat-main').scrollTop = ele.offsetTop;
    }
  }

  const uploadFileToUrl = (event) => {
    try {
      let files = event.target.files
      let ele =  event.target
      let FileSize = files[0].size / 1024 / 1024; // in MB
      if (FileSize > 2) {
        ele.value = null
        //setFileSizeError(true)
        return
      }else{
        //setFileSizeError(false)
      }

     if(files.length>0){
        //setShowUploadProgress(true)
        FileUploadService.uploadFileToUrl(files[0], null, chat, 'chat', 'family_tree/chats/file_upload').then((data)=> {
          ele.value = null
          //chat.record_id = res.data.chat.record_id;
          setChatList([...chatList, data.data.chat])
          /*setText('')*/
          chat = {};
          scrollTo(chatList)
          //setShowUploadProgress(false)
        }, (err)=>{
          //setShowUploadProgress(false)
        })
      }
    } catch (e) {
      //setShowUploadProgress(false)
    }
  }

  const CommentType = ({chat}) =>{
    if(chat.file_upload_id){
      return(
        <a target="_blank" href={CommonService.getFileUrl(chat.message)}>
          <img src={CommonService.getIconByType(chat.message)} width="75px"/>
        </a>
      )
    }else if(chat.parent_chat){
      return(
        <Fragment>
          <DivRichTextView className="reply-chat" style={{backgroundColor: "#afdeda", padding: "5px 12px 7px 8px", borderLeft: "5px solid #24c99c", marginBottom:"6px"}} html={chat.parent_chat.message}/>
          <SpanRichTextView  html={chat.message}/>
        </Fragment>
      )
    }else{
      return(
        <Fragment>
          <SpanRichTextView html={chat.message}/>
        </Fragment>
      )
    }
  }

  const chatsItem = chatList.map((c, k) =>
    <Fragment key={k}>
      <div id={`chat_${c.id}`} className={`speech-bubble ${c.created_by == currentUser.id?'speech-left':'speech-right'}`}>
        <CommentType chat={c}/>
        <span className="speech-read mb-3">{c.author && c.author.name} - {CommonService.formatUpdateDate(c.updated_at)}</span>
        { c.created_by == currentUser.id &&
          <div className="m-t-5">
          <div className="font-16 pull-left m-r-5">
              <i className="far fa-eye-slash text-muted" aria-hidden="true"></i>
            </div>
            <div className="font-16 pull-right m-r-5" onClick={e=>deleteChat(c)}>
              <i className="far fa-trash-alt" aria-hidden="true"></i>
            </div>
            {!c.file_upload_id &&
            <div className="font-16 pull-right m-r-15" onClick={e=>setEdit(c)}>
              <i className="far fa-edit" aria-hidden="true"></i>
            </div>}         
          </div> }
       
          <div className="m-t-5">
          <div className="font-16 pull-right m-r-15" onClick={e=>setReply(c)}>
              <i className="fas fa-reply" aria-hidden="true"></i>
            </div>
          </div>
       
      </div>
      <div className="clear"/>
    </Fragment>
  )

  const CommentForm = (props) =>{
    const [text, setText] = useState(props.text || '')
    
    return(
      <div className="container">
        <form className="clearfix speech-footer" onSubmit={e=> props.addChat(e, text)}>
          {parentChat.id && <div className="speech-reply">{parentChat.message}
            <span onClick={e=>setReply({})} className="pull-right">
              <i className="fas fa-times"></i>
            </span>
          </div>}
          <div className="col-xs-1 col-md-1 p-t-3 center-text">
            <label className="round-btn brd-17 bg-lgreen" htmlFor="file_upload">
              <i className="fas fa-paperclip"></i>
            </label>
            <input type="file" id="file_upload" className="hidden" onChange={e => props.uploadFileToUrl(e)} />
          </div>
          <div className="form-group col-md-10 col-xs-10 speach-input center-text">
            <input className="m-l-5" type="text" value={text} placeholder="Enter your message here" onChange={e => setText(e.target.value)} autoFocus/>
          </div>
          <div className="col-xs-1 col-md-1 p-t-3 center-text">
            <div onClick={e=> addChat(e,text)} className="round-btn brd-17 bg-lgreen"><i className="fas fa-arrow-up"></i></div>
          </div>
        </form>
      </div>
    )
  }

  const CommentBox = () =>(
    <Fragment>
      <div className="ml-center bg-lgreen white p-5">
        <div className="font-16 bold-600">{chatUser.email}</div>
        <span className="ml-button ml-xlarge ml-hover-red ml-display-topright" title="Close Modal"
          onClick={e=>setChatUser()}>×
        </span>
      </div>

      <div className="m-b-0 chat-main" id="chat-main" style={{height:screen.height-300}}>
        {chatsItem.length==0 && 
          <div className="no-chat font-15">No chats yet.You can add one now.</div>
        }
        {loading ? <Spinner/> : chatsItem}
        <div id="last_comment" className="m-t-5"></div>
      </div>
      <CommentForm text={text} addChat={addChat} uploadFileToUrl={uploadFileToUrl}/>
    </Fragment>    
  ) 

  return(
    <div className="m-t-15">
      <div className="ml-card-4 bg-white">
        <CommentBox/>
      </div>
    </div>
  )
}

export default ChatConversation;