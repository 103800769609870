import React, {useState, useEffect, useContext, useMemo} from "react";
import FormSuggestionService from "./FormSuggestionService";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import Cs from "../../services/CommonService";
import GenericModal from "../../components/Modals/GenericModal";
import useModal from "../../hooks/useModal";
import { Table, THead, TH, TR, TD, TBody } from "../Common/TableView";
import CheckAccess from '../Roles/CheckAccess'
import DataSourceService from "../../services/DataSourceService";
import FileUploadService from "../../services/FileUploadService";
import { useCurrentUserHook } from "../Authentication/useUserHook";
import { Spinner } from "../Common/Spinner";

const FormFieldImageSuggestions = (props) => {
  const {formField} = props;
  const {currentUser} = useCurrentUserHook()
  
  const [loading, setLoading] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [pagemeta, setPagemeta] = useState([]);
  const [requestParams, setRequestParams] = useState({
    'page':1,
    'per_page':15,
    'organization_id': currentUser.current_organization_id,
    'form_id':formField.formTemplateId,
    'field_index':formField.index
  })

  useEffect(() => getAll(), [])

  const getAll = () =>{
    setLoading(true)
    FormSuggestionService.getAll(requestParams).then((res)=>{
      setSuggestions(res.data.form_suggestions || []);
      setPagemeta(res.data.meta);
      setLoading(false);
    })
  }

  const uploadFileToUrl = (target, option, originalImgUrl) => {
    try {
      let file = target?.files[0];
      if(file){
        if ((file.size / 1024 / 1024) > 0.6) {
          target.value = null;
          alert('Files to be uploaded must not exceed  600Kb size.');
          return;
        }
      }else{
        return;
      }

      setLoading(true)
      const imageData = {
        'organization_id':currentUser.current_organization_id, 
        'fileable_type':'form_field_suggestion',
      }
      FileUploadService.uploadFileToUrl(file, 'private', imageData).then((res)=> {
        if(res.status == 201){
          const urlParts = originalImgUrl.split("/");
          create({
            'replace_file_upload_id':urlParts[urlParts?.length-2],
            'new_file_url':res.data.file_upload.file,
            'new_file_upload_id':res.data.file_upload.id,
            'data_soure_id': formField.data_source_id,
            'data_soure_list_id': option.pk_id
          })
        }
        target.value = null;
        setLoading(false)
      }, (err)=>{
        setLoading(false)
      })
    } catch (e) {
      setLoading(false)
    }
  }

  const create = (data) => {
    setLoading(true)
    const suggestion = {
      'data':data,
      'organization_id':currentUser.current_organization_id,
      'form_id':formField.formTemplateId,
      'suggestion_type':'field_image_replacement',
      'field_index':formField.index
    }
    FormSuggestionService.add(suggestion).then((res) => {
      if(res.status==201){
        setSuggestions([...suggestions, res.data.form_suggestion])
        setLoading(false)
      }
    }).catch(function(res){
       setLoading(false)
    })
  }

  const remove = (suggestion) => {
    setLoading(true)
    FormSuggestionService.delete({'id':suggestion.id}).then((res) => {
      if(res.status == 204){
        setSuggestions(suggestions.filter((s) => s.id !== suggestion.id))
        setLoading(false)
      }
    }).catch(function(res){
       setLoading(false)
    })
  }

  const updateDataSourceList = (suggestion, dataSource, imgDatakey, imgIdx) =>{
    if(imgIdx != null){
      //Multi image
      dataSource[imgDatakey][imgIdx] = suggestion.data.new_file_url
    }else{
      //single image
      dataSource[imgDatakey] = suggestion.data.new_file_url
    }
    DataSourceService.updateDataSourceList(dataSource.pk_id, {'id':dataSource.pk_id, 'data':dataSource}).then((res) => {
      alert("Success")
      setLoading(false)
    }).catch(function(res){
       setLoading(false)
    })
  }

  const dataTypes = Object.values(formField.options_key_value?.data_types || {})

  return(
    <div id="ct" className="row">
      {loading ? <Spinner/> : null}
      <Table className="shadow-small">
        <THead>
          <TR>
            <TH>
              Actual Image 
            </TH>
            <TH>
              Suggestion Image
            </TH>
          </TR>
        </THead>
        <TBody>
          {formField.options_key_value?.options.map((o, ci)=>
            <TR key={ci}>
              {dataTypes.map((type, i) =>
                <ImageContainer type={type} 
                  option={o} key={i} 
                  uploadFileToUrl={uploadFileToUrl}
                  suggestions={suggestions}
                  remove={remove}
                  replaceImg={updateDataSourceList}/>
              )}
            </TR>
          )}
        </TBody>
      </Table>
    </div>
  )
}

const ImageContainer = ({type, option, uploadFileToUrl, remove, suggestions, replaceImg})=>{
  const suggestion = suggestions.find((s)=> s.data?.data_soure_list_id == option.pk_id)
  if(['multi_image_upload', 'public_multi_image_upload'].indexOf(type.type)>-1){
    return <ImageList lists={option[type.key]} option={option} uploadFileToUrl={uploadFileToUrl} suggestion={suggestion} remove={remove} replaceImg={replaceImg} imgDataKey={type.key}/>
  }else if(['image_upload', 'public_image_upload'].indexOf(type.type)>-1){
    return <Image url={option[type.key]} option={option} uploadFileToUrl={uploadFileToUrl} suggestion={suggestion} remove={remove} replaceImg={replaceImg} imgDataKey={type.key}/>
  }

  return null
}

const Image = ({url, option, uploadFileToUrl, suggestion, remove, replaceImg, imgDataKey, imgIdx}) => {
  if(url){
    return(
      <>
        <TD>
          <img src={Cs.getIconByType(url)} className="img-responsive w-250 ht-150 p-5"/> 
          <div className="m-t-25">
            <div className="ellipsis">{Cs.getFileName(url)}</div>
          </div>   
        </TD>
         <TD>
            {suggestion ? 
              <div className="flex coldir">
                <img src={Cs.getIconByType(suggestion.data?.new_file_url)} className="img-responsive w-250 ht-150 p-5"/> 
                <div className="m-t-25">
                  <div>{Cs.getFileName(url)}</div>
                  <div>{Cs.formatUpdateDate(suggestion.created_at)}</div>
                </div> 
                <div>
                  <span className="far fa-trash-alt badge-2 m-t-10 font-18" onClick={()=>remove(suggestion)}/>
                  {/*<span className="far fa-images badge-2 m-t-10 font-18 m-l-5" onClick={()=>replaceImg(suggestion, option, imgDataKey, imgIdx)}/>*/}
                </div>
              </div>
              :
              <div className="flex aligncenter">
                <label htmlFor={"file_upload_btn_"+option.value}>
                  <span className="fas fa-plus badge-2 m-t-10 font-18">
                  </span>
                </label>
                <input type="file" id={"file_upload_btn_"+option.value} className="hidden" onChange={e => uploadFileToUrl(e.target, option, url)}/>
              </div>
            }
        </TD>
      </>
    )
  }

  return null
}

const ImageList = ({lists=[], option, uploadFileToUrl, suggestion, remove, replaceImg, imgDataKey}) => lists ? lists.map((url, k)=>{
  return <Image key={k} url={url} option={option} uploadFileToUrl={uploadFileToUrl} 
    suggestion={suggestion} remove={remove} replaceImg={replaceImg} 
    imgDataKey={imgDataKey} imgIdx={k}/>
}):null

const ImageReplacement = ({formField, className}) => {
  const { state:{screen, user:currentUser} } = useContext(AuthContext)
  const { isOpen, toggleModal} = useModal()

  const permission = useMemo(() => 
    CheckAccess.getMemberPermissionBySid(currentUser, 'form', 'form_field_image_suggestion'), [currentUser.id]
  )

  if(permission?.write){
    return(
      <>
        <span className={"fas fa-file-upload " + (className||'')} onClick={() => toggleModal()}/>

        {isOpen &&
          <GenericModal component={FormFieldImageSuggestions} 
            title="Image Replacement"
            formField={formField}
            isOpen={isOpen} 
            toggleModal={toggleModal}/>
        }
      </>
    )
  }

  return null
}

export {ImageReplacement};