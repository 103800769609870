import React, {useState, useEffect} from 'react'
import PlannerService from '../HealthJourney/Health/Planners/PlannerService'
import GenericModal from "../Modals/GenericModal"
import Cs from "../../services/CommonService"
import { Spinner } from './Spinner'

const VideoPlayer = ({url, width, height, className="video-container"}) => {
  if(url && url.includes('vimeo.com')){
		return(
			<iframe src={url} width={width} height={height}
				frameBorder="0" allow="fullscreen" 
				webkitallowfullscreen="true" mozallowfullscreen 
				allowFullScreen>
			</iframe>
		)  		
  }else if(url && url.includes('youtube.com')){
  	return(
  		<div className={className}>
	  		<iframe src={url}
					frameBorder="0" allow="fullscreen" 
					webkitallowfullscreen="true" mozallowfullscreen 
					allowFullScreen>
				</iframe>
  		</div>
  	)
  }

  return null	
}

const YouTubePlayer = ({videoList=[], startIndex=0, css, autoPlay=false}) =>{
  let player;
  
  //origin=https://health.cuetree.com"
  //widget_referrer - youtube analytics
  
  useEffect(()=>{
    try{
      if(!window.YT){ 
        loadYTIframe()
      }else{ 
        // If script is already there, load the video directly
        loadVideo()
      }
    }catch(e){
      console.error(e.message)
    }
  }, [videoList])

  const loadYTIframe = () =>{
    // If not, load the script asynchronously
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';

    // onYouTubeIframeAPIReady will load the video after the script is loaded
    window.onYouTubeIframeAPIReady = loadVideo;

    const firstScriptTag = document.getElementById('page');
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  const loadVideo = () => {
    //rel -> related videos will come from the same channel as the video that was just played
    player = new window.YT.Player('youtube-player', {
      playerVars: {'rel': 0, 'origin': window.location.href, 'widget_referrer' : window.location.href},
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChange
      }
    })
  }

  const onPlayerReady = event => {
    if(autoPlay){
      event.target.loadVideoById(videoList[startIndex]);
      //event.target.playVideo();
    }else{
      event.target.cueVideoById(videoList[startIndex]);
    }
  }

  const onPlayerStateChange = event => {
    if (event.data == window.YT.PlayerState.ENDED) {
      startIndex++;
      if (startIndex < videoList.length) {
        player.loadVideoById(videoList[startIndex]);
      }else{
        player.loadVideoById(videoList[0]);
      }
    }
  }

  return(
    <div className={css?css:'video-container'}>
      <div id="youtube-player"/>
    </div>
  )
}

const PlannerVideoPlayer = ({className, autoPlay=false, playInline=false, plannerId, url, plannerList, stepPlannerList, startIndex=0, toggleModal, isOpen, openInNewTab=false}) => {
	const [loading, setLoading] = useState(true)
  let [planner, setPlanner] = useState({})
  let [videoList, setVideoList] = useState([])
  let [plannerIdxUrl, setPlannerIdxUrl] = useState()
	const [isVideoPlayerOpen, setVideoPlayerOpen] = useState(!playInline)

	useEffect(()=>{
    setVideos()
	}, [plannerList, stepPlannerList, plannerId, url])

  const setVideos = () =>{
    try{
      setLoading(true)
      if(openInNewTab){
        //Embed
        if(plannerList?.[startIndex]){
          const params = new URL(plannerList[startIndex]?.data?.reference_url).searchParams
          setPlannerIdxUrl('https://www.youtube.com/embed/'+params.get('v'))
        }else if(stepPlannerList?.[startIndex]){
          const params = new URL(stepPlannerList[startIndex]?.data?.reference_url).searchParams
          setPlannerIdxUrl('https://www.youtube.com/embed/'+params.get('v'))
        }
      }else if(stepPlannerList?.length > 0){
        setVideoList(stepPlannerList.map((p) => {
          const params = new URL(p.data.url).searchParams
          return {
            videoId: params.get('v'),
            startSeconds: Cs.getSeconds(p.data.start_time),
            endSeconds: Cs.getSeconds(p.data.end_time)
          }
        }))
      }else if(url){
        const params = new URL(url).searchParams
        //planner.video_url = `${env.youtube_url}${params.get('v')}`
        setVideoList([{videoId:params.get('v')}])
      }else if(plannerList?.length > 0){
        setVideoList(plannerList.map((p) => {
          const params = new URL(p.data.reference_url).searchParams
          return {videoId: params.get('v')}
        }))
      }else if(plannerId){
        openVideo()
      }

      setTimeout(()=>{
        setLoading(false)
      }, 0)
    }catch(e){
      console.error(e.message)
    }
  }

	const openVideo = () =>{
    PlannerService.get(
      plannerId, 
      {columns:'reference_url', planner_id:plannerId}
    ).then(({status, data})=>{
      if(status === 200){
      	planner = data.planner
        setPlanner(data.planner)
        if(planner.reference_url){
          /*Same logic in Nutritioncare notification*/
          const params = new URL(planner.reference_url).searchParams
          //planner.video_url = `${env.youtube_url}${params.get('v')}`
          setVideoList([{videoId: params.get('v')}])
          setVideoPlayerOpen(true)
        }
      }  
    })
  }

  if(loading)
    return <Spinner/>

  if(openInNewTab && plannerIdxUrl)
    return <VideoPlayer url={plannerIdxUrl}/>

  if(videoList.length === 0)
    return null

  if(playInline)
    return <YouTubePlayer videoList={videoList} startIndex={startIndex} css={className || 'row'} autoPlay={autoPlay}/>

  if(isVideoPlayerOpen)
  	return <GenericModal component={YouTubePlayer}
			isOpen={isVideoPlayerOpen} 
      toggleModal={toggleModal}
      title="Video Player"
      videoList={videoList} 
      startIndex={startIndex}
      widthCss="w-100p"
      autoPlay={autoPlay}/>

}

export {VideoPlayer, YouTubePlayer, PlannerVideoPlayer};