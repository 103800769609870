import React, {Fragment, useContext, useState, useEffect} from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import AuthenticationService from "../../services/AuthService"
import useStyle from '../../hooks/useStyle'
import { Link } from "react-router-dom"
import CommonService from "../../services/CommonService"
import GeoLocation from "../Common/GeoLocation"
import {usePageTitle} from "../../hooks/pageHead"
import { Spinner } from "../Common/Spinner";

let geoData = {}
export const ForgetPassword = (props) => {
  useStyle('/css/login.css', true) 
  usePageTitle('Forget Password')

  const { state: authState, dispatch } = useContext(AuthContext)

  const initialState = {
    registration_mode: 'email',
    email: "",
    isSubmitting: false,
    errorMessage: null,
    isEmailSent: false
  }

  const [data, setData] = useState(initialState)
  const [count, setCount] = useState(Math.random())

  useEffect(() => {
    if(authState.isAuthenticated){
      props.history.push('/')
    }
  }, [authState.isAuthenticated])

  useEffect(()=>{
    if(localStorage.ct_health_rm){
      setDefaultRegmode(localStorage.ct_health_rm)
    }
  }, [])

  const setDefaultRegmode = (rm) => setData({...data, registration_mode:rm})

  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }

  const setAuthType = (type) => {
    setData({
      ...data,
      registration_mode: type,
    })
  }

  const handleForgetSubmit = (event) => {
    event.preventDefault()
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    })

    let req = { 
      user: { 
        phone_no: data.phone_no,
        email: data.email, 
        registration_mode: data.registration_mode,
        country_code:  geoData.geo_data?.country_code
      } 
    }
    
    AuthenticationService.forgotPassword(req).then((res) => {
      if (res.status == 200) {
        setData({
          ...data,
          isSubmitting: false,
          isEmailSent:true
        })
      } else {
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: res.message || res.statusText,
        })
      }
    }).catch((error) => {
      setData({ ...data, errorMessage: error.response.data.error });
    })
  }

  return (
    <div id="ct">
      <GeoLocation geoData={geoData} getAddress="true"/>
      {data.isSubmitting ? <Spinner/>:null}
      <section className="login-page flex justspacebetween">
        <form className="login-left wid-50 pos-rel flex aligncenter">
          <div className="login-form">
            {data.isEmailSent && 
              <h2 className="title m-b-30">Password reset link sent.</h2>
            }

            {!data.isEmailSent && 
              <>   
                <h2 className="title m-b-10">Reset your Account</h2>            
                {/*<div className="flex justspacebetween coldir-xs m-b-10">
                    <div onClick={e=>setAuthType('email')} className={`col-xs-6 auth-btn p5 ${data.registration_mode=='email'?'auth-btn-active':''}`}>Email</div>
                    <div onClick={e=>setAuthType('phone_no')} className={`col-xs-6 auth-btn p5 ${data.registration_mode=='phone_no'?'auth-btn-active':''}`}>Mobile</div>
                  </div>*/
                }
                {data.registration_mode == 'email' && 
                  <div className="form-fields flex coldir">
                    <label className="form-label m-b-10">Email</label>
                    <input
                      className="form-input fulwid"
                      type="email" placeholder="Email" 
                      name="email" onChange={handleInputChange}
                      required
                    />
                  </div>
                }
                {data.registration_mode == 'phone_no' &&  
                  <div className="form-fields flex coldir">
                    <label className="form-label m-b-10">Mobile</label>
                    <input type="password" className="form-input"
                      value={data.phone_no}
                      min="9999999999"
                      defaultValue={data.phone_no}
                      placeholder="9999999999"
                      name="phone_no"
                      onChange={handleInputChange}
                      required />
                  </div>
                }
            
                <button
                  disabled={data.isSubmitting}
                  onClick={handleForgetSubmit}
                  className="btn btn-primary fulwid f16 fw500 tcenter text-white m-t-20">
                  Send password reset instructions
                </button>
                <div className="m-t-50">
                  {/*<p>
                    Don’t have an account?{" "}
                    <Link to="/c2lnbnVw/signup" className="text-link">
                      <strong>Create an account </strong>
                    </Link>
                  </p>*/}
                </div>
              </>
            }
          </div>
        </form>
        <div className="login-right tcenter justifycenter wid-50 pos-rel flex aligncenter">
          <div className="login-content">
            <a href="#" className="m-b-20">
              <img className="w-115" src="images/logo/cuetree_Icon.png" alt="" />
            </a>
            <h3 className="fw500 m-b-15">Transform health and wellbeing journeys</h3>
            <p className="text-lite-gray m-b-20 wid-80 w-80 wid-100-xs mx-auto">
               {CommonService.displayRandomPhrase(Math.floor(4*count))}
            </p>
            <div className="text-lite-gray badge badge-md badge-content m-b-20 mx-auto fw500">
              From Screening to extended care
            </div>
            <div className="m-t-10 mx-auto">
              <ul className="flex gap-5 justifycenter">
                <li>
                  <a href="#">
                    <img src="images/fb-icon.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/instagram-icon.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/twitter-icon.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/linked-icon.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/whatsapp-icon.png" alt="" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex justspacebetween m-t-50">
              <p className="text-lite-gray f12">
                <i className="m-l-15 m-r-5 far fa-copyright" aria-hidden="true"/> 
                {new Date().getFullYear()} IPIPAL INC. All Rights Reserved.
              </p>
              <p className="f12">
                <Link to="/about_us/terms_of_service" className="text-link">
                  <strong>Terms of Service</strong>
                </Link>
                {" "}|{" "}
                <Link to="/about_us/privacy_policy" className="text-link">
                  <strong>Privacy policy </strong>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ForgetPassword;