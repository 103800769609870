import React, { useState, useEffect, useRef } from 'react';
import { CueTooltip } from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import { FieldLabel, getLocaleLabel, RequiredMsg, OptionLabel, getKeyByLocale } from '../FieldLabel';
import { TDTextInput, TDInputSelect, TDInputMultiSelect, TDInputRadio } from '../../Common/FormInput';
import { ImageFlexList } from '../../../components/Common/Image';
import { Spinner } from '../../Common/Spinner';

const InputTableView = ({labelLocale, renderField, field, screen, formData, readOnly, errors, formFn, isFormWizard, openFieldLabelModal, currentLocale, forceUpdate}) => {
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(true);
  let [data, setData] = useState(formData || {});
  const [dataSourceObject, setDataSourceObject] = useState({});

  useEffect(() => {
    if(renderField && field?.row_data_source_id){
      setDataSourceObject(field.data_sources);
      setLoading(false);
    }
  }, [renderField])

  if(!renderField){
    return null;
  }

  /*const getDataSourceList = (data_source_ids) =>{
    setLoading(true)
    let req = {
      data_source_params:{
        'locale':currentLocale,
        'parent_key': 'data_source_id',
        'data_source_id': data_source_ids,
        'fields':['data_type']
      }
    }

    Cs.getDataSource(req).then((res) => {
      setDataSourceObject(res)
      setLoading(false)
    })
  }*/ 

  let inputAttributes = {
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':readOnly
  }

  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes)
  //labelAttributes.className = 'm-b-10 f18 fw500'
  let validation = {
    required: field.required
  }

  const handleChange = (value, rowId) => {
    data[rowId] = value;
    formData[rowId] = value;
    setData({...data});
    setError();
    forceUpdate();
  }

  const setError = () => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = FormHelper.validateColumn(field, data)
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name];
      }
      setShowError(!showError);
      formFn.refreshFormSubmit();
    }
  }

  /*const validateColumn = () =>{
    for (const col of field.columns) {
      if(col.required){
        for (const row of dataSourceObject[field.row_data_source_id]?.options) {
          if(data[row.sid +'_'+ col.client_id]==null){
            return true
          }
        }
      }
    }
    return false
  }*/

  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>
            <span className="fas fa-exclamation-triangle m-r-5"/>
            <RequiredMsg {...{field, currentLocale}}/>
          </div>
        </div>
      )
    }
    return null;
  }

  if(loading)
    return <Spinner/>;
  
  return (
    <div {...parentAttributes} title="tooltip"> 
      <div className="flex coldir m-b-20">
        <FieldLabel field={field} 
          isFormWizard={isFormWizard}
          labelAttributes={labelAttributes} 
          currentLocale={currentLocale}
          openFieldLabelModal={openFieldLabelModal}/>
        <span className="fts-13">{field.description}</span>  
        <Error/>
        <div className="table-responsive tab-with-table p-0">
          <table className="table table-stripe table-head tl-fix w-full"> 
            <thead>
              <tr>
                <th></th> 
                <TableHeader formField={field} columns={field.columns} currentLocale={currentLocale}/>   
              </tr>
            </thead>

            <tbody>
              <TableRow formField={field} 
                rows={dataSourceObject[field.row_data_source_id]?.options}
                columns={field.columns}
                dataSourceObject={dataSourceObject}
                handleChange={handleChange}
                currentLocale={currentLocale}
                screen={screen}
                formData={formData}
                data={data}
                formFn={formFn}
                labelLocale={labelLocale}
                showError={(errors[field.client_id]?.touched || errors.isSubmitted)}/> 
            </tbody>
          </table>
        </div>
      </div>  
      <CueTooltip id={field.client_id} description={field.tooltip}/>
    </div> 
  )
}

const FieldGroupTableView = ({labelLocale, renderField, field, screen, formDataList, readOnly, errors, formFn, isFormWizard, openFieldLabelModal, currentLocale, forceUpdate}) => {
  let {current:scope} = useRef({formData:{}});
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(true);
  let [dataList, setDataList] = useState(formDataList || []);
  const [dataSourceObject, setDataSourceObject] = useState({});

  useEffect(() => {
    if(renderField && field?.row_data_source_id){
      setDataSourceObject(field.data_sources);
      setLoading(false);

      for (const row of formDataList) {
        for(const col of field.columns){
          scope.formData[row.row_sid +'_'+ col.client_id] = row[col.client_id];  
        }
      }

    }
  }, [renderField])

  if(!renderField){
    return null;
  }

  let inputAttributes = {
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled': readOnly
  }

  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  
  let validation = {
    required: field.required
  }

  const handleChange = (row, col, val, clientId) => {
    const rowId = field.client_id +'_'+ 'dsl_id';
    const foundIdx = formDataList.findIndex(v => v[rowId] == row.pk_id);
    
    let r = {
      row_sid: row.sid,
      [rowId]: row.pk_id
    }
    if(foundIdx > -1){
      r = {...r, ...formDataList[foundIdx]};
    }

    if(typeof val === 'object'){
      r[col.client_id +'_'+ 'dsl_id'] = val.pk_id;
      r[col.client_id] = val.value;
    }else{
      r[col.client_id] = val;
    }

    if(foundIdx > -1){
      formDataList[foundIdx] = r; 
    }else{
      formDataList.push(r);
    }
    setError();
    forceUpdate();
  }

  const setError = () => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = FormHelper.validateColumn(field, dataList);
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name];
      }
      setShowError(!showError);
      formFn.refreshFormSubmit();
    }
  }

  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>
            <span className="fas fa-exclamation-triangle m-r-5"/>
            <RequiredMsg {...{field, currentLocale}}/>
          </div>
        </div>
      )
    }
    return null;
  }

  if(loading)
    return <Spinner/>;
  
  return (
    <div {...parentAttributes} title="tooltip"> 
      <div className="flex coldir m-b-20">
        <FieldLabel field={field} 
          isFormWizard={isFormWizard}
          labelAttributes={labelAttributes} 
          currentLocale={currentLocale}
          openFieldLabelModal={openFieldLabelModal}/>
        <span className="fts-13">{field.description}</span>  
        <Error/>
        <div className="table-responsive tab-with-table p-0">
          <table className="table table-stripe table-head tl-fix w-full"> 
            <thead>
              <tr>
                <th></th> 
                <TableHeader formField={field} 
                  columns={field.columns} 
                  currentLocale={currentLocale}/>   
              </tr>
            </thead>

            <tbody>
              <TableRow formField={field} 
                rows={dataSourceObject[field.row_data_source_id]?.options}
                columns={field.columns}
                dataSourceObject={dataSourceObject}
                handleChange={handleChange}
                currentLocale={currentLocale}
                screen={screen}
                formData={scope.formData}
                data={scope.formData}
                formFn={formFn}
                labelLocale={labelLocale}
                onSelectReturnObject={true}
                showError={(errors[field.client_id]?.touched || errors.isSubmitted)}/> 
            </tbody>
          </table>
        </div>
      </div>  
      <CueTooltip id={field.client_id} description={field.tooltip}/>
    </div> 
  )
}

const ColumnError = ({labelLocale, showError, column, modelIdx, rowData}) => {
  if(column.required && showError && rowData[modelIdx] == null){
    return(
      <div className="errormsg">
        <div>
          <span className="fas fa-exclamation-triangle m-r-5"/>
          {labelLocale(10)}
        </div>
      </div>
    )
  }
  return null;
}

const TableHeader = ({formField, columns=[], currentLocale}) => columns.map((h, k) => {
  return(  
    <th key={k} className={`tcenter bold-unset f14-xss ${h.required ? 'req-field':''}`}>
      {getLocaleLabel(h, currentLocale)}
    </th>   
  )
})

const TableRow = ({data, formData, showError, formFn, formField, screen, currentLocale, columns, rows=[], dataSourceObject, handleChange, labelLocale, onSelectReturnObject}) => rows.map((r, k) => {
  const rowConfig = formField.row_col_config?.['row_'+r.pk_id]
  /*if(!rowConfig){
    console.log(rowConfig)
  }
  */
  /*const result = FormHelper.checkFieldConditionality(rowConfig, formData)
  if(!result){
    if(data[r.pk_id])handleChange(null, r.pk_id)
    return null
  }*/

  //const rowData = data[r.pk_id] || {}

  return(  
    <tr key={k}>  
      <td className="font-15">
        {(formField.show_image && r.images) ? <ImageFlexList styleName="radio-img" list={r.images}/> : null}
        <OptionLabel option={r} currentLocale={currentLocale}/>
      </td>
      <TableColumnData columns={columns} rowConfig={rowConfig}
        row={r} dataSourceObject={dataSourceObject}
        handleChange={handleChange}
        onSelectReturnObject={onSelectReturnObject}
        currentLocale={currentLocale}
        labelLocale={labelLocale}
        showError={showError}
        screen={screen}
        formField={formField}
        formData={formData}
        rowData={data}
        formFn={formFn}
        idx={k}
      />
    </tr>
  )
})

const TableColumnData = ({showError, labelLocale, rowConfig, formData, formFn, rowData, formField, screen, currentLocale, idx, columns=[], row, dataSourceObject, handleChange, onSelectReturnObject}) => columns.map((c, k) => {
  const colConfig = rowConfig?.[c.index];

  if(!colConfig){
    return null;
  }
  
  const rowClientId = rowConfig.client_id;
  const modelIdx = rowClientId +'_'+ c.client_id;

  const dependentField = colConfig.visibility_logic?.dependent_field;
  const colDs = dataSourceObject[colConfig?.data_source_id];
  const result = FormHelper.checkFieldConditionality(colConfig, formData);
  let dependentFieldDs = null;
  try{
    if(result && dependentField){
      dependentFieldDs = dataSourceObject[dependentField?.data_source_id];
    }else if(!result && rowData[dependentField.client_id]){
      delete rowData[dependentField.client_id];
      delete rowData[dependentField.client_id+'_array'];
      handleChange(rowData, row.pk_id);
    }
  }catch(e){
    console.log(e.message)
  } 

  return(
    <td key={k}>
      <InputComponent rowClientId={rowClientId} rowData={rowData} 
        screen={screen} currentLocale={currentLocale} formFn={formFn}
        idx={idx} column={c} row={row} modelIdx={modelIdx}
        handleChange={handleChange} colDs={colDs}
        labelLocale={labelLocale}
        onSelectReturnObject={onSelectReturnObject}/>
      <ColumnError labelLocale={labelLocale} showError={showError} 
        column={c} modelIdx={modelIdx} rowData={rowData}/>
      {result && colConfig.visibility_logic?.dependent_field && 
        <>
          <div className="m-t-10 bold-400 text-center font-15">{dependentField.label}</div>
          <InputComponent rowData={rowData} screen={screen} modelIdx={modelIdx}
            currentLocale={currentLocale} idx={idx} rowClientId={rowClientId}
            column={colConfig.visibility_logic?.dependent_field} formFn={formFn} 
            row={row} handleChange={handleChange} colDs={dependentFieldDs}
            onSelectReturnObject={onSelectReturnObject}/>   
        </>
      }
    </td>
  )

})

const InputComponent = ({labelLocale, modelIdx, rowData, screen, currentLocale, colDs, idx, column, row, handleChange, formFn, onSelectReturnObject}) =>{
  if(column.input_type == 'text' || column.input_type == 'numeric'){
    return(  
      <TDTextInput model={rowData[modelIdx]} column={column} 
        label={getKeyByLocale(row, currentLocale)}
        clientId={modelIdx} handleChange={handleChange} 
        labelLocale={labelLocale}
        onSelectReturnObject={onSelectReturnObject}
        row={row}/>   
    )
  }else if(column.input_type == 'select' && colDs?.options){
    return(  
      <TDInputSelect model={rowData[modelIdx]} column={column} clientId={modelIdx} 
        handleChange={handleChange} isObject={true}
        currentLocale={currentLocale} screen={screen}
        dataTypes={colDs.data_type} options={colDs?.options}
        label={getKeyByLocale(row, currentLocale)} formFn={formFn} 
        inputAttributes={{'className':'dd-button'}}
        onSelectReturnObject={onSelectReturnObject}
        row={row}/>   
    )
    //, 'placeholder': labelLocale(5)
  }else if(column.input_type == 'multi_select' && colDs?.options){
    return(
      <TDInputMultiSelect rowData={rowData} column={column} clientId={modelIdx} 
        handleChange={handleChange} screen={screen} formFn={formFn}
        options={colDs?.options} label={getKeyByLocale(row, currentLocale)} 
        labelLocale={labelLocale}/>  
    ) 
  }else if(column.input_type == 'radio' && colDs?.options){
    return(
      <TDInputRadio model={rowData[modelIdx+'_id']} 
        column={column} clientId={modelIdx} 
        handleChange={handleChange} isObject={true} formFn={formFn}
        currentLocale={currentLocale} screen={screen}
        options={colDs?.options} 
        label={getKeyByLocale(row, currentLocale)}
        onSelectReturnObject={onSelectReturnObject}/>  
    ) 
  }else{
    return null;
  }
}

export {InputTableView, FieldGroupTableView};