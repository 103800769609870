import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { useHistory, useParams, Prompt } from 'react-router-dom';
import InputTextField from '../../components/FormBuilder/InputComponents/InputTextField';
import InputTextArea from '../../components/FormBuilder/InputComponents/InputTextArea';
import InputSelect from '../../components/FormBuilder/InputComponents/InputSelect';
import InputRadio from '../../components/FormBuilder/InputComponents/InputRadio';
import InputCheckbox from '../../components/FormBuilder/InputComponents/InputCheckbox';
import InputDataTimePicker from '../../components/FormBuilder/InputComponents/InputDataTimePicker';
import InputColorPicker from '../../components/FormBuilder/InputComponents/InputColorPicker';
import InputMultiSelect from '../../components/FormBuilder/InputComponents/InputMultiSelect';
import InputMultiSelectSearch from '../../components/FormBuilder/InputComponents/InputMultiSelectSearch';
import InputMultiFileUpload from '../../components/FormBuilder/InputComponents/InputMultiFileUpload';
import InputFileUpload from '../../components/FormBuilder/InputComponents/InputFileUpload';
import InputMultiRating from '../../components/FormBuilder/InputComponents/InputMultiRating';
import InputRating from '../../components/FormBuilder/InputComponents/InputRating';
import InputTags from '../../components/FormBuilder/InputComponents/InputTags';
import InputExplanatoryText from '../../components/FormBuilder/InputComponents/InputExplanatoryText';
//import InputMapLocation from '../../components/FormBuilder/InputComponents/InputMapLocation';
import InputSlider from '../../components/FormBuilder/InputComponents/InputSlider';
import InputAutoComplete from '../../components/FormBuilder/InputComponents/InputAutoComplete';
import InputSignaturePad from '../../components/FormBuilder/InputComponents/InputSignaturePad';
import InputRichText from '../../components/FormBuilder/InputComponents/InputRichText';
import InputRichImageMap from '../../components/FormBuilder/InputComponents/InputRichImageMap';
import InputLottieSvg from '../../components/FormBuilder/InputComponents/InputLottieSvg';
import FormHelper from '../../components/FormBuilder/FormHelpers';
import Cs from '../../services/CommonService';
import Tabs, { TabPane } from 'rc-tabs';
import FormSubmit from '../../components/FormBuilder/FormSubmit';
import ErrorsList from '../../components/FormBuilder/ErrorsList';
import DataSourceListModal from "../DataSources/DataSourceListModal";
import useModal from "../../hooks/useModal";
import { AddLocale } from "../../components/Locale/AddLocale";
import GenericModal from "../../components/Modals/GenericModal";
import { InputTableView, FieldGroupTableView } from './InputComponents/InputTableView';
import { tabMoreIcon } from "../Common/Button";
import { TabLabel } from './FieldLabel';
import { usePageLocale } from '../../hooks/useLocale';
import { LocalizeContext } from "../../contexts/Localize/LocalizeStateProvider";
import InputAudioRecording from "./InputComponents/InputAudioRecording.client";
import { useReviewHook } from '../Reviews/useReviewHook';
import AssessmentService from '../TrainingPrograms/Assessments/AssessmentService';
import { FormFooter } from "./FormFooter";
import { FormTestInputIcon } from "../FormTestInput/FomTestInputListPopup";
import ReviewFormInputPopup from "./ReviewFormInputPopup";
import FormTemplateService from '../../services/FormTemplateService';
import { useCurrentUserHook } from '../Authentication/useUserHook';
import { Spinner } from '../Common/Spinner';

//let template = {}
let readOnly = true;
let errors = {invalid:{}};
let noOfTabs = 0;
let template = {};
let formTabs = [];
let isFormSubmitted = false;
let selectedFormField = {};

const DynamicForm = (props) => {

  let params = useParams();
  const history = useHistory();
  let {current:scope} = useRef({});
  let {formId, formFn, form, data, dataList, onCreate, onUpdate, formMode, formFieldViewEle, showTakeScreenshotInfo=false} = props;

  const {currentUser, screen} = useCurrentUserHook();
  const {localeDispatch} = useContext(LocalizeContext);

  const [activeKey, setActiveKey] = useState('0');
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [langauge, setLangauge] = useState(formFn.locale || currentUser.current_locale);

  const {isOpen: isDataSourceModalOpen, toggleModal: toggleDataSourceModal} = useModal();
  const {isOpen: isFieldLabelOpen, toggleModal: toggleFieldLabelModal} = useModal();
  const {isOpen: isConfirmInputOpen, toggleModal: toggleConfirmInputPopup} = useModal();
  
  const {labelLocale} = usePageLocale(langauge, 'formLocale');
  const {labelLocale:formCancelAlertLocale} = usePageLocale(langauge, 'formCancelAlert');
  const {reviewByClientId} = useReviewHook({
    itemId:form?.id, 
    itemType:formFn.form_type, 
    isReviewEnabled:props.isReviewEnabled, 
    organizationId:currentUser.current_organization_id,
    statuses:['open', 'reopen']
  });

  const forceUpdate = () => {
    setCount(value => ++value);
  }

  formFn.refreshForm = () => forceUpdate();
  formFn.setLoading = (status) => setLoading(status);
  formFn.setLocale = (language) => {
    setLangauge(language);
  }
    
  const onSubmit = (draft) => {
    setLoading(true);
    isFormSubmitted = true;
    form.updated_by = currentUser.id;
    form.draft = draft ? true : false;
    if(dataList?.length > 0){
      //grouped field list
      form.is_bulk_insert = true;
      form.data_list = dataList;
      form.data = {};
    }else{
      form.data = data;
    }

    if(formMode === 'create-form-submissions'){
      form.data.ct_time_to_submit = Cs.geTimeDiff(
        Cs.getCurrentDateTime(), formFn.ct_start_time
      );
      form.created_by = currentUser.id;
      onCreate(form);
    }else{
      onUpdate(form);
    }
  }

  useEffect(() => {
    setLangauge(formFn.locale || currentUser.current_locale);
    getFormTemplate();
    formFn.ds = formFn.ds || {};
    return () => {
      template = {};
      formTabs = [];
      noOfTabs = 0;
      readOnly = true;
	    errors = {invalid:{}};
	    isFormSubmitted = false;
    }
  }, [formId, currentUser.current_locale])

  const getFormTemplate = () => {
    if(formId) {
      FormTemplateService.getFormTemplateById(
        formId, 
        {
          'locale':langauge, 
          'organization_id':formFn.organization_id,
          'is_public':formFn.is_public,
          'page_locale':{
            'data_source_ids':[3930, 3953],
            'locale':langauge,
          },
          'add_cache':formFn.add_cache
        }
      ).then((data) => {
        setForm(data.form_template)
        localeDispatch({
          type: "UPDATE",
          payload: {
            'formLocale':(data.page_locale?.text_labels_forms),
            'formCancelAlert':(data.page_locale?.cancellation)
          }
        })
      })
    }else if(props.user_form_id){
      Cs.getUserForm({ id: props.user_form_id }).then((tf) => {
        setForm(tf);
      })
    }else if(props.assessmentFormId){
      AssessmentService.getFormFieldsAssessment( 
        {
          'assessment_id':props.assessmentFormId,
          'locale':langauge, 
          'page_locale':{
            'data_source_ids':[3930, 3953],
            'locale':langauge,
          }
        }
      ).then(({status, data}) => {
        setForm(data.form_template)
        localeDispatch({
          type: "UPDATE",
          payload: {
            'formLocale':(data.page_locale?.text_labels_forms),
            'formCancelAlert':(data.page_locale?.cancellation)
          }
        })
      })
    }
    setLoading(false)
  }

  const setForm = (tf) =>{
    //template = tf;
    noOfTabs = tf.template_fields.length;
    tf.fill_info = tf.fill_info || 'Please fill out the following fields';
    if(formMode === 'create-form-submissions'){
      readOnly = false;
    }else if(formMode === 'edit-form-submissions'){
      readOnly = !(form.created_by === currentUser.id || formFn.editPermission);
    }else if(formMode === 'view-form-submissions'){
      tf.fill_info = 'View out the following fields';
      tf.post.details ='View Details';
      readOnly = true;
    }
    template = tf;
    props.setPost({...tf.post, 'help_id':tf.help_id});  
    formFn.ct_start_time = Cs.getCurrentDateTime();
  }

  let callback = function(key) {
    setActiveKey(key);
  }

  const nextTab = () =>{
    let i = (parseInt(activeKey) +1).toString();
    setActiveKey(i);
  }

  const previousTab = () =>{
    const i = (parseInt(activeKey)-1).toString();
    setActiveKey(i);
  }

  /*useEffect(()=>{
    try{
      setTimeout(function() {
        document.getElementById("form-container").scrollTop = 0;
      }, 1000);
    }catch(e){
      console.error("form auto scroll error")
    }
  }, [activeKey])*/

  const openDataSourceModal = (field) =>{
    selectedFormField = field;
    toggleDataSourceModal();
  }

  const openFieldLabelModal = (field) =>{
    selectedFormField = field;
    toggleFieldLabelModal();
  }

  const resetFieldError = (field) =>{
    FormHelper.resetFieldError(errors, field);
  }
  
  const getExitAlert = () =>{
    return `  ${formCancelAlertLocale(3)}\n\n  ${formCancelAlertLocale(4)}`;
  }
  
  const onTestInputDataSelect = (testInput, closePopup) =>{
    for(const k in testInput.data){
      data[k] = testInput.data[k];
    }
    form.test_input_id = testInput.id;
    form.is_test_data = true;
    forceUpdate();
    closePopup();
  }

  const reviewInputBeforeSubmit = (draft=false, event) =>{
    event.stopPropagation();
    scope.submitDraft = draft;
    const reviewFields = [];
    let i = template.template_fields.length;
    while(i--) {
      const tab = template.template_fields[i];
      let j = tab.child_template_fields.length;
      while(j--) {
        const field = tab.child_template_fields[j];
        if(field.reconfirm_value){
          reviewFields.unshift(field);
        }
      }
    }

    if(reviewFields.length > 0){
      scope.reviewFields = reviewFields;
      toggleConfirmInputPopup();
    }else{
      onSubmit(scope.submitDraft);
    }
  }

  const download = () =>{
    const csv_list = []
    let i = template.template_fields.length
    while(i--) {
      const tab = template.template_fields[i]
      
      let j = tab.child_template_fields.length
      while(j--) {
        const field = tab.child_template_fields[j]

        if(field.component === "table_view"){
          const keys = Object.keys(field.row_col_config || {})
          if(keys.length > 0){
            const firstRowObj = field.row_col_config[keys[0]]
            for (const c of field.columns) {
              const dsid = firstRowObj[c.index]?.data_source_id
              const options = field.data_sources[dsid]?.options || []
              for (const c of options) {
                csv_list.unshift({
                  'Tab':'',
                  'Field':'',
                  'Required':'',
                  'Options':c.label
                }) 
              }

              csv_list.unshift({
                'Tab':'',
                'Field':`  ${c.label}`,
                'Required':c.required?'Yes':'No',
                'Options':''
              })
            }
          }
        }

        let k = field.options_key_value?.options?.length || 0
        while(k--) {
          const option = field.options_key_value?.options[k]
          csv_list.unshift({
            'Tab':'',
            'Field':'',
            'Required':'',
            'Options':option?.label
          })
        }

        csv_list.unshift({
          'Tab':'',
          'Field':field.label,
          'Required':field.required?'Yes':'No',
          'Options':''
        })

      } 

      csv_list.unshift({
        'Tab':tab.label,
        'Field':'',
        'Required':'',
        'Options':''
      })
    } 
    
    let dlnk = document.getElementById('dwnldLnk');
    const csv = "data:text/csv;charset=utf-8,%EF%BB%BF"+encodeURI(Cs.jsonToCsv(csv_list));
    dlnk.download =  'Form_'+ template.id +'.csv';
    dlnk.href = csv;
    dlnk.click();
  }

  if(loading)
    return <Spinner/>;

  formTabs = template.template_fields?.filter((parentField) =>{
    return FormHelper.checkFieldConditionality(parentField, data, true);
  }) 
  noOfTabs = formTabs?.length;

  return (
    <>
      <div className="bg-white p20 p5-xss form-border">
        <FormTestInputIcon 
          className="pos-abs r-55 t-n21 f18"
          currentUser={currentUser} 
          formId={formId} 
          onTestInputDataSelect={onTestInputDataSelect}/>
        <form className="row">
          <ErrorsList errors={errors} formFn={formFn} setActiveKey={setActiveKey}/>
          {!readOnly && !isFormSubmitted && <Prompt message={getExitAlert()}/>}
          <Tabs activeKey={activeKey} onChange={callback} moreIcon={tabMoreIcon}>
            {template.id && formTabs.map((parentField, key) => 
              <TabPane tab={<TabLabel tab={parentField} currentLocale={langauge}/>} key={key} forceRender="true">
                <div id="form-container" className={screen.xs?'form-ht':''}>
                  <div className="row">
                    <ChildElementList pkey={key} childField={parentField.child_template_fields}
                      formId={formId} activeKey={activeKey} data={data} dataList={dataList}
                      formFn={formFn} resetFieldError={resetFieldError}
                      fieldShowHideFn={props.fieldShowHideFn} currentUser={currentUser}
                      forceUpdate={forceUpdate} form={form} 
                      screen={screen} langauge={langauge}
                      openFieldLabelModal={openFieldLabelModal} 
                      openDataSourceModal={openDataSourceModal}
                      labelLocale={labelLocale} 
                      formMode={formMode}
                      reviewByClientId={reviewByClientId}
                      formCancelAlertLocale={formCancelAlertLocale}
                      permission={props.permission}/>
                    </div>
                  </div>
                </TabPane>
              )
            }
          </Tabs>
          <div className="clerfix"/>
          <FormSubmit labelLocale={labelLocale} errors={errors} form={form} 
            readOnly={readOnly} onCancel={props.onCancel} onSubmit={reviewInputBeforeSubmit} 
            activeKey={activeKey} previousTab={previousTab} nextTab={nextTab} 
            noOfTabs={noOfTabs} formFn={formFn} forceUpdate={forceUpdate}
            formCancelAlertLocale={formCancelAlertLocale} isPopupMode={props.isPopupMode}
            showDraftBtn={template.show_draft_btn} getExitAlert={getExitAlert} loading={loading}>
          </FormSubmit>
          <FormFooter showFeedbackLink={currentUser?.id!=null} {...{labelLocale, template, currentUser, download}}/>
        </form>
      </div>

      {isDataSourceModalOpen && 
        <DataSourceListModal form_template_id={template.id} 
          data_source_id={selectedFormField.data_source_id} 
          journey_profile_id={params.journey_profile_id}
          toggleModal={toggleDataSourceModal}/>
      }

      {isFieldLabelOpen && 
        <GenericModal component={AddLocale} 
          defaultLabel={selectedFormField.label} 
          localeType="form_field" 
          translateFieldKey="label"
          title={selectedFormField.label} 
          toggleModal={toggleFieldLabelModal} 
          onCancel={toggleFieldLabelModal} 
          labelObj={selectedFormField}/>
      }

      {isConfirmInputOpen &&
        <GenericModal title={labelLocale(16)}
          component={ReviewFormInputPopup} 
          reviewFields={scope.reviewFields}
          inputData={data} inputDataSource={formFn.ds}
          isOpen={isConfirmInputOpen}
          toggleModal={toggleConfirmInputPopup}
          okLabel={labelLocale(7)}
          cancelLabel={labelLocale(4)}
          footerInfo={showTakeScreenshotInfo ? labelLocale(19) : null}
          formFieldViewEle={formFieldViewEle}
          onSuccess={()=> {toggleConfirmInputPopup();onSubmit(scope.submitDraft)}}/>
      }
    </>
  )
    
}

const ChildElementList = ({forceUpdate, labelLocale, screen, form, currentUser, langauge, formId, activeKey, data, dataList, formFn, childField, pkey, fieldShowHideFn, resetFieldError, openDataSourceModal, openFieldLabelModal, formMode, reviewByClientId, formCancelAlertLocale, permission}) => childField.map((field, key) => {

    FormHelper.initializeValue(field, data, formMode);
    FormHelper.checkFieldValidity(field, data, errors, pkey);

    if(fieldShowHideFn && fieldShowHideFn(field)){
      resetFieldError(field);
      return;
    }

    const renderField = FormHelper.checkFieldConditionality(field, data);
    if(!renderField){
      resetFieldError(field);
    }

    const hasAccess = FormHelper.checkAccessControl(field, currentUser);
    if(!hasAccess) return false;

    if(activeKey != pkey) return; 
    
    field.formTemplateId = formId;

    let fieldProps = {
      'labelLocale':labelLocale,
      'formCancelAlertLocale':formCancelAlertLocale,
      'field':field, 
      'formData':data, 
      'formFn':formFn,
      'position': key,
      'openDataSourceModal':openDataSourceModal,
      'openFieldLabelModal':openFieldLabelModal,
      'currentLocale':langauge,
      'formId': formId,
      'renderField':renderField,
      'forceUpdate':forceUpdate,
      'fieldReview':reviewByClientId[field.client_id],
      'readOnly':field.disabled ? true : (formMode === 'create-form-submissions' && field.read_only) ? false:(field.read_only ? (permission?.read_only_editable ? false:true):false),
      'errors':errors
    }

    switch (field.component) {
      case 'textInput':
        FormHelper.onChangeExp(field, data, formFn.ds)
        return <InputTextField key={key} {...fieldProps} />
        break;
      case 'textArea':
        return <InputTextArea key={key} {...fieldProps} />
        break;
      case 'timepicker':
        return <InputDataTimePicker key={key} {...fieldProps} />
        break;
      case 'datepicker':
        return <InputDataTimePicker key={key} {...fieldProps} />
        break;
      case 'checkbox':
        return <InputCheckbox key={key} {...fieldProps} />
        break;
      case 'radio':
        return  <InputRadio key={key} {...fieldProps} />
        break;
      case 'select':
        return <InputSelect key={key} screen={screen} {...fieldProps}/>
        break;
      case 'multi_select':
        if(field.is_dynamic_data){
          return <InputMultiSelectSearch key={key} {...fieldProps} />  
        }else{
          return <InputMultiSelect screen={screen} key={key} {...fieldProps}/>
        }
        break;
      case 'slider':
        return <InputSlider key={key} {...fieldProps} />
        break;
      case 'counter':
        fieldProps.field.field_validation_options = {validation_type:'counter'}
        return <InputTextField key={key} {...fieldProps} />
        break;
      case 'autocomplete':
        return <InputAutoComplete key={key} {...fieldProps} />
        break;
      case 'imageupload':
        return <InputFileUpload key={key} formId={form.id} {...fieldProps} />
        break;
      case 'multi_file_upload':
        return <InputMultiFileUpload key={key} formId={form.id} {...fieldProps} />
        break;
      case 'richtext':
        return <InputRichText key={key} formId={form.id} {...fieldProps} user={currentUser}/>
        break;
      case 'explanatory_text':
        return <InputExplanatoryText key={key} {...fieldProps} />
        break;
      case 'color_picker':
        return <InputColorPicker key={key} {...fieldProps} />
        break;
      case 'rating':
        if(field.data_source_id){
          return <InputMultiRating key={key} {...fieldProps} />
        }else{
          return <InputRating key={key} {...fieldProps} />
        }
        break;
      case 'input_tags':
        return <InputTags key={key} {...fieldProps} />
        break;
      case 'signature':
        return <InputSignaturePad key={key} {...fieldProps} />
        break;
      case 'rich_image_map':
        return <InputRichImageMap key={key} user={currentUser} {...fieldProps} />
        break;
      case 'lottie_svg':
        return <InputLottieSvg key={key} user={currentUser} {...fieldProps} />
        break;
      case 'table_view':
        if(field.is_field_group){
          return <FieldGroupTableView key={key} screen={screen} user={currentUser} formDataList={dataList} {...fieldProps} />
        }
        return <InputTableView key={key} screen={screen} user={currentUser} {...fieldProps} />
        break;
      case 'record_audio':
        return <InputAudioRecording key={key} {...fieldProps} />
        break;
      default:
        console.log(field.component)
        return <Fragment key={key}>Not Implemented</Fragment>
      }
    }
  )

export default DynamicForm;