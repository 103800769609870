import React, { Suspense, lazy } from 'react'
import PrivateRoute from "../../routes/PrivateRoute"
import { Spinner } from '../Common/Spinner'
const CountryList = lazy(() => import('./Countries/CountryList'))
const StateList = lazy(() => import('./States/StateList'))
const CityList = lazy(() => import('./Cities/CityList'))
const SubDistrictList = lazy(() => import('./SubDistricts/SubDistrictList'))
const VillageList = lazy(() => import('./Villages/VillageList'))

const GeographyRoute = (props) => (
  <Suspense fallback={<Spinner/>}>
    <PrivateRoute path="/list/country" searchString={props.searchString} component={CountryList} exact />
    <PrivateRoute path="/list/state/:country_iso" searchString={props.searchString} component={StateList} exact />
    <PrivateRoute path="/list/district/:country_iso/:state_code" searchString={props.searchString} component={CityList} exact />
    <PrivateRoute path="/list/sub_district/:country_iso/:state_code/:district_code/:district_name" searchString={props.searchString} component={SubDistrictList} exact />
    <PrivateRoute path="/list/village/:country_iso/:state_code/:district_name" searchString={props.searchString} component={VillageList} exact />
    <PrivateRoute path="/list/village/:country_iso/:state_code/:district_name/:sub_district_name" searchString={props.searchString} component={VillageList} exact />
  </Suspense>
)

export default GeographyRoute;