import React from "react";
import { LinkFontAwesomeIcon } from "../Common/ImageIcons";

const ReviewScore = ({review={}, onSelect}) => {
	let eleProps = {};
	if(onSelect)eleProps.onSelect = () => onSelect(review);

	return(
		<>
			{review?.score === 1 &&
				<LinkFontAwesomeIcon  
					parentCss="bg-lgreen table-icon-sm"
					iconCss="far fa-thumbs-up white"
					{...eleProps}/>
			}
			{review?.score === -1 &&
		       	<LinkFontAwesomeIcon 
			        parentCss="bg-lred table-icon-sm"
					iconCss="far fa-thumbs-down white"
					{...eleProps}/>
			}
		</>
	)
}

const MultiReviewScore = ({reviews=[], onSelect}) => reviews?.length > 0 ? reviews.map((r, k) =>
	<ReviewScore key={k} review={r} onSelect={onSelect}/>
) : null;

export {ReviewScore, MultiReviewScore};