import React, { useState, useEffect, useRef } from 'react';
import UserRequestService from "../UserRequests/UserRequestService";
import Cs from "../../services/CommonService";
import DynamicTable from '../Common/DynamicTable';
import PaginationSM from '../Common/PaginationSM';
import { NavIcon } from '../Common/MenuItem';
import FormTemplateService from '../../services/FormTemplateService';
import { useCurrentUserHook } from "../Authentication/useUserHook";
import { Spinner } from '../Common/Spinner';

const UserSurveyTable = (props) => {
  const {searchString} = props;
  let params = props.match.params;
  let {current:scope} = useRef({"requestParams":{}});
  // const {state: { user: currentUser }} = useContext(AuthContext);
  const {currentUser} = useCurrentUserHook()
  const surveyFormId = 1191;
  const [loading, setLoading] = useState(true);	
  const [formFields, setFormFields] = useState([]);
  const [userSurveyList, setUserSurveyList] = useState([]);
  const [pagemeta, setPagemeta] = useState({});

  useEffect(() => {
    setParams(1);
    getFormFields();
    getSurvey();
  }, [searchString]);

  const getSurvey = async () => {
    setLoading(true);
    const { status, data } = await UserRequestService.getAllSurvey(scope.requestParams);
    if (status === 200) {
      setUserSurveyList(data.surveys);
      setPagemeta(data.meta);
    } else {
      console.error('Error fetching surveys:', status, data)
    }
    setLoading(false);
  };

  const setParams = (page) =>{
    const requestParams = {
      "page": page,
      "per_page": 15,
      "organization_id": currentUser.current_organization_id,
      'sort_column': scope.requestParams.sort_column || 'created_at',
      'sort_direction': scope.requestParams.sort_direction || 'desc',
      'search': searchString,
      "survey_form_id": surveyFormId,
    }
    scope.requestParams = requestParams;
  }

  const onPageChage = (page) =>{
    setParams(page);
    getSurvey();
  }
  
  const getFormFields = async () => { 
    const { status, data } = await FormTemplateService.getFormFields({ 'ids': [surveyFormId] });
    if (status === 200) {
      setFormFields(data.form_fields[surveyFormId]);
    }
    setLoading(false);
  };

  const downloadSurvey = async () => {
    setLoading(true);
    const { status, data } = await UserRequestService.downloadSurvey({
      "organization_id": currentUser.current_organization_id,
      "survey_form_id": surveyFormId
    })
    if (status === 200) {
      let dlnk = document.getElementById('dwnldLnk');
      const csv = "data:text/csv;charset=utf-8,%EF%BB%BF"+encodeURI(data.surveys);
      dlnk.download =  currentUser?.current_organization?.data?.name+'_Field_survey_'+ (Cs.getCurrentDateTime('MMM-DD-YYYY')) +'.csv';
      dlnk.href = csv;
      dlnk.click();
    }
    setLoading(false);
  };

  const specialFields = [
    {
      label: "ID", 
      client_id: "record_id",
      sort_column: "user_requests.record_id", 
      ele: userRegRecordId
    },
    {
      label: "Name",
      client_id: "name",
      sort_column:'user_requests.data.name', 
      ele: userRegName
    },
    {
      label: "Role",
      client_id: "role",
      sort_column:'user_requests.data.role', 
      ele: role
    }
  ]

  if (loading) return <Spinner/>;

  return (
    <div id="ct">
      <div className="app-content">
        <div className="app-header flex justspacebetween coldir-xs m-t-15">
          <div>
            <div className="app-title m-r-20">
            User Survey Response - <span className="f18">{currentUser?.current_organization?.data?.name}</span>
            </div>
            <div className="flex justspacebetween aligncenter m-t-5">
              <span className="font-14">{pagemeta?.total} -  Items</span>
            </div>        
          </div>
          <div className="flex aligncenter justifyflexend m-t-10-xs">
              <NavIcon 
                id="csv" 
                dataTip="CSV Download" 
                onSelect={() => downloadSurvey()} 
                className="bg-highlight round-btn float-right m-l-15" 
                icon="fas fa-download" 
              />
          </div>
        </div>

        <div className="app-content-section m-t-15 table-responsive">
          <DynamicTable 
            requestParams={scope.requestParams}
            specialFields={specialFields}
            formFields={formFields} 
            formDataList={userSurveyList} 
            tClass="table shadow-small" 
            isSortable={true}
            onSort={getSurvey} 
            onRowClick={() => {}}
            sortColPrefix="user_surveys"/>
        </div>

        <PaginationSM
          className="pagination-bar m-t-10"
          currentPage={parseInt(pagemeta.current_page)}
          totalItems={pagemeta.total || 0}
          itemsPerPage={pagemeta.per_page || 100}
          onPageChange={onPageChage}
          siblingCount={2} />
      </div>
    </div>
  );
};

const userRegRecordId = (clientId, data, row, rowIdx, onClick) => {
  if(!row.user_reg) return null;

  return(
    <div className="fw500 flex coldir aligncenter">
      {row.user_reg.record_id}
    </div>
  )
}

const userRegName = (clientId, data, row, rowIdx) => {
  if (!row.user_reg) return null;

  return (
    <div className="fw500 is-bw-80">
      {row.user_reg.name}
    </div>
  )
}

const role = (clientId, data, row, rowIdx) => {
  if (!row.user_reg) return null;

  return (
    <div className="fw500 is-bw-80">
      {row.user_reg.role}
    </div>
  )
}

export default UserSurveyTable;