import React, { Suspense, lazy } from 'react';
import PrivateRoute from '../../routes/PrivateRoute';
import { Spinner } from '../Common/Spinner';
/*import SubscriptionsTable from './SubscriptionsTable'
import SubscriptionForm from './SubscriptionForm'*/
const SubscriptionsTable = lazy(() => import('./SubscriptionsTable'));
const SubscriptionForm = lazy(() => import('./SubscriptionForm'));

const Router = (props) => (
  <Suspense fallback={<Spinner/>}>
    <PrivateRoute path="/list/subscriptions" component={SubscriptionsTable} searchString={props.searchString} exact />
    <PrivateRoute path="/subscription/create/:id?" component={SubscriptionForm} exact />
  </Suspense>
)

export default Router;