import React, { useState, useEffect } from "react";
import OrganizationMemberService from "./OrganizationMemberService";
import { LeafletMap } from '../../Common/Leaflet';
import GenericModal from '../../Modals/GenericModal';
import { LinkFontAwesomeIcon } from "../../Common/ImageIcons";
import useModal from "../../../hooks/useModal";
import FH from "../../FormBuilder/FormHelpers";
import { useCurrentUserHook } from "../../Authentication/useUserHook";
import { Spinner } from "../../Common/Spinner";
import FilterSlider from "../../Common/FilterSlider";
import {CheckBox} from "../../Common/FormInput";
import { NavIcon } from "../../Common/MenuItem";

const MemberGeoDataModal = ({filter:filterProps={}, organizationId, medicalFacilityId, memberUserId, isFilterOpen, toggleFilter}) => {

  const {currentUser} = useCurrentUserHook();
  const [geoData, setGeoData] = useState({});
  const [loading, setLoading] = useState(true);
  //const [dataType, setActiveType] = useState({'journey':true, 'measure_growth':true});
  let [group, setGroup] = useState();
  let [map, setMap] = useState();
  const [filters, setFilters] = useState({
    'protein_intake':'normal',
    'weight_zscore':"SUW",
    'is_low_birth_weight':true
  });
  /*let [journeyLabelColors, setJourneyLabelColors] = useState(['#00A2FF', 'green', 'red']);
  let [growthLabelColors, setGrowthLabelColors] = useState(['rgb(245, 77, 89)', '#DECE06', '#00a86b', '#00A2FF']);*/
  const [position, setPosition] = useState({'latitude':19.997454, 'longitude':73.789803});

  useEffect(() => {
    if(filterProps.report_type === "geo_location"){
      getGoeData();
    }
  }, [filters])

  /*useEffect(() => {
    if(map)toggleGeoData();
  }, [journeyLabelColors, growthLabelColors])*/

  const getGoeData = () => {
    const req = {
      organization_id:organizationId,
      user_id:memberUserId,
      filters:filters,
      ...filterProps,
    }
    OrganizationMemberService.getGeoData(req).then(({status, data})=>{
      if(status === 200){
        const orgData = currentUser.current_organization.data;
        if(orgData?.latitude && orgData?.longitude){
          setPosition(orgData);
        }
        setGeoData(data);
        if(group){
          group.clearLayers();
          addCustomMarker(map, data);
        }
        setLoading(false);
      }
    })
  }

  const marketStyle = {
    'protein_intake':{'className':'fas fa-square pos-rel f12'}, 
    'measure_growth':{'className':'fas fa-circle pos-rel f12'},
    'birth_weight':{'className':'fas fa-baby pos-rel f16'}
  }

  const getMarkerHtmlStyles = (color, key, toString=true) =>{
    if(toString)
      return `color:${color}`;
    else
      return {'color': color};
  }

  const getmarkerIcon = (color, key) =>{
    const markerHtmlStyles = getMarkerHtmlStyles(color, key);
    return window.L.divIcon({
      className:"custom-div-icon",
      iconAnchor: [-15,10],
      labelAnchor: [0, 0], 
      popupAnchor: [0, -36], 
      html: `<span class="${marketStyle[key].className} pos-rel" style="${markerHtmlStyles}" />`
    })
  }

  const addCustomMarker = (map, geoList=geoData) =>{
    setMap(map);
    const markerArray = [];
    let i = geoList?.length || 0;
      
    while(i--) {
      const g = geoList[i];
      if(g.case_latitude && g.case_longitude){
        if(g.protein_color && filters.protein_intake !== null){
          g.protein_color = g.protein_color || '#00A2FF';
          const custIcon = getmarkerIcon(g.protein_color, 'protein_intake');
          markerArray.push(
            window.L.marker([g.case_latitude, g.case_longitude], {'icon': custIcon})
            .bindPopup(`${g.case_name} - ${g.case_id}`)
          );
        }else if(g.birth_color && filters.is_low_birth_weight !== null){
          g.birth_color = g.birth_color || '#00A2FF';
          const custIcon2 = getmarkerIcon(g.birth_color, 'birth_weight');
          markerArray.push(
            window.L.marker([g.case_latitude, g.case_longitude], {'icon': custIcon2})
            .bindPopup(`${g.case_name} - ${g.case_id}`)
          );
        }
      }

      if(filters.weight_zscore !== null && g.measurement_latitude && g.measurement_longitude){
        g.weight_zscore_color = g.weight_zscore_color || '#00A2FF';
        const custIcon = getmarkerIcon(g.weight_zscore_color, 'measure_growth');
        markerArray.push(
          window.L.marker([g.measurement_latitude, g.measurement_longitude], {'icon': custIcon})
          .bindPopup(`${g.case_name} - ${g.case_id}`)
        );
      }
    }  
    
    let group = window.L.featureGroup(markerArray).addTo(map);
    setGroup(group);
    //map.fitBounds(group.getBounds()); //set view boundry to view all marker
  }

  /*const toggleData = (type) =>{
    if(dataType[type]){
      delete dataType[type];
    }else{
      dataType[type] = true;
    }
    toggleGeoData();
  }

  const toggleGeoData = () =>{
    group.clearLayers();  
    let filteredData = {'journey':[], 'measure_growth':[]};
    let activeLabels = [];

    for(const t in dataType){
      for(const d of geoData[t]){
        if(t === 'journey' && journeyLabelColors.indexOf(d.color) > -1){
          filteredData[t].push(d);
        }else if(t === 'measure_growth' && growthLabelColors.indexOf(d.color) > -1){
          filteredData[t].push(d);
        }
      } 
    }
    setActiveType({...dataType});
    addCustomMarker(map, filteredData);
  }

  const toggleJourneyLabel = (color) =>FH.toggleListString(color, journeyLabelColors, setJourneyLabelColors);
  const existJourneyLabel = (color) => FH.existsListString(color, journeyLabelColors);

  const toggleGrowthLabel = (color) =>FH.toggleListString(color, growthLabelColors, setGrowthLabelColors);
  const existGrowthLabel = (color) => FH.existsListString(color, growthLabelColors);*/

  if(loading) return <Spinner/>;

  return(
    <>
      <div className="col-xs-12 flex justspacebetween coldir-xs">
        <div className="p-10">
          <div className="flex aligncenter m-r-10">
            <span className="fas fa-square m-l-10 m-r-10" style={getMarkerHtmlStyles('#00A2FF', 'protein_intake', false)}/>
            No protein intake done
          </div>
          <div className="flex aligncenter m-r-10">
            <span className="fas fa-square m-l-10 m-r-10" style={getMarkerHtmlStyles('green', 'protein_intake', false)}/>
            Protein intake greater then 80 grams
          </div>
          <div className="flex aligncenter m-r-10">
            <span className="fas fa-square m-l-10 m-r-10" style={getMarkerHtmlStyles('red', 'protein_intake', false)}/>
            Protein intake less then 80 grams
          </div>
        </div>
        <div className="p-10">
          <div className="flex aligncenter m-r-10">
            <span className="fas fa-baby m-l-10 m-r-10" style={getMarkerHtmlStyles('green', 'birth_weight', false)}/>
            Normal Birth Weight
          </div>
          <div className="flex aligncenter m-r-10">
            <span className="fas fa-baby m-l-10 m-r-10" style={getMarkerHtmlStyles('red', 'birth_weight', false)}/>
            Low Birth Weight
          </div>
        </div>
        <div className="p-10">
          <div className="flex aligncenter m-r-10">
            <span className="fas fa-circle m-l-10 m-r-10" style={getMarkerHtmlStyles('rgb(245, 77, 89)', 'measure_growth', false)}/>
            SUW
          </div>
          <div className="flex aligncenter m-r-10">
            <span className="fas fa-circle m-l-10 m-r-10" style={getMarkerHtmlStyles('#DECE06', 'measure_growth', false)}/>
            MUW
          </div>
          <div className="flex aligncenter m-r-10">
            <span className="fas fa-circle m-l-10 m-r-10" style={getMarkerHtmlStyles('#00a86b', 'measure_growth', false)}/>
            Mild
          </div>
        </div>
        <div className="p-10">
          <NavIcon dataTip="Filter" positionCss="left"
            className="bg-highlight round-btn m-r-15" 
            icon="fas fa-sliders-h"
            onSelect={()=>toggleFilter(!isFilterOpen)}>
          </NavIcon>
        </div>
      </div>
      <LeafletMap 
        lat={position.latitude} long={position.longitude}
        addCustomMarker={addCustomMarker}
        zoomOffset={-1}
        title="Map"/>
      {isFilterOpen && 
        <FilterSlider component1={Filters} 
          openSideNav={isFilterOpen}
          setOpenSideNav={()=>toggleFilter()}
          filters={filters} 
          setFilters={setFilters} 
        />
      }
    </>
  )
}

const GeoDataIcon = ({filter, organizationId, medicalFacilityId, memberUserId, children}) =>{
  const { isOpen:isMapOpen, toggleModal:toggleMapModal } = useModal();

  return(
    <>
      {children != null ? 
        <u onClick={()=>toggleMapModal()}>{children}</u> 
        :
        <LinkFontAwesomeIcon onSelect={()=>toggleMapModal()} iconCss="fas fa-location-dot white"/>
      }

      {isMapOpen &&
        <GenericModal 
          component={MemberGeoDataModal} 
          isOpen={isMapOpen}
          toggleModal={toggleMapModal}
          organizationId={organizationId}
          medicalFacilityId={medicalFacilityId}
          memberUserId={memberUserId}
          filter={filter}/>
      }
    </>
  )
}

const Filters = ({filters, setFilters}) =>{

  const Filter = {
    proteinIntake:[
      {'label': 'No submissions for cases', 'sid': 'no_data'},
      {'label': 'Protein intake is above threshold', 'sid': 'normal'},
      {'label': 'Protein intake is below threshold', 'sid': 'low'}
    ],
    weightZscore:[
      {'label': 'No submissions for cases', 'sid': 'no_data'},
      {'label': 'Z Score W is Normal', 'sid': 'Normal'},
      {'label': 'Z Score W is MUW', 'sid': 'MUW'},
      {'label': 'Z Score W is SUW', 'sid': 'SUW'}
    ],
    birthWeight:[
      {'label': 'LBW (Low Birth Weight)', 'sid': true},
      {'label': 'Not (Low Birth Weight) ', 'sid': false}
    ]
  }

  const toggleFilter = (value, name) => {
    if(value!=null && value != filters[name]){
      setFilters({...filters, [name]:value});
    }else{
      delete filters[name];
      setFilters({...filters});
    }
  }

  const filterExists = (value, name) => filters[name] == value;
  
  return (
    <div className="filter-box-scroll p-5">
      <div className="bg-white">
        <h5 className="font-16 text-center sub-title">
          Mother's Protein Intake
        </h5>

        <CheckBox list={Filter.proteinIntake} 
          labelKey="label" valueKey="sid" 
          toggle={toggleFilter} exists={filterExists} 
          name="protein_intake"/> 
      </div>

      <div className="bg-white">
        <h5 className="font-16 text-center sub-title">
          Check Growth
        </h5>

        <CheckBox list={Filter.weightZscore} 
          labelKey="label" valueKey="sid" 
          toggle={toggleFilter} exists={filterExists} 
          name="weight_zscore"/> 
      </div>

      <div className="bg-white">
        <h5 className="font-16 text-center sub-title">
          Weight analysis
        </h5>

        <CheckBox list={Filter.birthWeight} 
          labelKey="label" valueKey="sid" 
          toggle={toggleFilter} exists={filterExists} 
          name="is_low_birth_weight"/> 
      </div>
    </div>
  )
}

export {MemberGeoDataModal, GeoDataIcon};