import React, { Fragment, useEffect, useRef, useState } from "react";
import HealthCategoryService from "../../HealthCategoryService";
import Cs from "../../../../services/CommonService";
import ReactTooltip from "react-tooltip";
import { useCurrentUserHook } from "../../../Authentication/useUserHook";
import { Spinner } from "../../../Common/Spinner";

let portalPath = null;

const PortalSideMenu = (props) => {
  const {currentUser} = useCurrentUserHook()
  const sideNavEl = useRef(null);
  const sideNavMenuEl = useRef(null);

  const [project, setProject] = useState();
  const [child, setChild] = useState({});
  const permission = currentUser.permission?.permissions?.infant_journey ?? {}

  useEffect(() => { 
    let req = {
      'data_source_params':{
        "data_source_id":[3421]
      },
    }

    HealthCategoryService.getById(props.journeyProfileId, req).then((res)=>{
      if(res.status == 200){
        res = res.data;
        portalPath = res.data_source_list.health_journey_tabs.options
        portalPath.push({
          sid:'portal',
          label:'Journey portal page',
          icon:'fa-cog',
          icon_color: 'rgb(28, 146, 232)'
        })
        setProject(res.journey_profile)
        setChild(res.child || {})
      }
    })

  }, [])

  useEffect(() => {
    ReactTooltip.rebuild()
    document.addEventListener("keydown", handleEscape);
    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("click", handleClick, true);
    }
  }, []);

  const handleEscape = (e) => {
    if (e.keyCode === 27) props.setOpenSideNav(false);
  }

  const handleClick = (e) => {
    if (sideNavEl.current && !(sideNavEl.current.contains(e.target) || sideNavMenuEl.current.contains(e.target))) {
      props.setOpenSideNav(false);
    }
  }

  /*const permission = {
    guides: () => {
      return env.admin_emails.includes(currentUser.email) ? true:false
    }
  }*/

  const Icon = ({icon, icon_color, path}) =>{
    if(permission[path.sid] || true){
      return(
        <Fragment>
          <a onClick={e=>routePath(e, path)}>
            <i className={'fa '+icon} style={{color:icon_color}}/>
            <span data-tip={path.hover_text}>{path.label}</span>
            <i className="fa fa-angle-right" />
          </a>
        </Fragment>
      )  
    }else{
      return null
    }
  }

  const Icons = ({icons}) => icons && icons.map((p, i) => (
    <Icon icon={p.icon} icon_color={p.icon_color} path={p}  key={i}/>
  ))

  const routePath = (e, path) => {
    e.stopPropagation();
    Cs.routeCancerPath(path, project, props.history)
  }

  return (
    <div id="menu-main" ref={sideNavEl}
      className={`menu pos-fix r-0 menu-box-path menu-box-detached round-medium ${props.openSideNav ? "menu-active" : ""} `}
      style={{ marginTop: '40px', width: 260, opacity: 1, display: "block", 'lineHeight': '48px', 'minHeight': '500px' }}>
        
        <div className="menu-icons">
          <p className="under-heading center-text font-800 font-16">
            Journey Menu

            <a href="#" className="close-menu"
              onClick={(e) => {e.preventDefault();props.setOpenSideNav(false)}}
              style={{'marginBottom':'unset', float: 'right'}}>
                <i className="fa fa-times color-red2-dark" />
            </a>
          </p>
          
          <div className="clear" />
        </div>

        <div className="content m-t-20 m-b-10">
          <div className="link-list link-list-1" ref={sideNavMenuEl}>
            {project?<Icons icons={portalPath}/>:<Spinner/>}
          </div>
        </div>

    </div>
  );
};

export default PortalSideMenu;