import React, {useEffect, useContext} from "react";
import importScript from "../../hooks/importScript";
import {AuthContext} from "../../contexts/Authentication/AuthStateProvider";

const ECharts = (props) => {
  const { state:{screen} } = useContext(AuthContext)
  const {chartId, title, formatter, labelFormatter, data, yAxis, xAxis, chartType, dataset, dimensions, legend, label, onBeforeRender, onClickEvent, barWidth, colors, dataZoom, series} = props

  useEffect(()=>{
    if(data.length>0){
      //scripts/apache-echarts-5.4.2/echarts.min.js
      importScript('https://fastly.jsdelivr.net/npm/echarts@5.4.2/dist/echarts.min.js', setChart, window.echarts);
    }
  }, [data])

  useEffect(()=>{
    if(window.echarts)setChart();
  }, [legend])

  let numOr0 = n => isNaN(n) ? 0 : parseInt(n)

  //https://looka.com/blog/color-combinations/
  /*const getColorArray = () => 
    ['#C7395F', '#DED4E8', '#E8BA40', '#EDCBD2', '#80C4B7', '#E3856B',  '#3B5BA5', '#E87A5D', '#F3B941', '#678CEC', '#D49BAE', '#BBCB50', '#4AAFD5', '#91B187', '#E7A339', '#F9EC7E', '#E3CCB2', '#E26274', '#B2456E', '#FBEAE7', '#552619', '#EDF4F2', '#7C8363', '#31473A', '#CADCFC', '#8AB6F9', '#00246B']*/
  

  const setCommonOption = (option) =>{
    try{
      option.toolbox = {
        'right':30,
        'feature': {
          //restore: {},
          'saveAsImage': {}
        }
      }

      if(chartType === 'line' || dataZoom){
        option.toolbox.feature.dataZoom = {
          'yAxisIndex': 'none'
        } 
      }

      option.title = [];
      let titleObj = title || {};
      titleObj.left = titleObj.left || 'left';
      titleObj.textStyle = title.textStyle || {fontSize: '16px'};
      if(title?.sub_text){
        const d = option.dataset?.source || data;
        let count = null;
        if(title?.show_count){
          count = d?.reduce((t, n) => numOr0(t) + numOr0(n.y || n.value), 0);
        }
        titleObj.subtext = (count!=null?count:'') + ' ' + (title?.sub_text || '');
      }

      option.title.push(titleObj);

      /*if(title.description){
        option.title.push({
          'text': title.description,
          'top': '92%',
          'left': 'left',
          'textStyle': {'width':500, 'height':100, 'overflow':'break', 'fontSize': '12px', 'color':'#b5adad', 'fontWeight':'normal'}
        })
      }*/

      option.title.push({
        'text': `${new Date().getFullYear()} IPIPAL INC. All Rights Reserved.`,
        'top': '96%',
        'right': '32px',
        'textStyle': {'fontSize': '12px', 'color':'#606060', 'fontWeight':'normal'}
      })
      
      option.title.push({
        'text': 'A CUEDWELL CHART',
        'top': '96%',
        'left': '32px',
        'textStyle': {
          'fontSize': '12px', 
          'color': 'lightgrey', 
          'fontWeight': 'bold'
        }
      });
    }catch(e){
      console.error(e.message)
    }
  }
  
  const setCustomLegend = (legend = {}) => {
    return {
      show: legend.show !== undefined ? legend.show : true,
      // type: legend.type || 'scroll',
      orient: legend.orient || 'horizontal',
      left: legend.left || 50,
      bottom: legend.top || 80,
      right: 70,
      bottom: legend.bottom || 40,
      textStyle: {
        width: 130,
        overflow: 'break'
      },
      formatter: (p) => {
        if (p) {
          return p;
        }
        return "No submissions";
      },
    };
  };
  
  const setChart = () =>{
    const dom = document.getElementById(chartId)
    let setting = {
      height: props.height || 560,
      renderer: 'canvas',
      useDirtyRect: false
    }
    if(props.width)setting.width = props.width;

    const myChart = window.echarts.init(dom, null, setting)

    let option = null

    const addOption = () =>{
      if (option && typeof option === 'object') {
        setCommonOption(option)
        myChart.setOption(option, true)
        window.addEventListener('resize', function(){
          myChart.resize();
        })
        onBeforeRender && onBeforeRender(myChart)
      }else{
        myChart.setOption({}, true);
      }
    }

    if(onClickEvent){
      myChart.on('click', function(params) {
        onClickEvent(params)
      })
    }
   /* fn.toggleLegend = () =>{
      if(option.legend){
        option.legend.show = !option.legend.show;
        myChart.setOption(option, true)
      }
    }*/
      
    if(chartType === 'line'){
      option = {
        tooltip: {
          trigger: 'axis',
          position: function (pt) {
            return [pt[0], '10%'];
          }
        },
        xAxis: xAxis,
        yAxis: yAxis,
        dataZoom: dataZoom,
        series: [
          {
            type: 'line',
            top: '20px',
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: 'rgb(255, 70, 131)'
            },
            data: data,
            markLine: {
              data: [{
                type: "average"
              }],
              silent: true
            }
          }
        ]
      }
    }else if(chartType === 'bar-y-category-stack'){
      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: setCustomLegend(legend),
        grid: {
          left: '3%',
          right: '4%',
          bottom: '140px',
          containLabel: true
        },
        xAxis: xAxis,
        yAxis: yAxis,
        dataZoom:dataZoom,
        series: data
      }
    }else if(chartType === 'bar'){ 
      const barSeries = series || [
        {
          name: 'Direct',
          type: 'bar',
          barWidth: barWidth || '60%',
          colorBy: 'data',
          top: '20px',
          label: {
            show: true,
            color: 'white',
            overflow:'break',
            formatter: (param) => {
              return `${param.data.value || param.data.y}`
              //return param.name + ' (' + param.value + ')' + ' (' + param.percent * 2 + '%)';
            }
          },
          markLine: {
            data: [{
              type: "average"
            }],
            silent: true
          }
        }
      ]

      option = {
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            return formatter(params)
          }
        },
        grid: {
          left: '6%',
          right: '4%',
          bottom: '10%',
          containLabel: true
        },
        xAxis: [
          {
            type: xAxis.type,
            name: xAxis.name,
            nameLocation: 'middle',
            nameGap: 37,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: yAxis.type,
            name: yAxis.name,
            nameLocation: 'middle',
            nameGap: 54,
            /*axisLabel : {
              formatter: '{value} °C'
            },*/
          }
        ],
        dataset: {
          dimensions: dimensions,
          source: data
        },
        series:  barSeries
      }
      //console.log(JSON.stringify(option))
    }else if(chartType === 'pie'){ 
      option = {
        legend: setCustomLegend(legend),
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            return formatter && formatter(params)
          }
        },
        label: {
          show: false
        },
        dataset: {
          dimensions: dataset?.dimensions || null, //['value']
          source: data
        },
        series: [
          { 
            type: 'pie', 
            bottom: '100px',
            color: colors,
            label: {
              show: true,
              overflow: 'break', 
              formatter: (param) => {
                return ' (' + (param.data.value!=null ? param.data.value : param.data.y) + ')' + ' (' + param.percent + '%)';
                //param.name + 
                //return `${params.data.value || params.data.y}`
              }
            }
            /*encode: {name: 'x', value:'y'}*/
          }
        ]
      };
    }else if(chartType === 'pie-half-donut'){ 
      option = {
        graphic: props.graphic,
        legend: {
          show: legend.show,
          left: 'center',
          // doesn't perfectly work with our tricks, disable it
          selectedMode: false,
          textStyle:{
             width: 150,
             overflow: 'break'
          } 
        },
        tooltip: {
          trigger: 'item',
          textStyle:{
            fontSize: 12,
            width:90,
            overflow:'break',
          },
          formatter: (params) => {
           return formatter(params)
          }
        },
        label: {
          show: false
        },
        series: [
          { 
            type: 'pie', 
            top: '20px',
            radius: ['40%', '70%'],
            center: ['50%', '70%'],
            // adjust the start angle
            startAngle: 180,
            label: {
              show: true,
              fontSize: 12,
              width:100,
              overflow:'break',
              formatter(param) {
                // correct the percentage
                //param.name + 
                return ' (' + param.value + ')' + ' (' + param.percent * 2 + '%)';
              }
            },
            data: data
          }
        ]
      }
    }else if(chartType === 'treemap'){ 
      option = {
        legend:legend,
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            return formatter(params)
          }
        },
        label:label,
        series: [
          {
            type: 'treemap',
            top: '50px',
            bottom: '50px',
            data: data,
            overflow:'break',
            label:{
              formatter: (params) => {
                return labelFormatter && labelFormatter(params)
              }
            }
          }
        ]
      }  
    }else if(chartType === 'scatter'){
      option = {
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            return formatter(params)
          }
        },
        grid: {
          left: '4%',
          right: '4%',
          bottom: '5%',
          containLabel: true
        },
        xAxis: [
          {
            type: xAxis.type,
            name: xAxis.name,
            nameLocation: 'middle',
            nameGap: 20,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: yAxis.type,
            name: yAxis.name,
            nameLocation: 'middle',
            nameGap: 20,
            /*axisLabel : {
              formatter: '{value} °C'
            },*/
          }
        ],
        dataset: {
          source: data
        },
        series: [
          {
            name: 'Direct',
            top: '20px',
            symbolSize: 10,
            type: 'scatter',
            barWidth: '60%',
            encode: { x: 0, y: 1},
            color:colors,
            label: {
              show: false,
              overflow:'break',
              formatter: (params) => {
                return labelFormatter && labelFormatter(params)
              }
            },
            markPoint: {
              label:{show: true},
              data: [
                { type: 'max', name: 'Max', valueIndex:0 },
                { type: 'min', name: 'Min', valueIndex:0  }
              ]
            }
          }
        ]
      }
    }else if(chartType === 'scatter_bar_histogram'){
      // See https://github.com/ecomfe/echarts-stat

      const setOption = () =>{
        window.echarts.registerTransform(window.ecStat.transform.histogram);
        option = {
          dataset: [
            {
              source: data
            },
            {
              transform: {
                type: 'ecStat:histogram',
                config: {}
              }
            },
            {
              transform: {
                type: 'ecStat:histogram',
                // print: true,
                config: { dimensions: [1] }
              }
            }
          ],
          tooltip: {},
          grid: [
            {
              top: '50%',
              right: '50%'
            },
            {
              bottom: '52%',
              right: '50%'
            },
            {
              top: '50%',
              left: '52%'
            }
          ],
          xAxis: [
            {
              name: xAxis.name,
              nameLocation: 'middle',
              nameGap: 20,
              scale: true,
              gridIndex: 0
            },
            {
              type: 'category',
              scale: true,
              axisTick: { show: false },
              axisLabel: { show: false },
              axisLine: { show: false },
              gridIndex: 1
            },
            {
              scale: true,
              gridIndex: 2
            }
          ],
          yAxis: [
            {
              name: yAxis.name,
              nameLocation: 'middle',
              nameGap: 20,
              gridIndex: 0
            },
            {
              gridIndex: 1
            },
            {
              type: 'category',
              axisTick: { show: false },
              axisLabel: { show: false },
              axisLine: { show: false },
              gridIndex: 2
            }
          ],
          series: [
            {
              name: 'origianl scatter',
              type: 'scatter',
              xAxisIndex: 0,
              yAxisIndex: 0,
              encode: { tooltip: [0, 1] },
              datasetIndex: 0
            },
            {
              name: 'histogram',
              type: 'bar',
              xAxisIndex: 1,
              yAxisIndex: 1,
              barWidth: '99.3%',
              label: {
                show: true,
                position: 'top'
              },
              encode: { x: 0, y: 1, itemName: 4 },
              datasetIndex: 1
            },
            {
              name: 'histogram',
              type: 'bar',
              xAxisIndex: 2,
              yAxisIndex: 2,
              barWidth: '99.3%',
              label: {
                show: true,
                position: 'right'
              },
              encode: { x: 1, y: 0, itemName: 4 },
              datasetIndex: 2
            }
          ]
        };
        addOption()
      }
      
      importScript('/scripts/echarts-stat/dist/ecStat.min.js', setOption, window.ecStat);
    }else if(chartType === 'funnel'){
      option = {
        tooltip: {
          trigger: 'item',
          //formatter: '{a} <br/>{b} : {c}%'
          formatter: (params) => {
           return formatter(params)
          }
        },
        /*legend: {
          data: ['Show', 'Click', 'Visit', 'Inquiry', 'Order']
        },*/
        series: [
          {
            name: 'Funnel',
            type: 'funnel',
            left: '10%',
            top: 60,
            bottom: 60,
            width: '80%',
            min: 0,
            max: 100,
            minSize: '0%',
            maxSize: '100%',
            sort: 'descending',
            gap: 2,
            label: {
              show: true,
              position: 'inside',
              color:'#fff',
              formatter(param) {
                return param.name + ' (' + param.value + ')';
                // + ' (' + param.percent * 2 + '%)'
              }
            },
            labelLine: {
              length: 10,
              lineStyle: {
                width: 1,
                type: 'solid'
              }
            },
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 1
            },
            emphasis: {
              label: {
                fontSize: 20
              }
            },
            data: data
          }
        ]
      };
    }else if(chartType === 'custom'){
      option = props.option
    }else if(chartType === 'sankey_levels'){
      option = {
        title: {
          text: 'Sankey Diagram'
        },
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove'
        },
        series: [
          {
            type: 'sankey',
            data: [
   ],//data.nodes,
            links: [
      
   ],//data.links,
            emphasis: {
              focus: 'adjacency'
            },
            levels: [
              {
                depth: 0,
                itemStyle: {
                  color: '#fbb4ae'
                },
                lineStyle: {
                  color: 'source',
                  opacity: 0.6
                }
              },
              {
                depth: 1,
                itemStyle: {
                  color: '#b3cde3'
                },
                lineStyle: {
                  color: 'source',
                  opacity: 0.6
                }
              },
              {
                depth: 2,
                itemStyle: {
                  color: '#ccebc5'
                },
                lineStyle: {
                  color: 'source',
                  opacity: 0.6
                }
              },
              {
                depth: 3,
                itemStyle: {
                  color: '#decbe4'
                },
                lineStyle: {
                  color: 'source',
                  opacity: 0.6
                }
              }
            ],
            lineStyle: {
              curveness: 0.5
            }
          }
        ]
      }
    }

    addOption()
  }

  const style=props.height?{'height':props.height}:{}

  return (
    <>
      <div id={chartId} className="chart-container" style={style}/>
    </>
  )  
}

export {ECharts};