import React, { lazy, Suspense } from 'react';
import PrivateRoute from "../../routes/PrivateRoute";
import { Spinner } from '../Common/Spinner';
const ScreeningEvents = lazy(() => import('./List/ScreeningEvents'));
const ScreeningLists = lazy(() => import('./List/ScreeningLists'));
const ScreeningEventForm = lazy(() => import('./Form/ScreeningEventForm'));
const ScreeningForm = lazy(() => import('./Form/ScreeningForm'));

const ScreeningEventRouter = (props) => (
  <Suspense fallback={<Spinner/>}>
    <PrivateRoute path="/screening_events" searchString={props.searchString} component={ScreeningEvents} exact />
    <PrivateRoute path="/screening_lists/:screening_event_id?" component={ScreeningLists} exact />
    <PrivateRoute path="/screening_events/details/:id?" component={ScreeningEventForm} exact />
    <PrivateRoute path="/screening_events/create/:formId?/:id?" component={ScreeningEventForm} exact />
    <PrivateRoute path="/screening_lists/create/:eventId/:formType/:formId?/:id?" component={ScreeningForm} exact />
  </Suspense>
)

export default ScreeningEventRouter;