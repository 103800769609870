import React, {useState, Fragment, useEffect, useMemo} from "react";
import ReactDOM from 'react-dom';
import Pagination from '../Common/Pagination';
import useStyle from '../../hooks/useStyle';
import {OptionsObjList} from "../Common/FormInput";
import {Locales} from "../../constants/DataSourceList";
import {InputTranslate} from "../Locale/InputTranslate";
import CheckAccess from '../Roles/CheckAccess'; 
import { CTTooltip } from "../Common/CueTooltip";
import FormSuggestionService from "./FormSuggestionService";
import { LinkFontAwesomeIcon } from "../Common/ImageIcons";
import DataSourceService from "../../services/DataSourceService";
import LocaleService from "../../services/LocaleService";
import { useCurrentUserHook } from "../Authentication/useUserHook";
import { Spinner } from "../Common/Spinner";

let requestParams = {};
let translatedLangauges = [];
const DataSourceListModal = (props) => {
  useStyle('table');  

  const {autoTranslate=true, locale, form_template_id, data_source_id, openLocale, isSuperUser, hasLocalePermission, toggleModal} = props;

  const {currentUser} = useCurrentUserHook()
  const [dataSourceList, setDataSourceList] = useState([]);
  const [dataSource, setDataSource] = useState({});
  const [showDataSourceForm, setShowDataSourceForm] = useState(false);
  const [currentpage, setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState();
  const [localeInputFieldId, setLocaleInputFieldId] = useState();

  const permission = useMemo(() =>{
    if(hasLocalePermission?.write){
      return hasLocalePermission;
    }
    
    return CheckAccess.getMenuPermission(currentUser, 'data_choices');
  }, [currentUser.id])

  const permittedLocales = useMemo(() => {
    try{
      if(CheckAccess.checkIsAdmin(currentUser)){
        return Locales;
      }
      
      return Locales.filter((l) => currentUser.current_member.permissions.locale_translate.indexOf(l.sid)>-1);
    }catch(e){
      return [];
    }
  }, [currentUser])

  useEffect(() => {
    /*requestParams = {
      'page': currentpage, 
      'per_page' : 100,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'asc',
      'sub_organization_id':currentUser.current_organization_id
    }*/
    translatedLangauges = [];
    getDataSourceList();
    setLocale(locale);
  }, [currentpage])

  let getDataSourceList = () => { 
    if(permission?.read){
      setLoading(true)
      let req = {
        'form_template_id':form_template_id,
        'data_source_id':data_source_id, 
        'sub_organization_id':currentUser.current_organization_id,
        'per_page' : 100
      }
      DataSourceService.getDataSourceList(req).then((res)=>{
        if(res.status == 200){
          translatedLangauges = Object.keys(res.data.data_source_lists?.[0].locale_data || {})
          setDataSourceList(res.data.data_source_lists)
          setDataSource(res.data.data_source)
          setLoading(false)
        }
      })
    }
  }

  let updateStatus = (o) =>{
    if(CheckAccess.checkIsAdmin(currentUser)){
      let req = {
        filterable_type: 'data_source_list',
        created_by: currentUser.id,
        sub_organization_id:currentUser.current_organization_id,
        filterable_id: o.id,
        data_source_id: o.data_source_id,
        form_template_id: form_template_id 
      }

      DataSourceService.updateDataSourceListStatus(req).then((res)=>{
        if(res.status === 200){
          if(o.inactive_status){
            o.inactive_status=null;
          }else{
            o.inactive_status={};
          }
          setDataSourceList([...dataSourceList])
        }
      }) 
    }
  }

  const addDataSource = (data) =>{
    if(permission?.write){
      let req = {
        'form_template_id': form_template_id,
        'data_source_id': dataSource.id, 
        'item_id': currentUser.current_organization_id,
        'item_type': 'organization',
        'privacy_type':'private',
        'data':data
      }
      DataSourceService.createDataSource(req).then((res)=>{
        if(res.status === 201){
          setDataSourceList([res.data.data_source_list , ...dataSourceList])
          setShowDataSourceForm(false)
        }
      })
    }
  }

  const updateDataSourceList = (d) =>{
    if(permission?.write){
      setLoading(true)
      if(d?.id){
        let req = {
          id: d.id,
          locale_data: d.locale_data || {}
        }
        DataSourceService.updateDataSourceList(req.id, req).then((res)=>{
          alert('success')
          setLoading(false)
        })
      }else{
        let req = {
          form_id: form_template_id,
          organization_id: currentUser.current_organization_id,
          suggestion_type: 'data_source_locale',
          data:{
            data_source_list_id: d.id,
            locale_data: d.locale_data || {}
          }
        }
        req.data.locale_data[d.locale] = {label:d.value}
        FormSuggestionService.add(req).then((res)=>{
          //updateFormLocale(d.locale, d.value)
          setLoading(false)
        })
      }
    }
  }

  const updateAllDataSourceList = () =>{
    if(permission?.write){
      setLoading(true);
      const list = [];
      
      dataSourceList.forEach((d, i) =>{
        if(d.locale_data){
          list.push({
            id:d.id,
            locale_data:d.locale_data
          })
        }
      })

      let req = {
        is_bulk_update:true,
        data_source_lists:list
      }
      DataSourceService.updateDataSourceList(null, req).then((res)=>{
        alert('success')
        setLoading(false)
      })
    }
  }

  const setTranslatedData = (translations, local) =>{
    dataSourceList.forEach((d, i)=>{
      if(translations[i]?.translatedText?.length > 0){
        d.locale_data = d.locale_data || {}
        d.locale_data[local.sid] = d.locale_data?.[local.sid] || {label:translations[i].translatedText}
      }
    })
    setDataSourceList([...dataSourceList])
  }

  const googleTranslate = (local) =>{
    if(autoTranslate){
      setLoading(true)
      const req = {
        "source": "en",
        "q": dataSourceList.map((d)=> d.data.label),
        "target": local.iso_code
      }
      
      LocaleService.googleTranslate(req).then((res)=>{
        if(res.data && res.data?.translations?.length > 0){
          setTranslatedData(res.data.translations, local)
          setLoading(false)
          //setTranslatedText(res.data.translations[0].translatedText)
        }else{
          setLoading(false)
        }
      })
    }
  } 

  const handleLocale = (target) =>{
    const local = permittedLocales[target.selectedIndex-1]
    setSelectedLocale(local)
    if(local.iso_code && permission?.write){
      googleTranslate(local)
    }
  }
  
  const handleLocaleChange = (idx, value) =>{
    dataSourceList[idx].locale_data[selectedLocale.sid] = {label:value}
    setDataSourceList([...dataSourceList])
  }

  const setLocale = (local_sid) =>{
    if(local_sid){
      const local = Locales.find((l)=>l.sid === local_sid)
      setSelectedLocale(local)
    }
  }

  const openAddLocaleForm = (e, dataSourceListItem, k) =>{
    dataSourceListItem.form_template_id = form_template_id; //pass form id
    openLocale(e, dataSourceListItem, 'data_source_list', k)
  }

  const DataSourceForm = () => {
    const [newDataSource, setNewDataSource] = useState({})

    const handleInput = (e) =>{
      setNewDataSource({...newDataSource, [e.target.name]:e.target.value})
    }

    return(
      <form>
        <div className="ml-section">
          <label><b>Role</b></label>
          <input onChange={e=>handleInput(e)} 
            className="ml-input ml-border ml-margin-bottom"
            type="text" placeholder="Label" name="label"
            required defaultValue={newDataSource.label}/>

          <div className="ml-button ml-block ml-section ml-padding bg-lgrey white font-16" onClick={()=>addDataSource(newDataSource)}>
            <b>{newDataSource.id?'Update':'Create'}</b>
          </div>  
        </div>
      </form>  
    )
  }

  const tableData = dataSourceList.map((o, k) => 
    <Fragment key={k}>
      <tr>
        <td className="text-center">
          {o.id}
        </td>
        <td className="text-center" data-tip={o.data.hover_text}>
          {o.data.label}
        </td>
        {selectedLocale &&
          <td>
            {localeInputFieldId == `textarea_${k}` &&
              <InputTranslate isRichText={false} 
                localeId={selectedLocale.value} 
                inputFieldId={localeInputFieldId}
                adjustTranslatePosition={false}
                onInsertTextArea={(target)=>handleLocaleChange(k, target.value)}/>
            }
            <textarea id={`textarea_${k}`}
              onChange={e => handleLocaleChange(k, e.target.value)} 
              onFocus={() => setLocaleInputFieldId(`textarea_${k}`)} 
              value={o.locale_data?.[selectedLocale.sid]?.label} 
              className="form-control textAreaht"/>
          </td>
        }
        {isSuperUser &&
          <td className="text-center">
            <span className={o.inactive_status==null?'fas fa-check-circle':'far fa-circle'} 
            onClick={() => updateStatus(o)}/>
          </td>
        }
        <td className="flex aligncenter">
          {permission?.write ?
            <>
              <LinkFontAwesomeIcon 
                tooltip="Update" 
                onSelect={() => updateDataSourceList(o)} 
                iconCss="fas fa-plus-circle white"/>

              <LinkFontAwesomeIcon 
                tooltip="Edit" 
                onSelect={e=>openAddLocaleForm(e, o, k)} 
                iconCss="fas fa-edit white"/>
            </>
            : null
          }
        </td>
      </tr>
    </Fragment>
  )

  return(
    ReactDOM.createPortal(
      <Fragment>
        <div className="ml-modal">
          <div className="ml-modal-content ml-card-4 ml-animate-zoom brd-10 bg-white w-90p">
            <div className="flex justspacebetween modal-title">
              <h4 className="f18 text-white fw500 p12-10-15">
                {dataSource.title} - {dataSource.id}
                <br/>
                <small>Data Choices</small>
              </h4>
              <button className="btn-close" title="Close Modal" onClick={e=> toggleModal(e)}>×
              </button>
            </div>

            <div className="row m-t-10">
              {loading && <Spinner/>}
              {!showDataSourceForm && 
                <Fragment>
                  {permission?.write
                    ? <>
                        <select name="locale" id="locale" 
                          className="form-control input-sm m-b-10" 
                          onChange={e=>handleLocale(e.target)}>
                            <option value="" disabled selected>Select an item from list below</option>
                            <OptionsObjList list={permittedLocales} idKey="sid" labelKey="label"/>
                        </select>

                        {dataSource.add_custom_value &&
                          <span className="text-right font-15 cursor" onClick={()=>setShowDataSourceForm(true)}>
                            <u>Add custom values</u>
                          </span>
                        }
                      </>
                    : null
                  }

                  {isSuperUser && translatedLangauges.map((l, i) =>
                    <span key={i} className="text-cap m-r-10 badge-2 bg-lgrey" 
                      onClick={()=>setLocale(l)}>{l}</span>
                  )}

                  <div className="table-sm table-responsive p-0">
                    <table className="shadow-small">
                      <thead>
                        <tr className="white bg-black">
                          <th className="p-2 bold-400 text-center miw-95">
                            ID 
                          </th>
                          <th className="p-2 bold-400 text-center miw-95">
                            Label 
                          </th>
                          {selectedLocale &&
                            <th className="p-2 bold-400 text-center miw-95">
                              {selectedLocale.label} 
                            </th>
                          }
                          {isSuperUser &&
                            <th className="p-2 bold-400 text-center miw-95">
                              Status
                            </th>
                          }
                          <th>
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData}
                      </tbody>
                    </table>
                  </div>
                  
                  {permission?.write && selectedLocale &&
                    <button className="ml-button ml-block ml-section ml-padding bg-highlight white" 
                      onClick={()=>updateAllDataSourceList()}>
                      <b>Update All</b>
                    </button>
                  }

                  <Pagination pagemeta={pagemeta} 
                    currentpage={currentpage} 
                    setCurrentpage={setCurrentpage}/>
                </Fragment>
              }

              {showDataSourceForm && <DataSourceForm/>}
              <CTTooltip />
            </div>
          </div>
        </div>
      </Fragment>, 
    document.body)
  )
};

export default DataSourceListModal;