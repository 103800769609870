import React, { lazy, Suspense } from 'react';
import PrivateRoute from "../../routes/PrivateRoute";
import { Spinner } from '../Common/Spinner';
const FormsList = lazy(() => import('./FormsList'));
const ReviewList = lazy(() => import('./Reviews/ReviewList'));

const FormTestInputRoute = (props) => (
  <Suspense fallback={<Spinner/>}>
    <PrivateRoute path="/test/input/forms" component={FormsList} exact />
    <PrivateRoute path="/test/input/form/templates" component={FormsList} exact />
    <PrivateRoute path="/test/input/review/:form_test_input_id?" component={ReviewList} exact />
  </Suspense>
)

export default FormTestInputRoute;