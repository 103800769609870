import React, { Fragment, useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { NavIcon } from "../../Common/MenuItem"
import useStyle from '../../../hooks/useStyle'
import useSort from '../../../hooks/useSorting'
import Cs from "../../../services/CommonService"
import ConfirmMenu from "../../Modals/ConfirmMenu"
import useModal from "../../../hooks/useModal"
import OrganizationMemberService from "./OrganizationMemberService"
import GenericModal from "../../Modals/GenericModal"
import AddFamilyMember from "./AddFamilyMember"
import { PortalToggleMenu } from "../../HealthJourney/GenericPortalPage/Common"
import DynamicTable from "../../Common/DynamicTable"
import { useCurrentUserHook } from "../../Authentication/useUserHook"
import { Spinner } from "../../Common/Spinner"

let formFields = []
let selectedMember = {}
let requestParams ={}
let memberFormProps = null
const FamilyMemberList = (props) => {
  useStyle('table')  
  
  const history = useHistory()

  const {journey, menuOptions, searchString, componentName, dataSource:component, journeyType} = props
  const {child_form:formId} = component

  const {currentUser} = useCurrentUserHook()
  
  const [isLoading, setIsLoading] = useState(true)
  const [list, setList] = useState([])

  const {isOpen:isDeleteOpen, toggleModal:toggleDeleteModal} = useModal()
  const {isOpen:isAddMemberOpen, toggleModal:toggleAddMemberModal} = useModal()

  useEffect(() => {
    requestParams = {
      page:1,
      per_page:100,
      sort_column: "updated_at",
      sort_direction: "desc",
      organization_id:currentUser.current_organization_id,
      subscription_id:currentUser.current_subscription_id,
      form_id: formId,
      form_fields_params: {
        'ids':[formId]
      }
    } 
    getAll()
  }, [])

  const getAll = () =>{
    setIsLoading(true)
    OrganizationMemberService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        formFields = res.data.form_fields[formId] || []
        setList(res.data.organization_members)
        setIsLoading(false)
      }
    })
  }

  const {sort} = useSort(requestParams, getAll)

  const openForm = (id) =>{
    memberFormProps = Cs.routeCancerPath(component, journey, history, id)
    memberFormProps.component = AddFamilyMember
    toggleAddMemberModal()
  }

  const onDelete = (org) => {
    toggleDeleteModal()
    selectedMember = org
  }

  const deleteMember = (e) => {
    e.preventDefault()
    OrganizationMemberService.delete(selectedMember.id).then((res) => {
      if (res.status == 204) {
        setList(list.filter(i => i.id !== selectedMember.id))
      }
    })
    toggleDeleteModal(e)
  }

  const onFormSubmit = (activity={}) =>{
    getAll()
    toggleAddMemberModal()
  }

  const updateMember = (member, key) =>{
    let req = {
      id: member.id,
      [key]: !member[key]
    }
    OrganizationMemberService.update(req).then((res) => {
      if (res.status == 204) {
        member[key] = !member[key]
        setList([...list])
      }
    })
  }

  const OptionsEle = ({item}) => (
    <td className="text-center">
      <span className="fas fa-edit m-l-15" onClick={e=>openForm(item.id)}/>
      <span className="fas fa-trash m-l-15" onClick={e=>onDelete(item)}/>
    </td>
  )

  if(isAddMemberOpen){
    return (
      <GenericModal toggleModal={toggleAddMemberModal} 
        isPopupMode="true" onFormSubmit={onFormSubmit} 
        {...memberFormProps} 
      />
    )
  }

  return (
    <Fragment>
      {isLoading && <Spinner/>}
      <div className="row">
        <div className="col-xs-8">
          <h4>{currentUser.current_organization.data.name}</h4>
          <div className="font-15 m-t-10">
            List of Members - {list.length}
          </div>
        </div>
        <div className="col-xs-4">
          <NavIcon id="new_member" dataTip="Add New Member" 
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-plus"
            onSelect={e=>openForm()}/>
        </div>
      </div>

      <div className="row">
        <div className="table-responsive table-fix-head">
          <DynamicTable requestParams={requestParams} onSort={sort} 
            formFields={formFields} formDataList={list} 
            tClass="table shadow-small" 
            OptionsEle={OptionsEle}/>
        </div>
      </div>

      <PortalToggleMenu
        journey={journey} 
        menuOptions={menuOptions} 
        journeyType={journeyType}
        permissionKey="infant_portal"
      />

      <ConfirmMenu isOpen={isDeleteOpen} 
        toggleModal={toggleDeleteModal} 
        success={deleteMember}/>
    </Fragment>
  )

}

export default FamilyMemberList;