import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import Cs from "../../services/CommonService";
import {CueTip} from "./CueTooltip";
import importScript from "../../hooks/importScript";

const CardMainMenu = ({activity, showMenu, disableClickEvent}) => (
  <div style={disableClickEvent} className="m-r-15">
    <i data-tip="Menu" onClick={e=>showMenu(e, activity)} className="fas fa-bars font-18"/>
  </div>
)

const MenuItem = ({id, link, className, icon, action, onSelect}) => {
  let linkAttribute = {'id':id,'to':link, 'className':className}

  return (
    <Link {...linkAttribute}  onClick={e=>onSelect && onSelect(e)}>
      <i className={icon}></i>
      <span className="font-13">{action}</span>
      <i className="fa fa-angle-right"></i>
    </Link>  
  )
}

const CardMenuInfo = ({formId, recordId, createdAt, userName}) => {
  const {state: { screen }, dispatch} = useContext(AuthContext)

  return (
    <a href="">
      <i className="fas fa-info-circle"></i>
      <span className={(screen.width < 460 && 'lh-20')+' font-15'}>
        <small className="lred">since { Cs.formatUpdateDate(createdAt)}</small>
        <small className="m-l-10"> by {userName}</small>
        <small className="m-l-10">({formId}-{recordId})</small>
      </span>
      <i className="fa fa-angle-right"></i>
    </a>  
  )
}

const NavIcon = ({id, dataTip, positionCss='bottom', link, className, icon, onSelect, children}) => {
  const attribute = {'id':id, 'to':link, 'className':(className+' tooltip')}

  if(link){
    return (
      <Link {...attribute}>
        <i className={icon}/>
        <CueTip tooltip={dataTip} positionCss={positionCss}/>
      </Link>  
    )
  }else{
    return (
      <span {...attribute} onClick={e=>onSelect && onSelect(e)}>
        <i className={icon}/>
        <CueTip tooltip={dataTip} positionCss={positionCss}/>
        {children}
      </span>
    )   
  } 
}

const PrintNavIcon = ({tableId, fileName}) =>{
  const [showBtn, setShowBtn] = useState(false);
  const [doc, setDoc] = useState();

  useEffect(()=>{
    /*if(!window.jspdf){*/
      importScript('/scripts/jspdf.umd.min.js', checkLoaded, window.jspdf);
      importScript('/scripts/jspdf.plugin.autotable.js', checkLoaded, window.autotable);
    /*}*/
  }, [])

  useEffect(()=>{
    if(tableId && fileName && doc?.autoTable) setShowBtn(true);
  }, [doc?.autoTable])

  const checkLoaded = () =>{
    if(window.jspdf && window.CellHookData){
      setDoc(new window.jspdf.jsPDF());
    }
  }

  const download = () =>{
    doc.autoTable({ html: '#'+tableId });
    doc.save(fileName+'.pdf');
  }

  if(showBtn){
    return(
      <NavIcon
        id="print"
        dataTip="Print"
        onSelect={() => download()}
        className="bg-highlight round-btn float-right m-l-15"
        icon="far fa-file-pdf"/>
    )
  }

  return null;
}

export {MenuItem,CardMenuInfo,NavIcon,CardMainMenu,PrintNavIcon}
