import React, { useState, useEffect, useRef } from "react";
import Cs from "../../services/CommonService";
import FormSubmissionReviewService from './FormSubmissionReviewService';
import { UserName } from '../UserProfile/UserInfoPopup';
import { LinkFontAwesomeIcon } from "../Common/ImageIcons";
import FormHelper from "../FormBuilder/FormHelpers";
import {ReviewScore} from "./Common";

const FormSubmissionReview = ({editReview, showReviewForm, formId, fieldClientId, fieldIndex, itemId, itemType, journeyProfileId, organizationId}) => {
	const [formError, setFormError] = useState({});
	const [isReviewOpen, toggleReview] = useState(false);
	const [review, setReview] = useState(editReview || {
		'form_id':formId,
		'field_client_id':fieldClientId,
		'field_index':fieldIndex,
		'organization_id':organizationId,
		'item_id':itemId,
		'item_type':itemType,
		'journey_profile_id':journeyProfileId
	});

	useEffect(() => {
		if(isReviewOpen && showReviewForm){
    		FormHelper.validate('form_sub_review', review, formError, setFormError);
    	}
  	}, [isReviewOpen && showReviewForm && review])

	const onInputChange = (name, value) =>{
    	setReview({...review, [name]:value});
	}

	const onSubmit = async (e) =>{
		e.preventDefault();
		if(review.id){
			review.status = review.score === 1 ? 'close':'reopen';
			const {status} = await FormSubmissionReviewService.update(review);	
			toggleReview(false);
		}else{
			review.status = 'open';
			const {status, data} = await FormSubmissionReviewService.create(review);
			if(status === 201){
				setReview(data.review);		
				toggleReview(false);
			}
		}
	}

	/*const updateRewardScore = async (id, score) =>{
		const req = {
			'id':id,
			'score':score,
			'status':score === 1 ? 'close':'reopen'
		}
		const {status} = await FormSubmissionReviewService.update(req);
		if(status === 204){
			setReview({...review, ...req});
			toggleReview(false);
		}
	}*/

	if(isReviewOpen && showReviewForm){
		return(
			<form id="form_sub_review" className="row m-t-5">
				<div className="col-xs-12">
			 		<label className="form-label font-15 m-b-5 req-field">Write Review</label>
			        <textarea maxLength="100" onChange={(e) => onInputChange(e.target.name, e.target.value)}
			          className="form-control" placeholder="Review Message" 
			          name="message" defaultValue={review.message} autoFocus required>
			        </textarea>
			        <div className="text-muted">Maximum 100 Characters</div>
			    </div>
			    <div className="col-xs-12 m-t-5">
					<div className="flex gap-20">
						<LinkFontAwesomeIcon id="review_pos" 
						  parentCss={`bg-lgreen ${review.score===1?'br-black':''}`}
			              onSelect={()=>onInputChange('score', 1)}
			              iconCss="far fa-thumbs-up font-18 white"/>

	              		<LinkFontAwesomeIcon id="review_neg" 
	              		  parentCss={`bg-lred ${review.score===-1?'br-black':''}`}
			              onSelect={()=>onInputChange('score', -1)}
			              iconCss="far fa-thumbs-down font-18 white"/>
			            <input type="hidden" name="score" defaultValue={review.score} required/>
					</div>
				</div>
				{formError.is_valid
					? <div className="flex gap-20 m-t-10 coldir-xs">
						<button className="btn btn-sm bg-highlight white"
							disabled={review.message==null || review.message?.length < 1}
							onClick={(e)=>onSubmit(e)}> 
			        		<b>{review.id?'Update':'Create'}</b>
			      		</button>   
			      		<button className="btn btn-sm bg-cancel white"
				     		onClick={()=>toggleReview(!isReviewOpen)}> 
				         	<b>Close</b>
				      	</button>   
			    	  </div>
			    	: null
			    }
	    	</form>
		)	
	}else{
		return (
		  <>
		  	<div className="flex gap-10 m-t-5 aligncenter">
		  		<ReviewScore review={review} />
				<p className={`font-15 ${review.score === 1 ? 'green' : 'lred'}`}>{review.message}</p>
			</div>
			<div className="flex gap-10 m-t-5 aligncenter">
				<span>{review.user?.name}</span>
				<span>{review.user?.role}</span>
				<span>{Cs.formatUpdateDate(review.created_at)}</span>
				{(['form_update', 'close'].includes(review.status) && review.form_updated_at) && 
					<span>Form updated : {Cs.formatUpdateDate(review.form_updated_at)}</span>
				}
				{showReviewForm && <LinkFontAwesomeIcon onSelect={()=>toggleReview(!isReviewOpen)} iconCss="far fa-comment-alt font-18 white" tooltip="Review"/>}
		  	</div>
		  </>
		)
	}
	
}

export {FormSubmissionReview};