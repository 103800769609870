import React ,{} from "react";
import Cs from "../../services/CommonService";
import {FormFieldCard} from "../Common/FormFieldsView";
import {PillList} from "../Common/NotificationPill";
import useStyle from '../../hooks/useStyle';

const UserRegCard = (props) => {
  useStyle('/css/tile_components.css', true)

  const {  formFields, userReg, currentUser, toggleModal, enableScroll=true} = props;
  
  return (
    <div id="ct">
      <div className="bg-white brd-10 card-shadow m-b-15">
        <Header userReg={userReg} />
        <div className="card-content p20">
          <div className={"b-tab-table active "+(enableScroll?'scroll-auto ht-400':'')}>
            <div className="p-b-15 m-b-15 m-t-15">
              <FormFieldCard formFields={formFields} 
                dataSource={userReg.data_source || {}}
                data={userReg.data || {}}
                formFieldViewEle={formFieldViewEle}/>
            </div>
          </div>
        </div>
        <Footer {...{  userReg, currentUser, toggleModal}}/>
      </div>
    </div>
  )
}

const formFieldViewEle = {
  phc: (clientId, data, ds) => {
    return <PillList list={data.phc_array} dataKey="phc_name" style="badge-2" />
  }
}

const Header = ({userReg}) => (
  <div className="card-title card-title-gray flex p-b-15 p20 coldir">
    <h4 className="fw700 f18 card-heading flex coldir text-left">
      {userReg.data.name}
    </h4>
    <div className="f14 m-t-10 fw500">
      <div>{userReg.organization}</div>
      {Cs.formatUpdateDate(userReg.updated_at)}
    </div>
  </div>
)

const Footer = ({ userReg, currentUser, toggleModal }) => {
  return(
    <div className="card-footer flex justspacebetween p20 aligncenter">
      <ul className="flex aligncenter">
        <li className="m-r-25">{userReg.record_id}</li>
      </ul>
    </div>
  )
}

export default UserRegCard;