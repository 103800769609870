import React from "react";
import { CueTip } from "./CueTooltip";

const Badge = ({text}) => {
  const defaultCss = 'badge badge-success fw900 f12'
  return (
    <div className={defaultCss}>{text}</div>
  )
}

const NotificationBadge = ({count, css, position="top"}) =>{
  const defaultCss = css?css:(position==='bottom'?
    'notification-count brd-100p text-center t-18 p-t-2 no-border bg-lred fw900 font-12'
    :
    'notification-count brd-100p notification-f11 fw900')

  return(
    <span className={defaultCss}>{count}</span>
  )
}

const StatusIndicator = ({ type }) => {
  if (!type) return null;

  const indicatorType = {
    verified: {
      text: "V",
      tooltip: "User Verified",
    },
    activated: {
      text: "A",
      tooltip: "Account Activated",
    },
    loggedIn: {
      text: "L",
      tooltip: "User has logged in",
    },
  };

  const { text, tooltip } = indicatorType[type] || {};

  return (
    <span className="circle-sm-2 bg-light-green white tooltip">
      {text}
      <CueTip tooltip={tooltip} positionCss="right" />
    </span>
  );
};

export { Badge, NotificationBadge, StatusIndicator };
