import React, { useEffect, useState, useRef } from 'react'
import useSort from '../../hooks/useSorting'
import Cs from '../../services/CommonService'
import {RecordAudioSafari} from '../Common/RecordAudio'
import FileUploadService from '../../services/FileUploadService'
import env from "../../env";
import {OptionsObjList} from "../Common/FormInput"
import { Table, THead, TH, TR, TD, TBody } from "../Common/TableView"
import GenericModal from "../Modals/GenericModal"
import useModal from "../../hooks/useModal"
import {usePageLocale} from "../../hooks/useLocale"
import { CueTip } from "../Common/CueTooltip"
import ConfirmMenu from "../Modals/ConfirmMenu"
import {subscribe, unsubscribe} from "../../hooks/useEvents"
import { useCurrentUserHook } from '../Authentication/useUserHook'
import { Spinner } from '../Common/Spinner'

// let requestParams = null
// let dataSource = {}
// let audios = []

const AudioRecordingList = ({journeyProfileId, incrementCount}) => {
 const {currentUser} = useCurrentUserHook()
  //const [audios, setAudioList] = useState([])
  let { current: scope } = useRef({ requestParams: null, dataSource: {}, audios: [] });
  const [selectedItem, setSelectedItem] = useState()
  const [subject, setSubject] = useState()
  const [loading, setLoading] = useState(true)
  const { isOpen, toggleModal: toggleDeleteModal } = useModal()

  useEffect(() => {
    scope.requestParams = {
      page:1,
      per_page:100,
      fileable_type:'journey_profile_audio',
      journey_profile_id:journeyProfileId,
      data_source_params:{
        data_source_id: [3692]
      }
    }

    getAll()

    return(() => {
      scope.audios = null;
      scope.dataSource = null;
      scope.requestParams = null;
    })
  }, []);

  const getAll = () =>{
    setLoading(true);
    FileUploadService.getAll(scope.requestParams).then((res) => {
      scope.dataSource = res.data.data_source_list;
      scope.audios = res.data.file_uploads;
      incrementCount(0)
      setLoading(false)
    })
  }

  const {sort} = useSort(scope.requestParams, getAll)

  const onUploadSuccess = (data) =>{
    data.file_upload.author = {name:currentUser.name }
    scope.audios.unshift(data.file_upload)
    incrementCount(1)
    setLoading(false)
  }

  const onDelete = (a) => {
    setSelectedItem(a)
    toggleDeleteModal()
  }

  const deleteAudio = (e) => {
    toggleDeleteModal(e)
    setLoading(true)
    FileUploadService.deleteFileFromUrl(selectedItem.url).then((res)=>{
      if(res.status == 204){
        scope.audios = (scope.audios.filter((i) => i.id !== selectedItem.id))
        incrementCount(-1)
        setLoading(false)
      }
    })
  }

  if(loading)
    return <Spinner/>

  return (
    <div id="ct">
      <div className='p-10'>
        <label className="font-14">Subject</label>
        <select className="form-control ml-margin-bottom" onChange={e=>setSubject(e.target.value)} placeholder="Subject">
          <OptionsObjList list={scope.dataSource?.languages?.options || []} idKey='label' labelKey='label'/>
        </select>
        <RecordAudioSafari 
          setLoading={setLoading}
          journeyProfileId={journeyProfileId} 
          onUploadSuccess={onUploadSuccess} 
          subject={subject}/>
      </div>
      <div className="table-responsive">
        <AudioRecordingTable sort={sort} audios={scope.audios} onDelete={onDelete}/>
      </div>
      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={deleteAudio}/>
    </div>
  )
}

const TableData = ({onDelete, audios}) => audios.map((s, k) => {
    return(
      <TR key={k}>
        <TD className="text-center" data-title="Id">{s.id}</TD>

        <TD className="text-center">
          {s.url ?
            <audio controls>
              <source src={env.file_url + s.url} type="audio/wav"/>
            </audio>
            :
            null
          }
        </TD>

        <TD className="text-center">
          {Cs.formatUpdateDate(s.updated_at, 'MMMM DD YYYY')}
        </TD>
       
        <TD className="text-center">
          {s?.author?.name}
        </TD>

        <TD className="text-center">
          <span className="fas fa-trash m-r-15 font-18 text-muted" onClick={()=>onDelete(s)}/> 
        </TD>
      </TR>
    )
  })

const AudioRecordingTable = ({sort, audios, onDelete}) =>(
    <Table className="shadow-small">
      <THead>
        <TR>
          <TH onClick={e=>sort('record_id')}>
            Id 
          </TH>
          <TH onClick={e=>sort('record_id')}>
            Audio
          </TH>
          <TH onClick={e=>sort('updated_at')}>
            Date
          </TH>
          <TH onClick={e=>sort('created_by')}>
            User
          </TH>
          <TH onClick={e=>sort('record_id')}>
            Options
          </TH>
        </TR>
      </THead>
      <TBody>
        <TableData audios={audios} onDelete={onDelete}/>
      </TBody>
    </Table>
)

const AudioRecordingBtn = ({journey, currentUser, onInitOpen}) =>{

  const { isOpen: isAudioRecorderOpen, toggleModal:toggleAudioRecorder } = useModal(onInitOpen)

  const [count, setCount] = useState(journey.audio_file_count || 0)
  const [badgeStyle, setBadgeStyle] = useState({})
  const {labelLocale} = usePageLocale(currentUser.current_locale, 'casePortalPage')

  const incrementCount = (number) => setCount(number!==null?(parseInt(count) + number):0);

  useEffect(()=>{
    setBadgeStyle(count>0?{'background':'red'}:{})
  }, [count])

  useEffect(()=>{
    subscribe("inc_audio_msg", ({detail:data}) => {
      if(data.journey_profile_id === journey.id && data.fileable_type === 'journey_profile_audio'){
        incrementCount(1);
      }
    })
    return () => {
      unsubscribe("inc_audio_msg", () => {})
    }
  }, [])

  return(
    <>
      <span id="voice_message" 
        onClick={() => toggleAudioRecorder()} className="bg-grey-4 round-btn m-r-5 m-t-4 tooltip">
        <i className="fas fa-microphone" />
        <span className="badge-2 up pos-abs" style={badgeStyle}>{count}</span>
        <CueTip tooltip={labelLocale(5)} positionCss="bottom"/>
      </span>
      
      {isAudioRecorderOpen && 
        <GenericModal component={AudioRecordingList} 
          toggleModal={toggleAudioRecorder} 
          title={labelLocale(5)}
          journeyProfileId={journey.id}
          incrementCount={incrementCount}/>
      }
    </>
  )
}

export {AudioRecordingList, AudioRecordingBtn};