import React, { Fragment, useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import CommentsService from '../../services/CommentsService'
import Cs from '../../services/CommonService'
import useStyle from '../../hooks/useStyle'
import useEscape from '../../hooks/useEscape'
import { InputTranslate } from "../Locale/InputTranslate"
import ConfirmMenu from "../Modals/ConfirmMenu"
import useModal from "../../hooks/useModal"
import { subscribe, unsubscribe } from "../../hooks/useEvents"
import { SpanRichTextView } from '../Common/RichTextView'
import FileUploadService from '../../services/FileUploadService'
import { useCurrentUserHook } from '../Authentication/useUserHook'
import { Spinner } from '../Common/Spinner'

// let comment = {}
// let requestParams = null

const CommentsModal = ({ item, type, isOpen, toggleModal, showInline, journeyProfileId, incrementCount}) => {
  useStyle('/css/tile_components.css', true);
  useStyle('chat');
  useEscape(toggleModal);
  
  let { current: scope } = useRef({ requestParams: null, comment:{}});
  const {currentUser, screen} = useCurrentUserHook()
  if(item)item.comments = item.comments || [];
  const [commentsList, setCommentsList] = useState([]);
  const [text, setText] = useState(item.selectedText || '');
  const [parentComment, setParentComment] = useState({});
  const [newMessage, setNewMessage] = useState({});
  const [loading, setLoading] = useState(true);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [pagemeta, setPagemeta] = useState({});
  const { isOpen:isDeleteOpen, toggleModal:toggleDeleteModal } = useModal();
  const listInnerRef = useRef();
  
  useEffect(() => {
    scope.requestParams = {
      'commentable_id': item.id,
      'commentable_type': type,
      'journey_profile_id':journeyProfileId,
      'sort_column': 'updated_at',
      'sort_direction': 'desc',
      'page':1,
      'per_page':15
    }

    if(isOpen){
      resetComment()
      getCommentsList()
    }
  }, [item.id])

  if(type == 'data_source'){
    scope.requestParams.commentable_int_id = item.pk_id
    delete scope.requestParams.commentable_id
  }

  useEffect(() =>{
    if(newMessage.id){
      setCommentsList([...commentsList, newMessage])
      scrollTo(commentsList)
    }
  }, [newMessage])

  useEffect(()=>{
    subscribe("comment", (e) => addCommentFromSocket(e.detail))
    return () => {
      unsubscribe("comment", () => addCommentFromSocket())
      scope.comment = {}
      scope.requestParams = null
    }
  }, [])

  let getCommentsList = (is_lazy_loaded) => {
    setLoading(true)
    CommentsService.getAll(scope.requestParams).then(({status, data}) => {
      if (status === 200) {
        setLoading(false)
        setPagemeta(data.meta)
        setCommentsList([...data.comments.reverse(), ...commentsList])
        //if(requestParams.page=='last')onScroll();
        /*requestParams.page = data.meta.page
        requestParams.totalItems = data.meta.total*/
        scrollTo(data.comments, is_lazy_loaded)
      }
    })
  }

  const addCommentFromSocket = (data) =>{
    if(data.commentable_id === item.id && data.commentable_type === type){
      setNewMessage(data)
    }
  }

  const onScroll = () => {
    if (listInnerRef.current) {
      if(listInnerRef.current.scrollTop == 0){
        if(scope.requestParams.page < pagemeta.last_page){
          scope.requestParams.page += 1
          getCommentsList(true)
        }
      }
    }
  }

  const resetComment = ()=>{
    scope.comment = {}
    scope.comment.commentable_id = item.id 
    scope.comment.commentable_type = type
    scope.comment.journey_profile_id = item.journey_profile_id
    if(type == 'data_source'){
      scope.comment.commentable_int_id = item.pk_id
      delete scope.comment.commentable_id
    }
  }

  const addComment = (e, text) => {
    setLoading(true)
    e.preventDefault()
    if(text==''){
      setLoading(true)
      return
    }
    if(scope.comment.id){
      scope.comment.comment = text
      CommentsService.update(scope.comment).then((res)=>{
        if(res.status == 204){
          setLoading(false)
          setText('')
          setCommentsList((commentsList.filter(i => i.id !== scope.comment.id)).concat([scope.comment]))
          resetComment()
          scrollTo(commentsList)
        }
      })
    }else{
      scope.comment.comment = text
      if(parentComment.id)scope.comment.parent_comment_id = parentComment.id
      CommentsService.create(scope.comment).then((res)=>{
        if(res.status == 201){
          setLoading(false)
          scope.comment = res.data.comment
          //comment.record_id = res.data.comment.record_id;
          scope.comment.parent_comment = parentComment;
          setCommentsList([...commentsList, scope.comment])
          /*setText('');*/
          resetComment()
          setParentComment({})
          scrollTo(commentsList)
          incrementCount && incrementCount(1)
        }
      })  
    }
  }

  const deleteComment = (e) => {
    toggleDeleteModal(e)
    setLoading(true)
    CommentsService.delete(scope.comment.id).then((res)=>{
      setLoading(false)
      setCommentsList(commentsList.filter(i => i.id !== scope.comment.id))
      scrollTo(commentsList)
      incrementCount && incrementCount(-1)
      resetComment()
    })  
  }

  const onDelete = (c) => {
    scope.comment = c
    toggleDeleteModal()
  }

  const setEdit =(c)=>{
    scope.comment = c
    setText(c.comment)
  }

  const setReply = (comment) =>{
    setParentComment(comment)
    scrollTo(commentsList)
  }

  function scrollTo(comments, is_lazy_loaded){
    if(comments.length>0){
      try{
        let ele = null
        if(is_lazy_loaded){
          let scroll_id = comments[comments.length - 1].id
          ele = document.getElementById('comment_'+scroll_id)  
        }else{
          ele = document.getElementById('last_comment')  
        }
        document.getElementById('chat-main').scrollTop = ele.offsetTop
      }catch(e){
        console.log(e)
      }
    }
  }

  const uploadFileToUrl = (event) => {
    try {
      let files = event.target.files
      let ele =  event.target
      let FileSize = files[0].size / 1024 / 1024; // in MB
      if (FileSize > 1) {
        ele.value = null
        setFileSizeError(true)
        setTimeout(()=>setFileSizeError(false), 5000) 
        return
      }

     if(files.length>0){
        setLoading(true)
        FileUploadService.uploadFileToUrl(files[0], null, scope.comment, 'comment', 'comments/file_upload').then((res)=> {
          ele.value = null
          scope.comment = res.data.comment;  
          //comment.record_id = res.data.comment.record_id;
          setLoading(false)
          setCommentsList([...commentsList, scope.comment])
          /*setText('');*/
          resetComment()
          scrollTo(commentsList);
        }, (err)=>{
          setLoading(false)
        })
      }
    } catch (e) {
      setLoading(false)
    }
  }

  const CommentType = ({comment}) =>{
    if(comment.file_upload_id){
      return(
        <a target="_blank" href={Cs.getFileUrl(comment.comment)}>
          <img src={Cs.getIconByType(comment.comment)} width="75px"/>
          <span>{Cs.getFileName(comment.comment)}</span>
        </a>
      )
    }else if(JSON.stringify(comment.parent_comment) != "{}"){
      return(
        <Fragment>
          {comment.parent_comment && 
            <div className="reply-comment">
              { comment.parent_comment.file_upload_id && 
                <a target="_blank" href={Cs.getFileUrl(comment.parent_comment.comment)}>
                  <img src={Cs.getIconByType(comment.parent_comment.comment)} width="75px"/>
                </a>
              }
              <span>{Cs.getFileName(comment.parent_comment.comment)}</span>
            </div>
          }
          <SpanRichTextView html={comment.comment}></SpanRichTextView>
        </Fragment>
      )
    }else{
      return(
        <Fragment>
          <SpanRichTextView html={comment.comment}></SpanRichTextView>
        </Fragment>
      )
    }
  }

  const commentsItem = commentsList.map((c, k) =>
    <Fragment key={k}>
      <div id={`comment_${c.id}`} className={`speech-bubble ${c.created_by == currentUser.id?'speech-left':'speech-right'}`}>
        <span className="portlet-dropdown">
          <a data-tip="Journey Menu" className="p-dropbtn">
            <i className='fas fa-caret-down'></i>
          </a>
          <div className="p-dropdown-comment">
            { c.created_by == currentUser.id &&
              <Fragment>
                <a onClick={()=>onDelete(c)}>
                  <i className="far fa-trash-alt"/> Delete
                </a>
              </Fragment>
            }
            <a onClick={()=>setReply(c)}>
              <i className="fas fa-reply" aria-hidden="true"></i> Reply
            </a>
          </div>
        </span>
        {c.created_by == currentUser.id &&
          <div className="pull-right">
            <div className="font-16 pull-right m-r-5">
              <i className={`far ${c.read_status?'fa-eye':'fa-eye-slash'} text-muted`} aria-hidden="true"></i>
            </div>
          </div>
        }
        <CommentType comment={c}/>
        <span className="speech-read mb-3">
          {c.author && c.author.name} - {Cs.formatUpdateDate(c.updated_at)}
        </span>
      </div>
      <div className="clear"/>
    </Fragment>
  )

  const CommentForm = (props) =>{
    const [text, setText] = useState(props.text || '');
    const [translateOn, toggleTranslate] = useState(false);

    return(
      <div className="card-footer p10">
        <form className="flex aligncenter" onSubmit={e=>props.addComment(e, text)}>
          {fileSizeError && 
            <div className="speech-reply errormsg">
              File Size is Large. Max Size 1 MB
            </div>
          }

          {parentComment.id && 
            <div className="speech-reply">
              <CommentType comment={parentComment}/>
              <span onClick={e=>setReply({})} className="pull-right">
                <i className="fas fa-times"></i>
              </span>
            </div>
          }

          {/*<label data-tip="Max File Size 1mb" htmlFor="file_upload"
              className="icon-gray-cirle brd-30 flex aligncenter justifycenter m-r-10">
                <i className="fas fa-plus"/>
            </label>
            <input type="file" id="file_upload" className="hidden" onChange={e => props.uploadFileToUrl(e)} />
          */}
            
          <span onClick={()=>toggleTranslate(!translateOn)} 
            className={`${translateOn?'bg-highlight':''} icon-gray-cirle brd-30 flex aligncenter justifycenter text-center m-r-5`}>
              <i className="w-16px fas fa-globe"/>
          </span>

          {currentUser.current_locale !== 'english' && translateOn &&
            <InputTranslate isRichText={false} 
              localeSid={currentUser.current_locale} 
              inputFieldId="comment_text"
              adjustTranslatePosition={true}
              onInsertTextArea={(e)=>setText(e.target.value)}/>
          }

          <input type="text" 
            className="form-input form-input-gray form-input-full" 
            autoFocus id="comment_text"
            onChange={e => setText(e.target.value)}
            placeholder="Enter your message here"/>

          <span onClick={e=>addComment(e, text)}
            className="icon-gray-cirle brd-30 flex aligncenter justifycenter m-l-10">
              <i className="w-16px fas fa-comments"/>
          </span>
        </form>
      </div>
    )
  }

  const CommentBox = () =>(
    <Fragment>
      
      <div className="card-title card-title-gray flex justspacebetween p-b-15 p15 font-18">
        <div className="m-t-20 m-l-20">Comments</div>
        {!showInline && (
          <button
            className="btn-close"
            title="Close Modal"
            onClick={e => toggleModal(e)}>
            ×
          </button>
        )}
      </div>

      {/*<div className="ml-center bg-lgreen white p-5">
        <div className="font-16 bold-600">Comments</div>
        {!showInline &&
          <span className="ml-button ml-xlarge ml-hover-red ml-display-topright" title="Close Modal"
            onClick={e=>toggleModal(e)}>×
          </span>
        }
      </div>*/}

      <div className="m-b-0 chat-main" id="chat-main" style={{height:screen.height-300}} onScroll={() => onScroll()} ref={listInnerRef}>
        <div id="first_comment" className="m-t-5"></div>
        {commentsItem.length==0 && 
          <div className="no-chat font-15">This {type} Tile has no comments yet.You can add one now.</div>
        }
        {loading ? <Spinner/> : commentsItem}
        <div id="last_comment" className="m-t-5"></div>
      </div>
      
      <CommentForm text={text} addComment={addComment} uploadFileToUrl={uploadFileToUrl}/>
    </Fragment>    
  ) 

  if(showInline){
    return(
      <div className="m-t-15">
        <div className="ml-card-4 bg-white">
          <div id="ct">
            <CommentBox/>
          </div>
          <ConfirmMenu isOpen={isDeleteOpen} toggleModal={toggleDeleteModal} success={deleteComment}/>
        </div>
      </div>
    )
  }

  return(
    ReactDOM.createPortal(
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom bg-white brd-10">
          <div id="ct">
            <CommentBox/>
          </div>
          <ConfirmMenu isOpen={isDeleteOpen} toggleModal={toggleDeleteModal} success={deleteComment}/>
        </div>
      </div>, document.body
    )
  )
}

export default CommentsModal;