import React, {useEffect, useState} from 'react'
import AuthenticationService from "../../services/AuthService"
import DigitalContentService from "../DigitalContent/DigitalContentService"
import {Content} from "./AboutUs"
import OrganizationMemberService from "../Organizations/Members/OrganizationMemberService"
import { useCurrentUserHook } from '../Authentication/useUserHook'
import { Spinner } from '../Common/Spinner'

const TermsPrivacyConsent = (props) => {
  const {recordIds} = props
  const {currentUser,dispatch} = useCurrentUserHook()

  const [loading, setLoading] = useState(true)
  const [content, setContent] = useState({})
    
  useEffect(() => {
    getAll()
  }, [])

  const getAll = () =>{
    const req = {
      page:1,
      per_page:1,
      sort_column:'record_id',
      sort_direction:'desc',
      record_ids:currentUser.current_organization?.data?.terms_privacy_ids
    }
    DigitalContentService.getAll(req).then((res)=>{
      setContent(res.data.digital_contents[0])
      setLoading(false)
    })
  }

  const onAgree = async () =>{
    const {status} = await OrganizationMemberService.update({
      'id':currentUser.current_member.id,
      'context':'open',
      'is_tos_agreed':true
    })
    if(status === 204){
      currentUser.current_member.is_tos_agreed = true;
      dispatch({
        type: "UPDATE",
        payload: {'user': currentUser},
      })
      /*window.sessionStorage.setItem("ct_org_terms_consent", true);*/
      props.history.push("/health/table");
    } 
  }

  const onDisagree = () =>{
    setLoading(true)
    AuthenticationService.signout().then(() => {
      dispatch({ type: "LOGOUT" })
      setLoading(false)
    })
  }

  if(loading)
    return <Spinner/>

  return(
    <>
      <Content content={content} contentCss="vh-52 scroll-y"/>  
      <div className="col-xs-12 text-center" style={{margin: "10px 0px 10px 0px"}}>
        <button type="button" className="dropbtn bg-submit" onClick={()=>onAgree()}>
          Agree
        </button>
        <button type="button" className="dropbtn bg-cancel" onClick={()=>onDisagree()}>
          Disagree
        </button>
      </div>
    </>
  )  
}

export {TermsPrivacyConsent};