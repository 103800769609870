import React, {useEffect} from "react"
import DynamicTable from './DynamicTable'
import { Spinner } from "./Spinner"

const ReadCsvFile = ({onReadCallback, isFileUploadOpen, toggleFileUpload, fileType='csv'}) => {

  useEffect(() => {
    fileUploadEle()
  }, [isFileUploadOpen])
  
  const fileUploadEle = () =>{
    if(isFileUploadOpen){
      
      function beforeClose() {
        if (input.value.length===0) {
          toggleFileUpload()
        }
        document.body.onfocus = null;
      }

      document.body.onfocus = beforeClose;
      console.log('initializing');
      let input = document.createElement('input');
      input.type = 'file';
      input.onchange = e => readSingleFile(e)
      input.click();
    }
  }

  const readSingleFile = (e) => {
    var file = e.target.files[0];
    if (!file && file.filename.split('.')[1] != fileType) {
      return;
    }

    let reader = new FileReader()
    reader.onload = function(e) {
      let contents = e.target.result
      displayContents(contents, file.name)
      e.target.value  = ''
      //displayParsed(contents);
    }
    reader.readAsText(file)
  }

  const displayContents = (contents, filename) => {
    //console.log(contents)
    onReadCallback(contents, filename)
  }

  return null

  /*return(
    <>
      <label htmlFor="csv-file-input" data-tip="Upload Csv File"
        className="bg-highlight round-btn float-right m-r-5 m-t-4">
          <i className="fas fa-upload"/>
      </label>
      
      <input type="file" id="csv-file-input" onChange={e => readSingleFile(e)} className="hidden"/>
    </>
  )*/
}

const CsvTableView = ({loading, formFields, dataList, insertParsedCsv, csvSpecialFields}) =>{
  let sort = () =>{

  }

  return(
    <div id="ct" className="table-responsive">
      <div className="col-xs-12 d-grid">
        {loading ? 
          <Spinner/> 
          :
          <div onClick={()=>insertParsedCsv()} 
            className="bg-submit p-10 font-16 text-center white">
            OK to upload
          </div>
        }
      </div>

      <div className="row vh-80">
        <DynamicTable requestParams={{}} onSort={sort} 
          formFields={formFields} 
          formDataList={dataList} 
          tClass="table shadow-small"
          specialFields={csvSpecialFields}/>
      </div>
    </div>
  )
}

export {ReadCsvFile, CsvTableView}